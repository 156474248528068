import { createContext, useContext, useEffect, useState } from "react";

export interface KeypadCallbackResponse {
    success: boolean,
    msg: string,
    navigateUrl: string
}
type KeypadSubmitCallbackType = (answer: string) => Promise<KeypadCallbackResponse>;

interface KeypadContextType {
    keypadCallback: KeypadSubmitCallbackType,
    setKeypadCallback: (fn: KeypadSubmitCallbackType) => void
}
const KeypadContext = createContext<KeypadContextType | undefined>(undefined);
export const useKeypadContext = () => {
    const context = useContext(KeypadContext);

    if (!context) {
        throw new Error("`keypadContext` have to be used inside `KeypadContextProvider`");
    };
    return context;
};

interface KeypadContextProps {
    children: any;
}

export const KeypadContextProvider = (props: KeypadContextProps) => {
    const initCallback = async (answer: string) => { return {success: false, msg: "Keypad callback not set", navigateUrl: ""}; };
    const [keypadCallback, setKeypadCallback] = useState<KeypadSubmitCallbackType>(() => initCallback);
    const updateKeypadCallback = (fn: KeypadSubmitCallbackType) => {
        setKeypadCallback(() => fn);
    };
    return (
        <KeypadContext.Provider
            value={{
                keypadCallback: keypadCallback,
                setKeypadCallback: updateKeypadCallback
            }}>
            {props.children}
        </KeypadContext.Provider>
    );
};
