import { connectDatabaseEmulator, getDatabase, onValue, ref } from "firebase/database";
import { app } from "./init";
import { store } from "../../app/store";
import { setFeatureSwitches } from "../feature-switches/featureSwitchSlice";
import { setSaleState } from "../minting/mintingSlice";
import { setGasLimit, setContract, setSigner } from "../metamask/metamaskSlice";

const database = getDatabase(app);

// if (process.env.NODE_ENV !== "production") {
//     connectDatabaseEmulator(database, "localhost", 9000);
// }

const starCountRef = ref(database, "featureSwitches");

onValue(starCountRef, (snapshot: any) => {
    const data = snapshot.val();
    store.dispatch(setFeatureSwitches(data));
});
const saleStateRef = ref(database, "saleState");

onValue(saleStateRef, (snapshot: any) => {
    const data = snapshot.val();
    store.dispatch(setSaleState(data));
    store.dispatch(setGasLimit({ gasLimit: data.gasLimit }));
    store.dispatch(setContract({ contractAddress: data.contractAddress, networkId: data.networkId }));
    store.dispatch(setSigner({ signerAPIBaseURI: data.signerAPIBaseURI }));
});
