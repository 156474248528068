import React from "react";
import { Route, Routes } from "react-router-dom";
import MetamaskScreen from "./MetamaskScreen";
import SaleScreen from "./SaleScreen";
import MintSuccessScreen from "./MintSuccessScreen";
import SoldOutScreen from "./SoldOutScreen";
import MintingErrorScreen from "./MintingErrorScreen";
import NotWhitelistedScreen from "./NotWhitelistedScreen";

function MintingNested (props: any) {
    return (
        <Routes>
            <Route path="/" element={<MetamaskScreen />} />
            <Route path="/metamask" element={<MetamaskScreen />} />
            <Route path="/sale" element={<SaleScreen />} />
            <Route path="/success" element={<MintSuccessScreen />} />
            <Route path="/sold-out" element={<SoldOutScreen />} />
            <Route path="/error" element={<MintingErrorScreen />} />
            <Route path="/not-whitelisted" element={<NotWhitelistedScreen />} />
        </Routes>
    );
}

export default MintingNested;
