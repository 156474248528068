import { useSwipeable } from "react-swipeable";
import { getAreaRealSize } from "./ComicPage";
import { useEffect, useState } from "react";

export function ComicArea ({ onSwiped, pageSizeCurrent, size, item, ...rest }: any) {
    const [{ width, height, offsetLeft, offsetTop }, setItemCurrentSize] =
        useState(getAreaRealSize(size, pageSizeCurrent, item));

    const handlers = useSwipeable({
        trackMouse: true,
        preventScrollOnSwipe: true,
        onSwiped: (eventData) => {
            onSwiped(eventData.dir);
            console.log("User Swiped!", eventData);
        }
    });

    useEffect(() => {
        if (pageSizeCurrent.offsetWidth && pageSizeCurrent.offsetHeight) {
            setItemCurrentSize(getAreaRealSize(size, pageSizeCurrent, item));
        }
    }, [pageSizeCurrent]);

    return (
        <div
            {...handlers}
            className="comic-slide"
            {...rest}
            style={{
                width: `${width}px`,
                height: `${height}px`,
                left: `${offsetLeft}px`,
                top: `${offsetTop}px`
            }}
        />
    );
}
