import React, { useEffect, useState } from "react";
import MintFrame from "./MintFrame";
import { useSkullVideo } from "../../hooks/useSkullVideo";
import { useCloseButton } from "../../hooks/useCloseButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    ActionStatus,
    selectWhitelistActionStatus,
    selectWhitelistEndDate,
    selectWhitelistMintError,
    setWhitelistActionStatus,
    whitelistMintAsync,
    WhitelistStatus
} from "./mintingSlice";
import {
    checkIfOnWhitelist,
    selectContractAddress,
    selectIsWhitelisted
} from "../metamask/metamaskSlice";
import TermsAndConditions from "./TermsAndConditions";

function MintNFTScreen (props: any) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const whitelistEndDate: string | undefined = useAppSelector(selectWhitelistEndDate);
    const whilistMintError: string | undefined = useAppSelector(selectWhitelistMintError);
    const [quantity, setQuantity] = useState(1);
    const [tcAgree, setTCAgree] = useState(false);
    const [screenState, setScreenState] = useState("buy");
    const [showTCs, setShowTCs] = useState(false);
    const [whitelistRequested, setWhitelistRequested] = useState(false);
    const [countDown, setCountDown] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
    });
    const whitlistActionStatus = useAppSelector(selectWhitelistActionStatus);
    const contractAddress = useAppSelector(selectContractAddress);

    useSkullVideo(["blurred"]);
    const onClosedClicked = () => {
        navigate("/mint");
    };
    useCloseButton(onClosedClicked);

    const setTime = (_endDate: Date) => {
        if (!_endDate) {
            return;
        }
        const now = new Date().getTime();
        const distance = _endDate.getTime() - now;

        if (distance <= 0) {
            setCountDown({
                days: "00", hours: "00", minutes: "00", seconds: "00"
            });
            return;
        }
        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            .toString().padStart(2, "0");
        const hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))
            .toString().padStart(2, "0");
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            .toString().padStart(2, "0");
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)
            .toString().padStart(2, "0");
        setCountDown({
            days, hours, minutes, seconds
        });
    };
    const isWhitelisted = useAppSelector(selectIsWhitelisted);

    useEffect(() => {
        if (isWhitelisted === WhitelistStatus.NOT_WHITELISTED) {
            navigate("/mint/not-whitelisted");
        }
    }, [isWhitelisted]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!whitelistRequested) {
                dispatch(checkIfOnWhitelist("args"));
                console.log("WhitelistScreen checking whitelist");
                setWhitelistRequested(true);
            }
        }, 10);
        return () => { clearTimeout(timeout); };
    }, []);

    useEffect(() => {
        if (whilistMintError) {
            setScreenState("buy");
        }
    }, [whilistMintError]);

    useEffect(() => {
        let timeoutId: any;
        if (whitelistEndDate) {
            const date = new Date(whitelistEndDate);
            timeoutId = setInterval(() => setTime(date), 1000);
        }
        return () => clearTimeout(timeoutId);
    }, [whitelistEndDate]);

    useEffect(() => {
        switch (whitlistActionStatus) {
        case ActionStatus.SUCCESS:
            navigate("/mint/success");
            dispatch(setWhitelistActionStatus(ActionStatus.IDLE));
            break;
        case ActionStatus.ERROR:
            navigate("/mint/error");
            dispatch(setWhitelistActionStatus(ActionStatus.IDLE));
            break;
        }
    }, [whitlistActionStatus]);

    function whitelistMint () {
        setScreenState("buying");
        dispatch(whitelistMintAsync({
            quantity
        }));
    }

    return (
        <MintFrame isPublicSale={false} titled={true}>
            <div id="mint-sale-scrn" className={`mint-screen active a100 ${screenState}`}>
                <div id="mnt-sale-container" className="mint-side-content">
                    <div id="mnt-sale-title"></div>
                    <div id="mnt-sale-countdown" className="msc-entry btm-separator mnt-whitelist">
                        <span>
                            {countDown.days}
                            <span className="colon">:</span>
                            {countDown.hours}
                            <span className="colon">:</span>
                            {countDown.minutes}
                            <span className="colon">:</span>
                            {countDown.seconds}
                        </span>
                    </div>
                    <div id="mnt-sale-select" className="msc-entry btm-separator">
                        <div className="mnt-sel-label">Mint Option: <span className="mnt-sel-amount">{quantity}</span> of 2</div>
                        <div className="mnt-sel-btn-box">
                            <div onClick={() => setQuantity(1)} className="mnt-sel-btn-01 sq-btn mss-btn active"><span>1</span></div>
                            <div onClick={() => setQuantity(2)} className={ `mnt-sel-btn-02 sq-btn mss-btn ${quantity > 1 ? "active" : ""}`}><span>2</span></div>
                            {/*
                                                        <div onClick={() => setQuantity(3)} className={ `mnt-sel-btn-03 sq-btn mss-btn ${quantity === 3 ? "active" : ""}`}><span>3</span></div>
                            */}
                        </div>
                    </div>
                    <div id="mnt-sale-TnC" className="msc-entry">
                        <div id="ms-TnC-checkbox" className={ tcAgree ? "checked" : "" } onClick={() => setTCAgree(!tcAgree)}></div>
                        <div id="ms-TnC-label">I confirm that I have read and agree to the <a onClick={(e) => {
                            e.preventDefault();
                            setShowTCs(true);
                        }}> Terms & Conditions</a> of
                            the sale. {showTCs.toString()} aa
                        </div>
                    </div>
                    <div id="mnt-sale-info" className="msc-entry btm-separator">
                        <div id="msi-label">Public address of the contract:</div>
                        <div id="msi-contract-link-box">
                            <a href={"https://opensea.io/" + contractAddress} target="blank">
                                {contractAddress}
                            </a>
                        </div>
                    </div>
                    <div id="mnt-sale-buy-btn-box" className="msc-entry">
                        <div id="ms-buy-button" onClick={() => whitelistMint()} className={`sq-btn ${!tcAgree ? "inactive" : ""}`}>
                            <span className="mnt-ms-buy">LET'S GO</span>
                            <span className="mnt-ms-buying">PROCESSING</span>
                        </div>
                        <div id="ms-buy-message-box">
                            <span id="mnt-msg-terms" className={`mnt-ms-buy ${tcAgree ? "inactive" : ""}`}>You need to agree to the <br /> Terms & Conditions</span>
                            <span id="mnt-msg-processing" className="mnt-ms-buying">Please check your wallet</span>
                        </div>
                    </div>
                </div>
            </div>
            <TermsAndConditions visible={showTCs} onClose={() => setShowTCs(false)} />
        </MintFrame>
    );
}

export default MintNFTScreen;
