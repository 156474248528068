import axios from "axios";

const COVER_METADATA_URL = "https://anthology.ey3k0n.io/metadata/";

const NONE_STRING = "None";

export interface COVER_METADATA {
    name: string,
    description: string,
    image: string,
    edition: number,
    artist: string,
    organization: string,
    attributes: Record<string, string | null>,
    tokenId: number,
}

const _parseAttributesArray = (traits: Array<{trait_type: string, value: string}>): Record<string, string | null> => {
    const parsedTraits: Record<string, string | null> = {};
    for (let i = 0; i < traits.length; i++) {
        const trait = traits[i];
        parsedTraits[trait.trait_type] = trait.value === NONE_STRING ? null : trait.value;
    };
    return parsedTraits;
};

const _parseMetadata = (metadata: any, tokenId: number): COVER_METADATA => {
    return {
        ...metadata,
        tokenId,
        properties: {
            ...metadata.properties
        },
        attributes: _parseAttributesArray(metadata.attributes)
    };
};

const getCoverMetadata = async (tokenId: number): Promise<COVER_METADATA | null> => {
    const metadataUrl = COVER_METADATA_URL + tokenId.toString() + `?cb=${Date.now()}`;
    try {
        const response = await axios.get(metadataUrl);
        const metadata = response.data;
        return _parseMetadata(metadata, tokenId);
    } catch (e) {
        const error = e as any;
        console.log(error);
        return null;
    }
};

export const coverMetadataService = {
    getCoverMetadata
};
