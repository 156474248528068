/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { SaleState } from "./SaleState";
import { getWeb3Token, whitelistMintNFT, mintNFT } from "../metamask/api";
// import { addToMailingList } from "../firebase/functions";
import { setWeb3Token, selectGasLimit, selectContractAddress, selectWalletAddress } from "../metamask/metamaskSlice";

export enum ActionStatus {
    IDLE = "PENDING",
    PENDING = "PENDING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export enum WhitelistStatus {
    WHITELISTED = "WHITELISTED",
    NOT_WHITELISTED = "NOT_WHITELISTED",
    UNKNOWN = "UNKNOWN"
}

export interface MintingState {
    whitelistActionStatus: string;
    whitelistMintError?: string;
    saleState: string,
    isPublicSale: boolean;
    whitelistEndDate?: string;
    tokenSupply: number;
    mintSuccess: boolean;
    whitelistMintSuccess: boolean;
    publicMintError?: string;
    publicMintActionStatus: ActionStatus;
    publicMintSuccess: boolean;
    NFTPrice: string;
    subscribedToMailingList: boolean;
}

const initialState: MintingState = {
    saleState: SaleState.COMING_SOON,
    isPublicSale: false,
    tokenSupply: 10000,
    mintSuccess: false,
    whitelistMintSuccess: false,
    publicMintSuccess: false,
    whitelistActionStatus: ActionStatus.IDLE,
    publicMintActionStatus: ActionStatus.IDLE,
    NFTPrice: "0.55",
    subscribedToMailingList: false
};

export const subscribeToMailingList = createAsyncThunk(
    "minting/subscribeToMailingList",
    async (emailAddress: any = "", thunkApi) => {
        if (!emailAddress) {
            throw new Error("emailAddress is required");
        }
        // try {
        //     const response = await addToMailingList({ //
        //         email: emailAddress
        //     });
        //     return true;
        // } catch (e) {
        //     console.log("subscribeToMailingList", e);
        //     return false;
        // }
        return true;
    });

export const mintingSlice = createSlice({
    name: "minting",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSaleState: (state: Draft<MintingState>, action: PayloadAction<any>) => {
            state.saleState = action.payload.currentState;
            state.whitelistEndDate = action.payload.whitelistEndDate;
            state.tokenSupply = action.payload.tokenSupply;
            state.NFTPrice = action.payload.NFTPrice;
        },
        setWhitelistActionStatus: (state: Draft<MintingState>, action: PayloadAction<ActionStatus>) => {
            state.whitelistActionStatus = action.payload;
        },
        setPublicActionStatus: (state: Draft<MintingState>, action: PayloadAction<ActionStatus>) => {
            state.publicMintActionStatus = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(whitelistMintAsync.pending, (state, action) => {
            state.whitelistActionStatus = ActionStatus.PENDING;
            state.whitelistMintSuccess = false;
            state.whitelistMintError = undefined;
        }).addCase(whitelistMintAsync.rejected, (state, action) => {
            state.whitelistActionStatus = ActionStatus.ERROR;
            console.log("I WAS REJECTED", action.error.message);
            state.whitelistMintError = `There was an error Minting: ${action.error?.message}`;
        }).addCase(whitelistMintAsync.fulfilled, (state, action) => {
            state.whitelistMintError = undefined;
            state.mintSuccess = true;
            state.whitelistActionStatus = ActionStatus.SUCCESS;
        });

        builder.addCase(publicMintAsync.pending, (state, action) => {
            state.publicMintActionStatus = ActionStatus.PENDING;
            state.publicMintSuccess = false;
            state.publicMintError = undefined;
        }).addCase(publicMintAsync.rejected, (state, action) => {
            state.publicMintActionStatus = ActionStatus.ERROR;
            console.log("I WAS REJECTED", action?.error?.message);
            state.publicMintError = `There was an error Minting: ${action.error?.message}`;
        }).addCase(publicMintAsync.fulfilled, (state, action) => {
            state.publicMintError = undefined;
            state.mintSuccess = true;
            state.publicMintActionStatus = ActionStatus.SUCCESS;
            console.log("PublicMintSuccess", action);
        });

        builder.addCase(subscribeToMailingList.pending, (state, action) => {
            state.subscribedToMailingList = false;
        }).addCase(subscribeToMailingList.rejected, (state, action) => {
            state.subscribedToMailingList = false;
            console.log("I WAS REJECTED", action?.error?.message);
        }).addCase(subscribeToMailingList.fulfilled, (state, action) => {
            state.subscribedToMailingList = action.payload;
            console.log("PublicMintSuccess", action);
        });
    }
});
export const {
    setSaleState,
    setWhitelistActionStatus,
    setPublicActionStatus
} = mintingSlice.actions;

export const whitelistMintAsync = createAsyncThunk(
    "minting/whitlistMint",
    async (args: { quantity: number }, thunkApi) => {
        const state: any = thunkApi.getState();
        let web3Token: string | undefined = state.minting.web3Token;
        if (!web3Token) {
            web3Token = await getWeb3Token("Ey3k0n wants you to sign in with your Ethereum account.\nThis signature is required in order to securely look you up into TH3 L1ST database.");
            thunkApi.dispatch(setWeb3Token(web3Token));
        }
        const NFTPrice = selectNFTPrice(state);
        if (!NFTPrice) {
            throw new Error("NFTPrice is required");
        }

        const gasLimit = selectGasLimit(state);
        const contractAddress = selectContractAddress(state);

        return await whitelistMintNFT({
            address: selectWalletAddress(state),
            web3Token,
            quantity: args.quantity,
            NFTPrice,
            gasLimit,
            contractAddress
        });
    });

export const publicMintAsync = createAsyncThunk(
    "minting/publicMintAsync",
    async (args: { quantity: number }, thunkApi) => {
        const state: any = thunkApi.getState();

        if (state.minting.saleState !== SaleState.PUBLIC_SALE_STARTED) {
            throw new Error("Wrong Sale State " + state.minting.saleState);
        }

        let web3Token: string | undefined = state.minting.web3Token;
        if (!web3Token) {
            web3Token = await getWeb3Token("Ey3k0n wants you to sign in with your Ethereum account.\nThis signature is required in order to securely look you up into TH3 L1ST database.");
            thunkApi.dispatch(setWeb3Token(web3Token));
        }

        const NFTPrice = selectNFTPrice(state);
        if (!NFTPrice) {
            throw new Error("NFTPrice is required");
        }

        const gasLimit = selectGasLimit(state);
        const contractAddress = selectContractAddress(state);

        return await mintNFT({
            address: selectWalletAddress(state),
            web3Token,
            quantity: args.quantity,
            NFTPrice,
            gasLimit,
            contractAddress
        });
    });

export const selectNFTPrice = (state: RootState) => state.minting.NFTPrice;
export const selectWhitelistActionStatus = (state: RootState) => state.minting.whitelistActionStatus;
export const selectPublicActionStatus = (state: RootState) => state.minting.publicMintActionStatus;
export const selectWhitelistMintError = (state: RootState) => state.minting.whitelistMintError;
export const selectTokenSupply = (state: RootState) => state.minting.tokenSupply;
export const selectWhitelistEndDate = (state: RootState) => state.minting.whitelistEndDate;
export const selectSaleState = (state: RootState) => state.minting.saleState;
export default mintingSlice.reducer;
