import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTransforms } from "../transforms/transformsSlice";
import UnlockSlider from "./UnlockSlider";

interface UnlockSliderProps {
    loading: boolean,
    onUnlockCallback?: () => void;
}

function UnlockScreen (props: UnlockSliderProps) {
    const transforms = useSelector(selectTransforms);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    const style = {
        transform: transforms.s2ht5050
    };

    return (
        <div id="unlock-layer" className={`aHD s2ht5050 active ${loading ? "loading" : ""}`} style={style}>
            <div id="unlock-container">
                <div id="umv-logo" className="ut-loaded"></div>
                <UnlockSlider enabled={!loading} onUnlockCallback={props.onUnlockCallback} />
                <div id="umv-loading" className="ut-loading act">Loading...</div>
                <div id="umv-title" className="ut-loaded"><span>Slide to Unlock</span></div>
            </div>
        </div>
    );
}

export default UnlockScreen;
