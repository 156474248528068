import React, { useEffect, useRef, useState } from "react";
import ContentViewport from "../../components/ContentViewport";
import { useAppDispatch } from "../../app/hooks";
import { turnDeviceOn } from "../device-ui/deviceUISlice";
import SquadScreen from "./SquadScreen";
import CodeAboutScreen from "./CodeAboutScreen";
import IntroVideoScreen from "./IntroVideoScreen";
import useBodyClasses from "../../hooks/useBodyCasses";
import { hideBackgroundCharacters, showBackgroundCharacters } from "../background/backgroundSlice";
import useFadeIn from "../../hooks/useFadeIn";

function CodeSection (props: any) {
    const dispatch = useAppDispatch();
    const [currentScreen, setCurrentScreen] = useState("about");
    const body = useBodyClasses();
    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        body.setClasses(["sec-code"]);
        dispatch(turnDeviceOn());
        setTimeout(() => {
            sectionRef.current?.classList.add("active");
        }, 1);

        return () => {
            dispatch(hideBackgroundCharacters());
        };
    }, []);

    let visibleElement;

    switch (currentScreen) {
    case "intro-video":
        visibleElement = <IntroVideoScreen onCloseButtonClicked={() => setCurrentScreen("about")} />;
        break;
    case "squad":
        visibleElement = <SquadScreen onCloseButtonClicked={() => setCurrentScreen("about")} />;
        break;
    case "about":
    default:
        visibleElement = <CodeAboutScreen
            squadButtonClicked={() => setCurrentScreen("squad")}
            playIntroClicked={() => setCurrentScreen("intro-video")}
        />;
    }

    useEffect(() => {
        switch (currentScreen) {
        case "intro-video":
            dispatch(hideBackgroundCharacters());
            break;
        case "squad":
            dispatch(hideBackgroundCharacters());
            break;
        case "about":
        default:
            dispatch(showBackgroundCharacters());
        }
    }, [currentScreen]);

    return (
        <ContentViewport>
            <div ref={sectionRef} id="sec-code" className="section">
                <div id="about-background"></div>
                {visibleElement}
            </div>

        </ContentViewport>
    );
}

export default CodeSection;
