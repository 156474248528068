import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addCartItem, removeCartItem, selectCartLoading, selectItemQtyInCart } from "../shopify/shopifySlice";

interface CartItemQtyUpdateProps {
    merchandiseId: string,
    addDisabled: boolean
}

const CartItemQtyUpdate = (props: CartItemQtyUpdateProps) => {
    const dispatch = useAppDispatch();

    const cartLoading = useAppSelector(selectCartLoading);
    const itemQtyInCart = useAppSelector(selectItemQtyInCart(props.merchandiseId));
    const handleAddItem = () => {
        dispatch(addCartItem({ merchandiseId: props.merchandiseId }));
    };
    const handleRemoveItem = () => {
        dispatch(removeCartItem({ merchandiseId: props.merchandiseId }));
    };
    return (
        <div className="cart-qty-update-wrapper">
            <div className={`cart-qty-update-btn ${cartLoading ? "btn-disabled" : ""}`} onClick={handleRemoveItem}>-</div>
            <div className="cart-qty-update-text">{itemQtyInCart}</div>
            { (!props.addDisabled)
                ? <div className={`cart-qty-update-btn ${cartLoading ? "btn-disabled" : ""}`} onClick={handleAddItem}>+</div>
                : <div></div>
            }
        </div>
    );
};

export default CartItemQtyUpdate;
