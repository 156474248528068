import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectUserCoverTokenMetadata } from "../coverMetadata/coverMetadataSlice";
import PillCard from "./PillCard";

const BrowsePillCards = () => {
    // Comic details
    const userCoverTokenMetadata = useAppSelector(selectUserCoverTokenMetadata);

    return (
        <div id="browse-page-cards">
            { userCoverTokenMetadata?.map((pill, idx) => {
                return (
                    <PillCard key={idx} pill={pill} />
                );
            })}
        </div>
    );
};

export default BrowsePillCards;
