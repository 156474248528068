import React from "react";
import MintFrame from "./MintFrame";
import { useSkullVideo } from "../../hooks/useSkullVideo";
import useFancyNav from "../../hooks/useFancyNav";
import { useCloseButton } from "../../hooks/useCloseButton";
import { useAppSelector } from "../../app/hooks";
import { selectContractAddress } from "../metamask/metamaskSlice";

function SoldOutScreen (props: any) {
    const { navigateTo } = useFancyNav();
    useSkullVideo([]);
    const onCloseButtonClicked = () => {
        navigateTo("/mint");
    };
    useCloseButton(onCloseButtonClicked);
    const contractAddress = useAppSelector(selectContractAddress);
    return (
        <MintFrame>
            <div id="mint-soldout-scrn" className="mint-screen a100 active">
                <div id="mint-soldout-container" className="mint-side-content">
                    <div id="mnt-soldout-title" className="msc-entry">
                        <span>SOLD <strong>OUT!</strong></span>
                    </div>
                    <div id="mnt-soldout-message" className="mnt-msg msc-entry btm-separator top-separator">
                        <span>The bad news is the drop is sold out. The good news is you can still get your hands on an EY3KON! Follow the breadcrumbs…</span>
                    </div>
                    <div id="mnt-soldout-links" className="mnt-links-box msc-entry">
                        <div className="mnt-opensea-button sq-btn">
                            <a href={`https://www.opensea.io/${contractAddress}`} target="blank">BUY ON OPENSEA</a>
                        </div>
                    </div>
                </div>
            </div>
        </MintFrame>
    );
}

export default SoldOutScreen;
