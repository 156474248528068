import { goneInComic } from "./comics/gone_in";
import { maraudingComic } from "./comics/marauding";
import { pilgrimageComic } from "./comics/pilgrimage";
import { titansComic } from "./comics/titans";
import { sterlingComic } from "./comics/sterling";
import { skinComic } from "./comics/skin";
import { dontShootComic } from "./comics/dont_shoot";
import { detenteComic } from "./comics/detente";
import { sockHopComic } from "./comics/sock_hop";
import { lightsComic } from "./comics/lights";

export interface ComicZoomInfo {
  width: number,
  height: number,
  offsetTop: number,
  offsetLeft: number,
}

export interface ComicPageInterface {
  img: string,
  children: ComicZoomInfo[]
}

export enum ComicName {
  GONE_IN = "GONE_IN",
  PILGRIMAGE = "PILGRIMAGE",
  MARAUDING = "MARAUDING",
  TITANS = "TITANS",
  STERLING = "STERLING",
  SKIN = "SKIN",
  DONT_SHOOT = "DONT_SHOOT",
  DETENTE = "DETENTE",
  SOCK_HOP = "SOCK_HOP",
  LIGHTS = "LIGHTS"
}

export interface ComicInfo {
  name: ComicName,
  displayName: string,
  cover: string,
  pages: ComicPageInterface[],
  stage: number,
}

export const comicSize: number[] = [3700, 2859];

export const comics: Record<ComicName, ComicInfo> = {
    GONE_IN: {
        cover: "/img/comic/covers/Chapter_Icons_01.jpg",
        name: ComicName.GONE_IN,
        displayName: "Chapter 1",
        pages: goneInComic,
        stage: 1
    },
    PILGRIMAGE: {
        cover: "/img/comic/covers/Chapter_Icons_02.jpg",
        name: ComicName.PILGRIMAGE,
        displayName: "Chapter 2",
        pages: pilgrimageComic,
        stage: 2
    },
    MARAUDING: {
        cover: "/img/comic/covers/Chapter_Icons_03.jpg",
        name: ComicName.MARAUDING,
        displayName: "Chapter 3",
        pages: maraudingComic,
        stage: 3
    },
    TITANS: {
        cover: "/img/comic/covers/Chapter_Icons_04.jpg",
        name: ComicName.TITANS,
        displayName: "Chapter 4",
        pages: titansComic,
        stage: 4
    },
    STERLING: {
        cover: "/img/comic/covers/Chapter_Icons_05.jpg",
        name: ComicName.STERLING,
        displayName: "Chapter 5",
        pages: sterlingComic,
        stage: 5
    },
    SKIN: {
        cover: "/img/comic/covers/Chapter_Icons_06.jpg",
        name: ComicName.SKIN,
        displayName: "Chapter 6",
        pages: skinComic,
        stage: 6
    },
    DONT_SHOOT: {
        cover: "/img/comic/covers/Chapter_Icons_07.jpg",
        name: ComicName.DONT_SHOOT,
        displayName: "Chapter 7",
        pages: dontShootComic,
        stage: 7
    },
    DETENTE: {
        cover: "/img/comic/covers/Chapter_Icons_08.webp",
        name: ComicName.DETENTE,
        displayName: "Chapter 8",
        pages: detenteComic,
        stage: 8
    },
    SOCK_HOP: {
        cover: "/img/comic/covers/Chapter_Icons_09.webp",
        name: ComicName.SOCK_HOP,
        displayName: "Chapter 9",
        pages: sockHopComic,
        stage: 9
    },
    LIGHTS: {
        cover: "/img/comic/covers/Chapter_Icons_10B.webp",
        name: ComicName.LIGHTS,
        displayName: "Chapter 10",
        pages: lightsComic,
        stage: 10
    }
};
