import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    checkNetwork,
    connectToMetamask, disconnectMetamask,
    getTokenBalance,
    selectTokenBalance,
    selectWalletAddress,
    selectWalletConnected,
    selectWalletConnectionError,
    selectWalletInstalled,
    selectWeb3Token,
    sliceGetWeb3Token,
    tryInstallMetamask
} from "../metamask/metamaskSlice";
import MetamaskFrame from "./MetamaskFrame";
import ScreenCenterWrapper from "../utils/ScreenCenterWrapper";
import useFancyNav from "../../hooks/useFancyNav";
import { toast, ToastContainer } from "react-toastify";
import { fetchUserEy3k0nMetadata } from "../ey3k0nMetadata/ey3k0nMetadataSlice";
import { fetchUserCoverMetadata } from "../coverMetadata/coverMetadataSlice";
import { AuthStatus, checkEmailPassword, selectAuthStatus, selectAuthorized } from "../authentication/authSlice";

interface MetamaskScreenProps {
    hasPermission: () => boolean,
    targetBtnText: string,
    targetUrl: string,
    autoNavigate: boolean,
    permissionConditions?: any[],
    requireSign: boolean
    enableEmailAndPasswordOption?: boolean
}

function MetamaskScreen (props: MetamaskScreenProps) {
    const walletConnected = useAppSelector(selectWalletConnected);
    const tokenBalance = useAppSelector(selectTokenBalance);
    const walletInstalled = useAppSelector(selectWalletInstalled);
    const web3Token = useAppSelector(selectWeb3Token);
    const walletConnectionError = useAppSelector(selectWalletConnectionError);
    const walletAddress = useAppSelector(selectWalletAddress);
    const dispatch = useAppDispatch();
    const { navigateTo } = useFancyNav();

    useEffect(() => {
        if (walletConnected) {
            dispatch(checkNetwork("dummy"));
            dispatch(getTokenBalance(walletAddress));
            dispatch(fetchUserEy3k0nMetadata(walletAddress));
            dispatch(fetchUserCoverMetadata(walletAddress));
        }
    }, [walletConnected, walletAddress]);

    function disconnectWallet () {
        dispatch(disconnectMetamask());
    }

    function tryConnectToMetamask () {
        dispatch(connectToMetamask("dummy"));
    }

    function tryGetWeb3Token () {
        dispatch(sliceGetWeb3Token("Ey3k0n wants you to sign in with your Ethereum account.\nThis signature is required in order to securely look you up into the comics database."));
    }

    function gotoUrl () {
        if (props.hasPermission()) {
            navigateTo(props.targetUrl);
        }
    }

    function installMetamask () {
        dispatch(tryInstallMetamask());
    }

    useEffect(() => {
        if (props.autoNavigate && props.hasPermission()) {
            navigateTo(props.targetUrl);
        };
    }, [props.permissionConditions]);

    const afterWalletConnectedButton = () => {
        if (props.requireSign) {
            if (!web3Token) {
                return (
                    <div className="metamask-button sq-btn" onClick={tryGetWeb3Token}>
                        <span className="metamask-connect hidden">SIGN</span>
                    </div>
                );
            } else {
                return (
                    <div className={`metamask-button sq-btn ${!props.hasPermission() ? " no-hover" : ""}`} onClick={gotoUrl}>
                        <span className="metamask-connected">{ props.hasPermission() ? props.targetBtnText : "ACCESS PASS REQUIRED"}</span>
                    </div>
                );
            }
        }
        return (
            <div className={`metamask-button sq-btn ${!props.hasPermission() ? " no-hover" : ""}`} onClick={gotoUrl}>
                <span className="metamask-connected">{ props.hasPermission() ? props.targetBtnText : "ACCESS PASS REQUIRED"}</span>
            </div>
        );
    };

    // enable email password connection
    const authStatus = useAppSelector(selectAuthStatus);
    const emailAuthorized = useAppSelector(selectAuthorized);
    const [xEmailPasswordForm, setXEmailPasswordForm] = useState(false);
    const emailRef = useRef("") as any;
    const passwordRef = useRef("") as any;
    const handleCheckEmailPassword = () => {
        dispatch(checkEmailPassword({
            email: emailRef.current.value,
            password: passwordRef.current.value
        }));
    };

    // toast utils
    const toastParams = {
        position: "top-center",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        className: "metamask-toast",
        bodyClassName: "metamask-toast-body"
    };
    const toastSuccess = (message: string) => toast.success(message, toastParams as any);
    const toastError = (message: string) => toast.error(message, toastParams as any);
    useEffect(() => {
        if (authStatus === AuthStatus.ERROR) {
            toastError("UNABLE TO AUTHENTICATE");
        }
        if (authStatus === AuthStatus.SUCCESS) {
            toastSuccess("SUCCESS");
            setXEmailPasswordForm(false);
        }
    }, [authStatus]);
    return (
        <MetamaskFrame>
            <div className="metamask-scrn metamask-screen a100 connect active">
                <div className="metamask-container metamask-center-content">
                    <ToastContainer />
                    <div className="metamask-title msc-entry btm-separator">
                        <span><strong>META</strong>Mask</span>
                    </div>
                    <div className="metamask-logo-box msc-entry">
                        <div className="metamask-logo"></div>
                    </div>
                    { !props.hasPermission()
                        ? <div className="metamask-message msc-entry btm-separator top-separator">
                            { !walletConnected && walletInstalled && <span className="metamask-connect">Connect to <strong>META</strong>Mask</span>}
                            { !walletInstalled && <span className="metamask-connect">Click to install <strong>META</strong>Mask</span>}
                            { walletConnectionError && <span className="metamask-connecting">Check your wallet</span>}

                            {walletConnectionError && <span className="metamask-retry"><strong
                                className="metamask-error-msg">Unsuccesful Connection</strong></span>}
                        </div>
                        : <div className="metamask-message msc-entry btm-separator top-separator">
                            { walletConnected &&
                                <div className="mnt-mm-connected active">
                                    <span>Connected as:</span>
                                    <div id="mnt-mm-account-box">
                                        <div id="mnt-mm-accID">{`${walletAddress.substr(0, 6)}...${walletAddress.substr(-4)}`}</div>
                                        <div id="mnt-mm-disconnect-button" className="sq-btn" onClick={disconnectWallet}>DISCONNECT</div>
                                    </div>
                                </div>
                            }
                            {
                                emailAuthorized &&
                                <div className="mnt-mm-connected active">
                                    <span>Email connected</span>
                                </div>
                            }
                        </div>
                    }
                    { !props.hasPermission()
                        ? <div className="metamask-button-box msc-entry">
                            { !walletInstalled &&
                                <div className="metamask-button sq-btn" onClick={installMetamask}>
                                    <span className="metamask-connect hidden">INSTALL</span>
                                </div>
                            }
                            { !walletConnected && walletInstalled &&
                                <div className="metamask-button sq-btn" onClick={tryConnectToMetamask}>
                                    <span className="metamask-connect hidden">CONNECT WALLET</span>
                                </div>
                            }
                            { props.enableEmailAndPasswordOption &&
                                <div style={{ marginTop: "20px" }} className="metamask-button sq-btn" onClick={() => setXEmailPasswordForm(true)}>
                                    <span className="metamask-connect hidden">USE EMAIL</span>
                                </div>
                            }
                            {/*
                                <div id="mnt-mm-button" className="sq-btn">
                                    <span className="mnt-mm-connecting">PROCESSING</span>
                                    <span className="mnt-mm-retry">RETRY</span>
                                </div>
                            */}
                        </div>
                        : <div className="metamask-button-box msc-entry">
                            { afterWalletConnectedButton() }
                        </div>
                    }
                    { xEmailPasswordForm &&
                        <ScreenCenterWrapper
                            widthPercent={100}
                            heightPercent={100}
                            mobileWidthPercent={100}
                            mobileHeightPercent={100}>
                            <div className="email-container">
                                <div className="email-form-wrapper">
                                    <div className="email-form">
                                        <div className="email-form-title-section">
                                            <div className="email-form-title">AUTHENTICATE</div>
                                            <div className="email-form-close-button" onClick={() => setXEmailPasswordForm(false)}>X</div>
                                        </div>
                                        <div className="email-form-input-section">
                                            <input ref={emailRef} id="mnt-sub-text" type="text" placeholder="EMAIL" name="email" autoComplete="off" required onChange={() => {}} />
                                            <input ref={passwordRef} id="mnt-sub-text" type="password" placeholder="PASSWORD" name="password" autoComplete="off" required onChange={() => {}} />
                                        </div>
                                        <div className="email-form-submit-section">
                                            { authStatus === AuthStatus.PENDING &&
                                                <div className="email-form-loading">Loading ... </div>
                                            }
                                            { (authStatus !== AuthStatus.PENDING && !emailAuthorized) &&
                                                <div className="email-form-submit-button metamask-button sq-btn" onClick={handleCheckEmailPassword}>
                                                    <span className="metamask-connect hidden">SUBMIT</span>
                                                </div>
                                            }
                                            { (authStatus !== AuthStatus.PENDING && emailAuthorized) &&
                                                <div className="email-form-submit-button metamask-button sq-btn" onClick={() => setXEmailPasswordForm(false)}>
                                                    <span className="metamask-connect hidden">CLOSE</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScreenCenterWrapper>
                    }
                </div>
            </div>
        </MetamaskFrame>
    );
}

export default MetamaskScreen;
