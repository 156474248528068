import { useEffect } from "react";
import { hideCloseButton, showCloseButton } from "../features/device-ui/deviceUISlice";
import { useAppDispatch } from "../app/hooks";

export function useCloseButton (onClosedClicked: () => void) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(showCloseButton(onClosedClicked));
        return () => {
            dispatch(hideCloseButton());
        };
    }, []);
}
