/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { verifyEmailAndPassword } from "../firebase/functions";

export enum AuthStatus {
    IDLE = "IDLE",
    PENDING = "PENDING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

interface EmailPasswordCred {
    email: string,
    password: string
}

export interface AuthState {
    authStatus: AuthStatus,
    authorized: boolean
}

const initialState: AuthState = {
    authStatus: AuthStatus.IDLE,
    authorized: false
};

export const checkEmailPassword = createAsyncThunk(
    "auth/checkEmailPassword",
    async (cred: EmailPasswordCred, thunkApi) => {
        const state: any = thunkApi.getState();
        const res = await verifyEmailAndPassword(cred);
        return res.data as boolean;
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(checkEmailPassword.pending, (state, action) => {
                state.authStatus = AuthStatus.PENDING;
            })
            .addCase(checkEmailPassword.rejected, (state, action) => {
                state.authStatus = AuthStatus.ERROR;
            })
            .addCase(checkEmailPassword.fulfilled, (state, action) => {
                state.authorized = action.payload;
                if (action.payload) {
                    state.authStatus = AuthStatus.SUCCESS;
                    return;
                }
                state.authStatus = AuthStatus.ERROR;
            });
    }
});

// export const {
// } = authSlice.actions;

export const selectAuthorized = (state: RootState) => state.auth.authorized;
export const selectAuthStatus = (state: RootState) => state.auth.authStatus;
export default authSlice.reducer;
