import React, { useEffect, useState } from "react";
import RadioWidget from "../radio/RadioWidget";
import RadioWidgetMobile from "../radio/RadioWidgetMobile";
import { useSelector } from "react-redux";
import {
    selectActiveButton,
    selectDeviceStatus, selectNavDisabled
} from "./deviceUISlice";
import { selectTransforms } from "../transforms/transformsSlice";
import { DeviceUIButton } from "./DeviceUIButton";
import { useAppSelector } from "../../app/hooks";
import { DeviceUIStatus } from "./DeviceUIStatus";
import useFancyNav from "../../hooks/useFancyNav";
import TimeWidget from "./TimeWidget";
import DateWidget from "./DateWidget";
import CloseButton from "./CloseButton";

interface DeviceUIProps {
    children?: React.ReactNode
}

export default function DeviceUI (props: DeviceUIProps) {
    const deviceStatus = useSelector(selectDeviceStatus);
    const [deviceStatusClassName, setDeviceStatusClassName] = useState("off");
    const fancyNav = useFancyNav();
    const navDisabled = useAppSelector(selectNavDisabled);

    useEffect(() => {
        switch (deviceStatus) {
        case DeviceUIStatus.SWITCHING_ON:
            setDeviceStatusClassName("switchingOn");
            break;
        case DeviceUIStatus.ON:
            setDeviceStatusClassName("");
            break;
        case DeviceUIStatus.OFF:
        default:
            setDeviceStatusClassName("off");
            break;
        }
    }, [deviceStatus, deviceStatusClassName]);

    const transforms = useSelector(selectTransforms);

    const navigateTo = (path: string) => {
        if (navDisabled) {
            console.log("WARN: NAV Disabled");
            return;
        }
        fancyNav.navigateTo(path);
    };
    const activeBtn = useAppSelector(selectActiveButton);

    return (
        <div id="device" className={`a100 ${deviceStatusClassName}`}>
            <div id="frame" className="a100">
                <div id="grid-tl" className="grid-corner"></div>
                <div id="grid-tr" className="grid-corner"></div>
                <div id="grid-bl" className="grid-corner"></div>
                <div id="grid-br" className="grid-corner"></div>
                <div id="grid-lft" className="grid-side"></div>
                <div id="grid-rgt" className="grid-side"></div>

                <div id="ft" className="f-central">
                    <div className="ft-TL">
                        <div className="ft-B">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                        <div className="ft-A">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                    </div>
                    <div className="ft-TR">
                        <div className="ft-B">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                        <div className="ft-A">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                    </div>
                    <div id="ft-skull-btn" className="ft-MM"></div>
                    <div className="ft-Title">
                        <div className="ft-Title-A"></div>
                    </div>
                </div>
                <div id="fb" className="f-central">
                    <div className="ft-TL">
                        <div className="ft-B">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                        <div className="ft-A">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                        <div className="fb-meter"></div>
                    </div>
                    <div className="ft-TR">
                        <div className="ft-B">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                        <div className="ft-A">
                            <div className="ft-L"></div>
                            <div className="ft-M"></div>
                            <div className="ft-R"></div>
                        </div>
                        <div className="fb-meter"></div>
                    </div>
                    <div id="discord-menu" className="menu">
                        <div id="entry-discord" className="mm-entry">
                            <a href="https://discord.gg/ey3k0n" target="blank">
                                <div id="btn-discord" className="mm-btn">
                                </div>
                            </a>
                            <div className="mm-btn-lbl">DISCORD</div>
                        </div>
                    </div>
                </div>
                <div id="fs-LL" className="f-side">
                    <div className="fs-TT">
                        <div className="fs-B">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                        <div className="fs-A">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                    </div>
                    <div className="fs-BB">
                        <div className="fs-B">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                        <div className="fs-A">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                    </div>
                    <div className="fs-Detail">
                        <div className="fs-Detail-B"></div>
                        <div className="fs-Detail-A"></div>
                    </div>
                </div>
                <div id="fs-RR" className="f-side">
                    <div className="fs-TT">
                        <div className="fs-B">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                        <div className="fs-A">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                    </div>
                    <div className="fs-BB">
                        <div className="fs-B">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                        <div className="fs-A">
                            <div className="fs-L"></div>
                            <div className="fs-M"></div>
                            <div className="fs-R"></div>
                        </div>
                    </div>
                    <div className="fs-Detail">
                        <div className="fs-Detail-B"></div>
                        <div className="fs-Detail-A"></div>
                    </div>
                </div>
                <div id="fc-TL" className="f-corner">
                    <div className="fc-A">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                    <div className="fc-B">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                </div>
                <div id="fc-TR" className="f-corner">
                    <div className="fc-A">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                    <div className="fc-B">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                </div>
                <div id="fc-BL" className="f-corner">
                    <div className="fc-A">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                    <div className="fc-B">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                </div>
                <div id="fc-BR" className="f-corner">
                    <div className="fc-A">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                    <div className="fc-B">
                        <div className="fc-L"></div>
                        <div className="fc-M"></div>
                        <div className="fc-R"></div>
                    </div>
                </div>
                <div id="fm-Head" className="f-mobile">
                    <div id="fm-skull-btn" className="act"></div>
                </div>
                <div id="fm-L" className="f-mobile"></div>
                <div id="fm-R" className="f-mobile"></div>
            </div>
            <div id="device-widgets">
                <DateWidget />
                <TimeWidget />
                <RadioWidget />

                <div id="dw-dotcode-bl" className="dw-widget dw-dotcode-dskt"></div>
                <div id="dw-dotcode-br" className="dw-widget dw-dotcode-dskt"></div>

                <div id="dw-dotcode-mbl" className="dw-widget dw-dcm-wrapper">
                    <div className="dw-dotcode-mobile"></div>
                </div>
                <div id="dw-dotcode-mbr" className="dw-widget dw-dcm-wrapper">
                    <div className="dw-dotcode-mobile"></div>
                </div>
                <div id="dw-dotcode-mtl" className="dw-widget dw-dcm-wrapper">
                    <div className="dw-dotcode-mobile"></div>
                </div>
                <div id="dw-dotcode-mtr" className="dw-widget dw-dcm-wrapper">
                    <div className="dw-dotcode-mobile"></div>
                </div>
            </div>
            <CloseButton />
            {/* <div id="close-button-container" className="s2ht5050" style={{ transform: transforms.s2ht5050 }}>
                <div ref={closeBtnRef} id="close-button" className="close-button hidden" onClick={() => {
                    if (closeButtonAction) {
                        closeButtonAction();
                    }
                }}></div>
            </div> */}
            <div id="main-menu-container" className="s2HorW" style={{ transform: transforms.s2HorW }}>
                <div id="main-menu" className="menu">
                    <div id="entry-about" className="mm-entry" onClick={() => navigateTo("/code")}>
                        <div id="btn-about" className={`mm-btn ${activeBtn === DeviceUIButton.CODE ? "active" : ""}`}>
                            <div className="mm-btn-decor"></div>
                        </div>
                        <div className="mm-btn-lbl">AB0UT</div>
                    </div>
                    <div id="entry-factions" className="mm-entry" onClick={() => navigateTo("/ey3k0ns")}>
                        <div id="btn-factions" className={`mm-btn ${activeBtn === DeviceUIButton.EY3K0NS ? "active" : ""}`}>
                            <div className="mm-btn-decor"></div>
                        </div>
                        <div className="mm-btn-lbl">EY3K0NS</div>
                    </div>
                    <a href="https://get.ey3k0n.com" target="_blank"><div id="entry-roadmap" className="mm-entry" >
                        <div id="btn-roadmap" className={`mm-btn ${activeBtn === DeviceUIButton.MAP ? "active" : ""}`}>
                            <div className="mm-btn-decor"></div>
                        </div>
                        <div className="mm-btn-lbl">KickStarter</div>
                    </div></a>
                </div>
            </div>
            <div id="secondary-menu-container" className="s2HorW" style={{ transform: transforms.s2HorW }} >
                <div id="secondary-menu-comic" className="menu">
                    {/* <div id="entry-mint" className="mm-entry" onClick={() => navigateTo("/mint")}>
                        <div id="btn-mint" className={`mm-btn ${activeBtn === DeviceUIButton.MINT ? "active" : ""}`}>
                            <div className="mm-btn-decor"></div>
                        </div>
                        <div className="mm-btn-lbl">MINT</div>
                    </div> */}
                    <div id="entry-comic" className="mm-entry" onClick={() => navigateTo("/c0m1cs")}>
                        <div id="btn-comic" className={`mm-btn ${activeBtn === DeviceUIButton.COMIC ? "active" : ""}`}>
                            <div className="mm-btn-decor"></div>
                        </div>
                        <div className="mm-btn-lbl">C0M1CS</div>
                    </div>
                </div>
                <div id="secondary-menu-shop" className="menu">
                    <div id="entry-shop" className="mm-entry" onClick={() => navigateTo("/sh0p")}>
                        <div id="btn-shop" className={`mm-btn ${activeBtn === DeviceUIButton.SHOP ? "active" : ""}`}>
                            <div className="mm-btn-decor"></div>
                        </div>
                        <div className="mm-btn-lbl">SH0P</div>
                    </div>
                </div>
                <RadioWidgetMobile />
                <div id="mobdis-menu" className="menu">
                    <div id="entry-mobdis" className="mm-entry">
                        <a href="https://discord.gg/ey3k0n" target="blank">
                            <div id="btn-mobdis" className="mm-btn">
                                <div className="mm-btn-decor"></div>
                            </div>
                        </a>
                        <div className="mm-btn-lbl">DISCORD</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
