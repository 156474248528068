import React from "react";
import useFancyNav from "../../hooks/useFancyNav";
import MintFrame from "./MintFrame";
import { useSkullVideo } from "../../hooks/useSkullVideo";
import { useCloseButton } from "../../hooks/useCloseButton";

function PresaleOverScreen (props: any) {
    const fancyNav = useFancyNav();
    const onClosedClicked = () => {
        fancyNav.navigateTo("/mint");
    };
    useSkullVideo([]);
    useCloseButton(onClosedClicked);

    return (
        <MintFrame>
            <div id="mint-presaleover-scrn" className="mint-screen a100 active">
                <div id="mint-presaleover-container" className="mint-side-content">
                    <div id="mnt-presaleover-title" className="msc-entry">
                        <span>PRESALE IS <strong>OVER!</strong></span>
                    </div>
                    <div id="mnt-presaleover-message" className="mnt-msg msc-entry btm-separator top-separator">
                        <span>The bad news is the presale is over. The good news is you can still get your hands on an EY3KON in the upcoming Public sale! Or check the secondary market.</span>
                    </div>
                    <div id="mnt-presaleover-links" className="mnt-links-box msc-entry">
                        <div className="mnt-opensea-button sq-btn">
                            <a href="https://www.opensea.io/ey3k0n" target="_blank">BUY ON OPENSEA</a>
                        </div>
                    </div>
                </div>
            </div>
        </MintFrame>
    );
}

export default PresaleOverScreen;
