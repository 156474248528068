import React from "react";
import { useSelector } from "react-redux";
import { selectTransforms } from "../features/transforms/transformsSlice";

function ContentViewport (props: any) {
    const transforms = useSelector(selectTransforms);
    const contentStyles: any = {
        transform: transforms.s2ht5050
    };

    return (
        <div id="content-viewport" className="a100">
            <div id="content" className="aHD s2ht5050" style={contentStyles}>
                {props.children}
            </div>
        </div>
    );
}

export default ContentViewport;
