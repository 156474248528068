import { useEffect } from "react";

export default function useFadeIn (sectionRef: any, _opts: any = {}) {
    const opts = {
        faceInTimeout: 100,
        callback: undefined,
        ..._opts
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            sectionRef.current?.classList.add("active");
            if (opts.callback) {
                opts.callback();
            }
        }, opts.faceInTimeout);
        return () => clearTimeout(timeoutId);
    }, []);
}
