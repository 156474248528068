import React, { useEffect, useState } from "react";
const MONTHS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

function TimeWidget (props: any) {
    const [date, setDate] = useState({
        day: 0,
        month: 0,
        year: 0
    });

    function update () {
        const now = new Date(Date.now());
        const values: any = {};
        values.day = now.getDate();
        values.month = MONTHS[now.getMonth()];
        values.year = now.getFullYear();
        setDate(values);
    }

    useEffect(() => {
        update();
        const timeoutId = setInterval(update, 5000);

        return () => clearInterval(timeoutId);
    }, []);

    return (
        <div id="dw-date" className="dw-widget">
            <div id="dw-day"><span className="dw-fnt">{date.day.toString().padStart(2, "0")}</span></div>
            <div id="dw-month"><span className="dw-fnt">{date.month}</span></div>
            <div id="dw-year"><span className="dw-fnt">{date.year}</span></div>
        </div>
    );
}

export default TimeWidget;
