import { useSwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { ComicArea } from "./ComicArea";
import { debounce } from "lodash";
import backgroundSlice from "../background/backgroundSlice";

export const getAreaRealSize = (pageSizeOriginal: any, pageSizeCurrent: any, item: any) => {
    const width =
        (pageSizeCurrent.offsetWidth * item.width) / pageSizeOriginal[0];
    const height =
        (pageSizeCurrent.offsetHeight * item.height) / pageSizeOriginal[1];
    const offsetLeft =
        (pageSizeCurrent.offsetWidth * item.offsetLeft) / pageSizeOriginal[0];
    const offsetTop =
        (pageSizeCurrent.offsetHeight * item.offsetTop) / pageSizeOriginal[1];
    return { width, height, offsetLeft, offsetTop };
};

interface ComicPageProps {
    swiper: any,
    size: any,
    page: any,
    currentArea: any,
    setCurrentArea: any
}
export function ComicPage (props: ComicPageProps) {
    const swiperSlide = useSwiperSlide();
    const ref = useRef(null);
    const [transform, setTransform] = useState("none");
    const [isSwiped, setIsSwiped] = useState(false);

    const [pageSizeCurrent, setPageSizeCurrent] = useState({
        offsetWidth: 0,
        offsetHeight: 0
    });

    const onAreaShow = () => {
        const pageEl: any = ref.current;
        const { offsetWidth, offsetHeight } = pageEl;
        const item = props.page.children[props.currentArea - 1];

        const { width, height, offsetLeft, offsetTop } = getAreaRealSize(
            props.size,
            { offsetWidth, offsetHeight },
            item
        );

        let scale = 1;
        if (width > height) {
            scale = pageEl.offsetWidth / width;
        } else {
            scale = pageEl.offsetHeight / height;
        }
        const x =
            offsetLeft * scale * -1 + (pageEl.offsetWidth - width * scale) / 2;
        const y =
            offsetTop * scale * -1 + (pageEl.offsetHeight - height * scale) / 2;

        setTransform(`translate(${x}px, ${y}px) scale(${scale})`);
    };

    const resetScale = () => {
        setTransform("scale(1)");
    };
    const onAreaHide = () => {
        resetScale();
    };

    const onSwiped = (dir: any) => {
        if (props.currentArea === 0) return;
        if (props.currentArea === props.page.children.length + 1) return;
        setIsSwiped(true);
        if (dir === "Left") {
            props.setCurrentArea((state: any) => state + 1);
        } else if (dir === "Right") {
            props.setCurrentArea((state: any) => state - 1);
        }
    };

    const onAreaToggle = (index: number) => {
        if (isSwiped) return setIsSwiped(false);

        if (props.currentArea === index) {
            props.setCurrentArea(1);
        } else {
            props.setCurrentArea(index);
        }
    };

    useEffect(() => {
        if (!props.currentArea || props.currentArea > props.page.children.length) {
            onAreaHide();
        } else {
            onAreaShow();
        }
        // eslint-disable-next-line
    }, [props.currentArea]);

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize () {
            const { offsetWidth, offsetHeight } = ref.current as any;
            setPageSizeCurrent({ offsetWidth, offsetHeight });
        }, 1000);

        window.addEventListener("resize", debouncedHandleResize);

        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []);

    useEffect(() => {
        const { offsetWidth, offsetHeight } = ref.current as any;
        setPageSizeCurrent({ offsetWidth, offsetHeight });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="comic-wrapper">
            <div
                ref={ref}
                className="comic-page"
                style={{
                    transform,
                    background: `url(${props.page.img}) center/100% no-repeat`
                }}
            >
                {swiperSlide.isActive &&
                    props.page.children.map((item: any, index: any) => {
                        return (
                            <ComicArea
                                onSwiped={onSwiped}
                                onDoubleClick={(e: any) => {
                                    // console.log(e);
                                    onAreaToggle(index + 1);
                                }}
                                key={index}
                                pageSizeCurrent={pageSizeCurrent}
                                size={props.size}
                                item={item}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
