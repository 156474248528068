import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFancyNav from "../../hooks/useFancyNav";
import { incubateVerifyPin } from "../firebase/functions";
import { selectHasIncubatePerms, setHasIncubatePerms } from "./incubateSlice";

const KeyPadScreen = (props: any) => {
    const body = useBodyClasses();
    const dispatch = useAppDispatch();
    const { navigateTo } = useFancyNav();

    useEffect(() => {
        setTimeout(() => {
            body.setClasses(["sec-keypad"]);
        }, 100);
    }, []);

    // auto navigate if succeeds
    const hasIncubatePerms = useAppSelector(selectHasIncubatePerms);
    useEffect(() => {
        if (hasIncubatePerms) {
            navigateTo("/incubate/video");
        }
    }, [hasIncubatePerms]);

    const [pin, setPin] = useState("");
    const [verifying, setVerifying] = useState(false);
    const addToPin = (num: number) => {
        if (pin.length < 5) {
            setPin(pin + num.toString());
        }
    };
    useEffect(() => {
        if (pin.length === 5) {
            handleSubmit();
        }
    }, [pin]);
    const handleClear = () => {
        setPin("");
    };
    const handleSubmit = async () => {
        setVerifying(true);
        const res = await incubateVerifyPin(pin);
        setVerifying(false);
        // set result
        dispatch(setHasIncubatePerms(res.data as boolean));
    };

    return (
        <div id="keypad-container">
            <div id="keypad-wrapper">
                <div id="keypad-result">
                    <div id="keypad-result-text">
                        {pin.padEnd(5, "_")}
                    </div>
                </div>
                <div id="keypad-entries-wrapper">
                    {
                        Array.from(new Array(10), (x, i) => i + 0).map((val: number, idx: number) => {
                            return (
                                <div key={idx} className={`keypad-pin-btn keypad-entry-btn-${val} ${pin.includes(val.toString()) ? "disabled" : ""}`} onClick={() => addToPin(val)} />
                            );
                        })
                    }
                    <div className="keypad-pin-btn keypad-entry-btn-back" onClick={handleClear}/>
                </div>
                {/* <div id="keypad-verify-lbl">
                    {verifying ? "V3R1FY1NG" : ""}
                </div> */}
            </div>
        </div>
    );
};

export default KeyPadScreen;
