import React, { useEffect, useRef, useState } from "react";
import { turnDeviceOn } from "../device-ui/deviceUISlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ContentViewport from "../../components/ContentViewport";
import useFadeIn from "../../hooks/useFadeIn";
import { getRadio } from "./RadioService";
// eslint-disable-next-line camelcase
import { radio_playToggle, radio_setVolume, selectTrackArtwork, selectTrackTitle } from "./radioSlice";
const radio = getRadio();

function RadioSection (props: any) {
    const dispatch = useAppDispatch();
    const sectionRef: any = useRef();

    const radioArtEle: any = useRef();
    const radioInfoEle: any = useRef();

    const artwork = useAppSelector(selectTrackArtwork);
    const trackTitle = useAppSelector(selectTrackTitle);
    const [time, setTime] = useState("00:00:00");
    const [volume, setVolume] = useState(50);

    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
    }, [dispatch]);

    useEffect(() => {
        if (artwork) {
            radioArtEle.current.style["background-image"] = `url(${artwork})`;
        } else {
            radioArtEle.current.style["background-image"] = "none";
        }
    }, [artwork]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!radio.player.isPlaying()) return;

            setTime(radio.player.getTime(true));

            // volume slider
            setVolume(radio.getVolume());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handelVolumeChange = (evt: any) => {
        const value = evt.target?.value;
        setVolume(value);
        dispatch(radio_setVolume(value));
    };

    function togglePlay (res: any) {
        // eslint-disable-next-line camelcase
        dispatch(radio_playToggle());
    }

    return (
        <ContentViewport>
            <div ref={sectionRef} id="sec-radio" className="section whitelist">
                <div id="radio-frame" className="a100">
                    <div id="rad-fs-doteq-title" className="red-doteq deq-5 act"></div>
                    <div id="rad-fs-doteq-footer" className="red-doteq deq-7 act"></div>
                    <div id="rad-fs-circles" className="act"></div>
                </div>
                <div id="rad-fs-title" className="act"><strong>NODE</strong> FM</div>
                <div className="rad-fs-eq rfeq-l">
                    <div className="rfeq-bar rfeqb-l rfeqb-1"></div>
                    <div className="rfeq-bar rfeqb-r rfeqb-2"></div>
                </div>
                <div className="rad-fs-eq rfeq-r">
                    <div className="rfeq-bar rfeqb-l rfeqb-3"></div>
                    <div className="rfeq-bar rfeqb-r rfeqb-4"></div>
                </div>
                <div ref={radioArtEle} id="rad-fs-art" className="radio-art act"></div>
                <div id="rad-fs-time" className="radio-time act">{time}</div>
                <div id="rad-fs-play" className="radio-play act" onClick={togglePlay}></div>
                <div ref={radioInfoEle} id="rad-fs-info" className="radio-info act">{ trackTitle }</div>
                <div id="rad-fs-wave-container" className="act">
                    <div id="rad-fs-wave-bg">
                        <img alt="EQ Animation" src="/img/radio/grey_EQ/animated.gif" id="rad-fs-wave" />
                    </div>
                </div>
                <input onInput={handelVolumeChange} type="range" min="1" max="100" value={volume} step="0.1" id="rad-fs-vol" className="radio-vol act" />

                <div className="radioplayer"
                    data-src-test="https://streaming.radio.co/sced7c0e79/listen"
                    data-src="https://s4.radio.co/s54de34e1d/listen"
                    data-autoplay="false"
                    data-playbutton="false"
                    data-volumeslider="false"
                    data-elapsedtime="false"
                    data-nowplaying="false"
                    data-showplayer="false"
                    data-showartwork="false">
                </div>

            </div>
        </ContentViewport>
    );
}

export default RadioSection;
