import React from "react";
import { useAppSelector } from "../../app/hooks";
import ComicCoverCard from "./ComicCoverCard";
import { selectComics } from "./comicSlice";

const BrowsePageCards = () => {
    // Comic details
    const comics = useAppSelector(selectComics);

    return (
        <div id="browse-page-cards">
            { comics.map((item, idx) => {
                return (
                    <ComicCoverCard key={idx} comic={item} />
                );
            })}
        </div>
    );
};

export default BrowsePageCards;
