/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { hideCloseButton, selectCloseButtonAction, showCloseButton } from "../device-ui/deviceUISlice";
import { useSelector } from "react-redux";

interface TermsAndConditionsProps {
    visible: boolean;
    onClose: () => void;
}

function TermsAndConditions (props: TermsAndConditionsProps) {
    const [cssClass, setCssClass] = useState("hidden");
    const dispatch = useAppDispatch();

    function close () {
        setCssClass("hidden");
        if (props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        if (!props.visible) {
            setCssClass("hidden");
        } else {
            setCssClass("active");
        }
    }, [props.visible]);

    const closeButtonAction: any = useSelector(selectCloseButtonAction);

    useEffect(() => {
        if (!props.visible) return;
        dispatch(hideCloseButton());

        return () => {
            dispatch(showCloseButton(closeButtonAction));
        };
    }, [props.visible]);

    return (
        <div id="mint-terms-overlay" className={`mint-screen a100 ${cssClass}`}>
            <div id="mint-terms-container">
                <div id="mint-terms-box">
                    <h2>Terms and Conditions for EY3K0N</h2>
                    <p>The EY3KON Genesis NFT Collection is a collection of digital artworks in the form of non-fungible tokens running on the Ethereum network (the “Artistic Works”).</p>
                    <p>These Terms and Conditions constitute a legally binding agreement (these “Terms and Conditions”) between you (also referred to herein as “you”, “your” or “Primary Purchaser”) and EY3K0N Community Ltd. Seychelles International Business Company number 232140, (“we”, “our” or “us”), governing your purchase of NFTs from us.</p>
                    <p>By accepting these Terms and Conditions, and/or by you Purchasing NFTs from us, you expressly acknowledge that you understand these Terms and Conditions and accept them in their entirety. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS, YOU MAY NOT PURCHASE NFTS FROM US.</p>
                    <h4>1. Definitions</h4>
                    <p>“Art” means any art, design, and drawings that may be associated with a NFT that you Own.</p>
                    <p>"NFT" means any blockchain-tracked, non-fungible token, such as those conforming to the ERC-721 standard.</p>
                    <p>“Own” means, with respect to an NFT, an NFT that you have purchased or otherwise rightfully acquired from a legitimate source, where proof of such purchase is recorded on the relevant blockchain.</p>
                    <p>“Purchased NFT” means an NFT that you Own and “Purchase” shall be construed accordingly.</p>
                    <p>“Third Party IP” means any third party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.</p>
                    <h4>2. Ownership and security</h4>
                    <p>2.1 You acknowledge and agree that the Issuer owns all legal right, title and interest in and to the Art, and all intellectual property rights therein.</p>
                    <p>2.2 The rights that you have in and to the Art are limited to those described in these Terms and Conditions below.</p>
                    <p>2.3 The Issuer reserves all rights in and to the Art not expressly granted to you in these Terms and Conditions.</p>
                    <p>2.4 All Purchases of NFTs, as well as associated charges, are non-refundable. This no-refund policy shall apply at all times regardless of your decision to terminate usage of the Purchased NFTs, any disruption to the operations of any components of the Purchased NFTs, or any other reason whatsoever.</p>
                    <p>2.5 You are entirely responsible for the safety and management of your own private Ethereum wallets and validating all transactions and contracts generated before approval.</p>
                    <p>2.6 Please be aware that there is no ability to undo, reverse, or restore any transactions once processed so please undertake your own due diligence before purchasing any NFTs.</p>
                    <p>2.7 The NFTs are provided “as is” and “as available” without warranty of any kind. By purchasing NFTs you are accepting sole responsibility for any and all transactions, whether valid or otherwise.</p>
                    <h4>3. License</h4>
                    <p>Subject to your continued compliance with these Terms and Conditions, The Issuer grant you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the Art for your Purchased NFTs, solely for the following purpos</p>
                    <p>1. for your own personal, non-commercial use; </p>
                    <p>2. as part of a marketplace that permits the purchase and sale of your NFTs, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art; or</p>
                    <p>as part of a third party website or application that permits the inclusion, involvement, or participation of your NFTs, provided that the website/application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the website/application. </p>
                    <h4>4. Restrictions</h4>
                    <p>You agree that you may not, nor may you permit any third party to do or attempt to do any of the foregoing without The Issuer’s express prior written consent in each case:</p>
                    <p>1. 	modify the Art for your Purchased NFT in any way, including, without limitation, the shapes, designs, drawings, attributes, or colour schemes;</p>
                    <p>2.   use the Art for your Purchased NFTs to advertise, market, or sell any third party product or service; </p>
                    <p>3.   use the Art for your Purchased NFTs in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; </p>
                    <p>4.   use the Art for your Purchased NFTs in movies, videos, or any other forms of media, except to the limited extent that such use is solely for your own personal, non-commercial use; </p>
                    <p>5.   sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your Purchased NFTs; </p>
                    <p>6.   attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your Purchased NFTs; or </p>
                    <p>7.   otherwise utilize the Art for your Purchased NFTs for your or any third party’s commercial benefit. To the extent that Art associated with your Purchased NFTs contain Third Party IP (e.g., licensed intellectual property from a celebrity, athlete, or other public figure), you understand and agree as follows: </p>
                    <p>     (x) that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; </p>
                    <p>     (y) that, depending on the nature of the license granted from the owner of the Third Party IP, The Issuer may need to pass through additional restrictions on your ability to use the Art; and </p>
                    <p>     (z) to the extent that The Issuer inform you of such additional restrictions in writing (email is permissible), you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of these Terms and Conditions. </p>
                    <h4>5. Terms of License</h4>
                    <p>5.1 The license granted in Section 3 above applies only to the extent that you continue to Own the applicable Purchased NFT.</p>
                    <p>5.2 If at any time you sell, trade, donate, give away, transfer, or otherwise dispose of your Purchased NFT for any reason, the license granted in Section 3 will immediately expire with respect to those NFTs without the requirement of notice, and you will have no further rights in or to the Art for those NFTs.</p>
                    <h4>6. DISCLAIMER OF WARRANTIES & LIMITATION OF LIABILITY.</h4>
                    <p>6.1 AS USED HEREIN THE PHRASE “THE ISSUER” MEANS EY3K0N COMMUNITY LTD. ITS DIRECT AND INDIRECT PRESENT AND FUTURE PARENT, SUBSIDIARY AND AFFILIATE ENTITIES AND THEIR RESPECTIVE VENDORS AND LICENSORS.</p>
                    <p>6.2 ALL PURCHASED NFTS ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE ISSUER DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT SHALL THE ISSUER BE LIABLE TO YOU FOR ANY PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER AND/OR DEVICE OR TECHNOLOGY FAILURE OR MALFUNCTION, OR FOR ANY FORM OF DIRECT OR INDIRECT DAMAGES, AND/OR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES BASED ON ANY CAUSES OF ACTION WHATSOEVER RELATED TO ANY NFT PURCHASED FROM US INCLUDING BUT NOT LIMITED TO THE PURCHASED NFTS, ANY TECHNOLOGY AND/OR PARTIES RELATED TO THE PURCHASE, INCLUDING BUT NOT LIMITED TO BLOCKCHAIN, METAMASK OR OTHER DIGITAL WALLET.</p>
                    <p>6.3 YOU AGREE THAT THIS LIMITATION OF LIABILITY APPLIES WHETHER SUCH ALLEGATIONS ARE FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOUR, NEGLIGENCE, OR FALL UNDER ANY OTHER CAUSE OF ACTION, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS CLAIMED AND EVEN IF A DISCLAIMING PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE AND IN ANY EVENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ISSUER’S TOTAL AGGREGATE LIABILITY SHALL NOT EXCEED 10% OF THE TOTAL SUM PAID DIRECTLY BY YOU TO THE ISSUER FOR THE APPLICABLE PURCHASED NFTS. IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND AGREE THAT THE ISSUER HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO THE ISSUER’S GROSS NEGLIGENCE.</p>
                    <h4>7. ASSUMPTION OF RISK</h4>
                    <p>7.1 You acknowledge and agree as follows:</p>
                    <p>(i) To the extent there is a price or market for a blockchain asset, such markets and prices are extremely volatile, and variations in the price of other digital assets could materially and adversely affect the value of any digital asset(s) you own, such as Purchased NFTs, and there is no guarantee Purchased NFTs will have or retain any value;</p>
                    <p>(ii) there are risks associated with using an Internet-native assets (e.g., non-fungible tokens, cryptocurrencies, etc.) including, but not limited to, the risk of hardware, software and Internet connections and/or failures, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your digital “wallet”, and The Issuer will not be responsible for any of these, however caused;</p>
                    <p>(iii) The Issuer do not make any promises or guarantees about the availability of the Art on the Internet or that they will host the Art at any specific location and/or for any specific period of time;</p>
                    <p>(iv) NFTs are made available solely for entertainment purposes;</p>
                    <p>(v) The Issuer are not responsible for any transaction between you and a third party (e.g., your purchase of a Purchased NFTs from a third party on the so-called “secondary market”), and The Issuer shall have no liability in connection with any such transaction;</p>
                    <p>(vi) The Issuer does not make any promises or guarantees related to any MetaMask Wallet, any other digital wallet, blockchain or any other third parties related to the Purchase and each of their applications and/or services, including but not limited to the continued availability of either and/or the protection and/or storage of any data you provide to those parties;</p>
                    <p>(vii) there also exists:</p>
                    <p>(a) the risk of losing access to Purchased NFTs due to loss of private key(s), custodial error or purchaser error;</p>
                    <p>(b) the risk of mining attacks;</p>
                    <p>(c) the risk of hacking, security weaknesses, fraud, counterfeiting, cyberattacks and other technological difficulties;</p>
                    <p>(d) the risk of changes to the regulatory regime governing blockchain technologies, cryptocurrencies, and tokens and new regulations, unfavourable regulatory intervention in one or more jurisdictions or policies any of which may materially adversely affect the use and value of the Purchased NFTs; and</p>
                    <p>(e) the risks related to taxation;</p>
                    <p>(viii) that NFTs are not legal tender and are not backed by any government; and</p>
                    <p>(ix) The Issuer is not responsible for any transaction between you and a third party. In addition to assuming all of the above risks, you acknowledge that you have obtained sufficient information to make an informed decision to license the Purchased NFTs and that you understand and agree that you are solely responsible for determining the nature, potential value, suitability and appropriateness of these risks for yourself. The Issuer cannot and does not represent or warrant that any Purchased NFTs, or its supporting systems or technology, is reliable, current or error-free, meets your requirements, or that defects in the Purchased NFTs, or its supporting systems or technology, will be corrected. The Issuer cannot and does not represent or warrant that the Purchased NFTs or the delivery mechanism for it are free of viruses or other harmful components. You accept and acknowledge that The Issuer will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience in purchasing NFTs from us.</p>
                    <h4>8. Governing Law</h4>
                    <p>8.1 These Terms and Conditions and all matters related to them and/or any NFTs shall be governed by, construed, and enforced in accordance with the laws of England and Wales, as they are applied to agreements entered into and to be performed entirely within England and Wales and without regard to conflict of law principles, except to the extent that law is inconsistent with or pre-empted by applicable law.</p>
                    <p>8.2 Any action related to these Terms and Conditions and/or any NFT shall be brought in the appropriate courts located in England and Wales; and you and The Issuer both irrevocably consent to the non-exclusive jurisdiction and venue of the courts in England and Wales for the adjudication of all claims.</p>
                    <h4>9. Changes</h4>
                    <p>9.1 The Issuer may make changes to these Terms and Conditions from time to time. When The Issuer makes changes, The Issuer will make the updated Terms and Conditions available on The Issuer’s website and update the “Last Updated” date at the beginning of these Terms and Conditions accordingly.</p>
                    <p>9.2 Please check these Terms and Conditions periodically for changes. Any changes to these Terms and Conditions will apply on the date that they are made and, by way of example, your continued access to or use of the Art after these Terms and Conditions has been updated will constitute your binding acceptance of the updates.</p>
                </div>
                <div id="mint-tnc-decor-container">
                    <div id="mtnc-tl" className="mtnc-corner"></div>
                    <div id="mtnc-tr" className="mtnc-corner"></div>
                    <div id="mtnc-bl" className="mtnc-corner"></div>
                    <div id="mtnc-br" className="mtnc-corner"></div>
                </div>
                <div id="mint-tnc-close-button" className="close-button active" onClick={close}></div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
