import { store } from "../../app/store";
import { onSongChanged, RadioState, setRadioStatus } from "./radioSlice";
import { RadioStatus } from "./RadioStatus";

class Radio {
    player: any;
    private volume: any;
    private tryingToPlay: boolean;
    private tryingToPause: boolean;
    constructor () {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        this.player = $(".radioplayer").radiocoPlayer();
        this.player.load(() => {
            console.log("Player is loaded");
        });
        this.player.volume(50);
        this.volume = 50;
        this.tryingToPlay = false;
        this.tryingToPause = false;
    }

    addEventListener (name: string, callback: (evt?: any) => void) {
        this.player.event(name, callback);
    }

    setVolume (value: any) {
        this.player.volume(value);
        this.volume = value;
    }

    getVolume () {
        return this.volume;
    }

    async playToggle () {
        await this.player.playToggle();
    }

    async play () {
        try {
            if (this.tryingToPlay) return;
            this.tryingToPlay = true;
            await this.player.play();
        } catch (e) {
            console.log("RADIO:PLAY", e);
        } finally {
            this.tryingToPlay = false;
        }
    }

    async pause () {
        try {
            if (this.tryingToPause) return;
            this.tryingToPause = true;
            await this.player.pause();
        } catch (e) {
            console.log("RADIO:PAUSE", e);
        } finally {
            this.tryingToPause = false;
        }
    }
}

const radio: Radio = new Radio();

// @ts-ignore
let currentState: RadioState = {};

async function handelChange () {
    const newState = store.getState().radio;

    if (newState.status !== currentState.status) {
        switch (newState.status) {
        case RadioStatus.PLAYING:
            await radio.play();
            break;
        case RadioStatus.PAUSED:
            await radio.pause();
            break;
        }
    }
    if (newState.volume !== currentState.volume) {
        radio.setVolume(newState.volume);
    }
    currentState = newState;
}

function _initRadio (dispatch: any) {
    radio.player.event("audioPlay", (evt: any) => {
        if (currentState.status !== RadioStatus.PLAYING) {
            dispatch(setRadioStatus(RadioStatus.PLAYING));
        }
    });

    radio.player.event("audioPause", function () {
        if (currentState.status !== RadioStatus.PAUSED) {
            dispatch(setRadioStatus(RadioStatus.PAUSED));
        }
    });
    radio.player.event("invalidStream", () => {
        if (currentState.status !== RadioStatus.INVALID_STREAM) {
            dispatch(setRadioStatus(RadioStatus.INVALID_STREAM));
        }
    });
    radio.player.event("songChanged", (evt: any) => {
        dispatch(onSongChanged(evt));
    });
}

store.subscribe(handelChange);
_initRadio(store.dispatch);

export function getRadio () {
    return radio;
}

console.log("Radio innit");
