import React, { useEffect, useRef } from "react";
import { useAppDispatch } from "../../app/hooks";
import { hideCloseButton, showCloseButton } from "../device-ui/deviceUISlice";

interface IntroVideoScreenProps {
    onCloseButtonClicked?: () => void;
}

function IntroVideoScreen (props: IntroVideoScreenProps) {
    const dispatch = useAppDispatch();
    const introVideo: any = useRef();
    const playButton: any = useRef();

    useEffect(() => {
        dispatch(showCloseButton(() => {
            if (props.onCloseButtonClicked) {
                props.onCloseButtonClicked();
            }
        }));
        return () => {
            dispatch(hideCloseButton());
        };
    }, [dispatch, hideCloseButton, showCloseButton]);

    useEffect(() => {
        introVideo.current.addEventListener("play", function () {
            playButton.current?.classList.add("hidden");
        }, false);

        introVideo.current.addEventListener("pause", function () {
            playButton.current?.classList.remove("hidden");
            introVideo.current?.load();
        }, false);

        playButton.current.addEventListener("click", () => {
            playButton.current?.classList.add("hidden");
            introVideo.current?.play();
        });
    }, []);

    return (
        <div className="intro-video-screen">
            <div id="subsec-intro" className="a100 subsection active">
                <div id="intro-video-container" className="act">
                    <video ref={introVideo} id="intro-video" playsInline preload="metadata" controls={true}
                        poster="img/ui/intro_video_poster.jpg">
                        <source src="./videos/EY3K0N_Anthology_Trailer.mp4" />
                    </video>
                    <div id="iv-play-video-container" className="act">
                        <div ref={playButton} id="btn-playvid" className="mm-btn">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IntroVideoScreen;
