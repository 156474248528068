import React, { useEffect, useRef, useState } from "react";
import ContentViewport from "../../components/ContentViewport";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import useFancyNav from "../../hooks/useFancyNav";

const IncubateVideo = () => {
    const body = useBodyClasses();
    const { navigateTo } = useFancyNav();

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    // Control video
    const incubateVideo: any = useRef();
    const navigateNext = () => {
        navigateTo("/incubate/");
    };
    const startVideo = () => {
        incubateVideo.current.play();
    };
    const [isPlaying, setIsPlaying] = useState(false);
    const updateIsPlaying = () => {
        setIsPlaying(true);
    };

    useEffect(() => {
        incubateVideo.current.addEventListener("ended", navigateNext);
        incubateVideo.current.addEventListener("canplay", startVideo);
        incubateVideo.current.addEventListener("playing", updateIsPlaying);
        return () => {
            incubateVideo.current?.removeEventListener("ended", navigateNext);
            incubateVideo.current?.removeEventListener("canplay", startVideo);
            incubateVideo.current?.removeEventListener("playing", updateIsPlaying);
        };
    }, []);

    return (
        <ContentViewport>
            <div ref={sectionRef} className={"section"}>
                <div id="sec-incubate">
                    <div className="incubate-container">
                        <div className="incubate-wrapper">
                            <div id="incubate-video-container">
                                <video id="incubate-video" ref={incubateVideo} playsInline controls preload="auto">
                                    <source src="/videos/Ey3_EDIT_v03.mp4" type="video/mp4" />
                                </video>
                                <div id="incubate-skip-btn" className="sq-btn" onClick={navigateNext}>SKIP</div>
                                { !isPlaying &&
                                    <div id="incubate-play-btn" className="sq-btn" onClick={startVideo}>PLAY VIDEO</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
};

export default IncubateVideo;
