import React, { useEffect, useRef, useState } from "react";
import MintFrame from "./MintFrame";
import { useSkullVideo } from "../../hooks/useSkullVideo";
import { useCloseButton } from "../../hooks/useCloseButton";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    ActionStatus,
    publicMintAsync,
    selectPublicActionStatus,
    selectTokenSupply, setPublicActionStatus
} from "./mintingSlice";
import {
    listenToBalance,
    selectContractAddress,
    selectTokensLeft
} from "../metamask/metamaskSlice";
import TermsAndConditions from "./TermsAndConditions";
// import { listenForTokensLeft } from "../metamask/api/ey3k0nContract";

function MintNFTScreen (props: any) {
    const navigate = useNavigate();
    const tokenSupply = useAppSelector(selectTokenSupply);
    const tokensLeft = useAppSelector(selectTokensLeft);

    const [quantity, setQuantity] = useState(1);
    const [tcAgree, setTCAgree] = useState(false);
    const [screenState, setScreenState] = useState("buy");
    const [showTCs, setShowTCs] = useState(false);
    const [percentLeft, setPercentLeft] = useState(0);
    const sliderRef: any = useRef();
    const dispatch = useAppDispatch();
    const contractAddress = useAppSelector(selectContractAddress);
    const publicActionStatus = useAppSelector(selectPublicActionStatus);

    const onClosedClicked = () => {
        navigate("/mint");
    };
    useSkullVideo(["blurred"]);
    useCloseButton(onClosedClicked);

    useEffect(() => {
        let _percentLeft: number = 0;
        if (tokenSupply > 0) {
            _percentLeft = (tokensLeft / tokenSupply) * 100;
        }
        if (sliderRef.current) {
            sliderRef.current.style.width = `${_percentLeft}%`;
        }
        setPercentLeft(_percentLeft);
    }, [tokenSupply, tokensLeft]);

    useEffect(() => {
        switch (publicActionStatus) {
        case ActionStatus.SUCCESS:
            navigate("/mint/success");
            dispatch(setPublicActionStatus(ActionStatus.IDLE));
            break;
        case ActionStatus.ERROR:
            navigate("/mint/error");
            dispatch(setPublicActionStatus(ActionStatus.IDLE));
            break;
        }
    }, [publicActionStatus]);

    useEffect(() => {
        dispatch(listenToBalance("dummy"));
    }, []);

    function mint () {
        setScreenState("buying");
        dispatch(publicMintAsync({
            quantity
        }));
    }

    return (
        <MintFrame isPublicSale={true} titled={true}>
            <div id="mint-sale-scrn" className={`mint-screen active a100 ${screenState}`}>
                <div id="mnt-sale-container" className="mint-side-content">
                    <div id="mnt-sale-title"></div>
                    <div id="mnt-sale-remain" className="msc-entry mnt-public">
                        <div id="msr-label"><span><strong>{percentLeft?.toFixed(1)}%</strong> Left</span></div>
                        <div id="msr-bar-box">
                            <div ref={sliderRef} id="msr-bar"></div>
                            <div className="msr-bar-mrk lft"><span>0%</span></div>
                            <div className="msr-bar-mrk rght"><span>100%</span></div>
                        </div>
                    </div>
                    <div id="mnt-sale-select" className="msc-entry btm-separator">
                        <div className="mnt-sel-label">Mint Option: <span className="mnt-sel-amount">{quantity}</span> of 10</div>
                        <div className="mnt-sel-btn-box">
                            <div onClick={() => setQuantity(1)} className="mnt-sel-btn-01 sq-btn mss-btn active"><span>1</span></div>
                            <div onClick={() => setQuantity(2)} className={ `mnt-sel-btn-02 sq-btn mss-btn ${quantity > 1 ? "active" : ""}`}><span>2</span></div>
                            <div onClick={() => setQuantity(3)} className={ `mnt-sel-btn-03 sq-btn mss-btn ${quantity > 2 ? "active" : ""}`}><span>3</span></div>
                            <div onClick={() => setQuantity(4)} className={ `mnt-sel-btn-04 sq-btn mss-btn ${quantity > 3 ? "active" : ""}`}><span>4</span></div>
                            <div onClick={() => setQuantity(5)} className={ `mnt-sel-btn-05 sq-btn mss-btn ${quantity > 4 ? "active" : ""}`}><span>5</span></div>
                            <div onClick={() => setQuantity(6)} className={ `mnt-sel-btn-06 sq-btn mss-btn ${quantity > 5 ? "active" : ""}`}><span>6</span></div>

                            <div onClick={() => setQuantity(7)} className={ `mnt-sel-btn-07 sq-btn mss-btn ${quantity > 6 ? "active" : ""}`}><span>7</span></div>
                            <div onClick={() => setQuantity(8)} className={ `mnt-sel-btn-08 sq-btn mss-btn ${quantity > 7 ? "active" : ""}`}><span>8</span></div>
                            <div onClick={() => setQuantity(9)} className={ `mnt-sel-btn-09 sq-btn mss-btn ${quantity > 8 ? "active" : ""}`}><span>9</span></div>
                            <div onClick={() => setQuantity(10)} className={ `mnt-sel-btn-10 sq-btn mss-btn ${quantity > 9 ? "active" : ""}`}><span>10</span></div>

                        </div>
                    </div>
                    <div id="mnt-sale-TnC" className="msc-entry">
                        <div id="ms-TnC-checkbox" className={ tcAgree ? "checked" : "" } onClick={() => setTCAgree(!tcAgree)}></div>
                        <div id="ms-TnC-label">I confirm that I have read and agree to the <a onClick={(e) => {
                            e.preventDefault();
                            setShowTCs(true);
                        }}> Terms & Conditions</a> of
                            the sale. {showTCs.toString()} aa
                        </div>
                    </div>
                    <div id="mnt-sale-info" className="msc-entry btm-separator">
                        <div id="msi-label">Public address of the contract:</div>
                        <div id="msi-contract-link-box">
                            <a href={"https://opensea.io/" + contractAddress} target="blank">
                                {contractAddress}
                            </a>
                        </div>
                    </div>
                    <div id="mnt-sale-buy-btn-box" className="msc-entry">
                        <div id="ms-buy-button" onClick={() => mint()} className={`sq-btn ${!tcAgree ? "inactive" : ""}`}>
                            <span className="mnt-ms-buy">LET'S GO</span>
                            <span className="mnt-ms-buying">PROCESSING</span>
                        </div>
                        <div id="ms-buy-message-box">
                            <span id="mnt-msg-terms" className={`mnt-ms-buy ${tcAgree ? "inactive" : ""}`}>You need to agree to the <br /> Terms & Conditions</span>
                            <span id="mnt-msg-processing" className="mnt-ms-buying">Please check your wallet</span>
                        </div>
                    </div>
                </div>
            </div>
            <TermsAndConditions visible={showTCs} onClose={() => setShowTCs(false)} />
        </MintFrame>
    );
}

export default MintNFTScreen;
