import React, { useEffect, useRef } from "react";

interface UnlockSliderProps {
    onUnlockCallback?: () => void;
    enabled?: boolean;
}

function UnlockSlider (props: UnlockSliderProps) {
    const { onUnlockCallback, enabled } = props;

    useEffect(() => {
        setDefaults();
        start();
        return () => {
            stop();
        };
    }, []);

    useEffect(() => {
        if (enabled) {
            start();
        } else {
            stop();
        }
    }, [enabled, start, stop]);

    const _enabled = useRef(false);
    const knobT = useRef(0);
    const lt = useRef(0);
    const handlers = useRef({});
    const dragData = useRef({
        draggingKnob: false,
        draggingKnobEventData: undefined
    });
    const pathData = useRef({
        pathSteps: [] as any,
        pathLength: 0
    });

    function onStartMouseDragEvent (evt: any) {
        evt.preventDefault();
        dragData.current = ({
            ...dragData.current,
            draggingKnob: true,
            draggingKnobEventData: evt
        });
    }

    function onDoMouseDragEvent (evt: any) {
        evt.preventDefault();
        if (!dragData.current.draggingKnob) { return; }
        dragData.current = ({
            ...dragData.current,
            draggingKnobEventData: evt
        });
    }

    function onDoTouchDragEvent (evt: any) {
        evt.preventDefault();
        evt = evt.touches[0];
        dragData.current = ({
            ...dragData.current,
            draggingKnob: true,
            draggingKnobEventData: evt
        });
    }

    function onCancelDragEvent () {
        dragData.current = ({
            ...dragData.current,
            ...dragData,
            draggingKnob: false
        });
    }

    const pathResolution: number = 200;

    const unlockActiveArrows = useRef() as any;
    const unlockSvg = useRef() as any;
    const unlockPath = useRef() as any;
    const unlockKnob = useRef() as any;
    const unlockKnobEle = useRef() as any;

    function start () {
        _enabled.current = true;
        moveKnobToStep(0);
        addUnlockEventListeners();
        requestAnimationFrame((t) => { updateUnlock(t); });
    }

    function stop () {
        _enabled.current = false;
        removeUnlockEventListeners();
    }
    function getMousePosition (evt: any) {
        const originalLayerWidth = 510;

        const rect = unlockSvg.current.getBoundingClientRect();
        const scale = originalLayerWidth / rect.width;

        return {
            x: scale * (evt.clientX - rect.x),
            y: scale * (evt.clientY - rect.y)
        };
    }

    function updateUnlock (t: number) {
        const dt = t - lt.current;
        lt.current = t;

        if (dragData.current.draggingKnob) {
            try {
                const mousePos = getMousePosition(dragData.current.draggingKnobEventData);
                const computeDistance = (p0: any, p1: any) => { return Math.hypot(p1.x - p0.x, p1.y - p0.y); };

                const searchRadius = 0.025;
                const cknobStep = Math.round(knobT.current);

                let closestStep = cknobStep;
                const { pathSteps } = pathData.current;
                let closestDis = computeDistance(pathSteps[cknobStep], mousePos);

                for (let i = Math.max(0, cknobStep - pathResolution * searchRadius);
                    i < Math.min(pathResolution, cknobStep + pathResolution * searchRadius);
                    i++) {
                    const d = computeDistance(pathSteps[i], mousePos);
                    if (d < closestDis) {
                        closestDis = d;
                        closestStep = i;
                    }
                }

                knobT.current = closestStep;

                moveKnobToStep(knobT.current);

                if (knobT.current > pathResolution * 0.98) {
                    stop();
                    if (onUnlockCallback) {
                        onUnlockCallback();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        } else if (knobT.current >= 0) {
            const cknobStep = Math.round(knobT.current);

            moveKnobToStep(cknobStep);

            knobT.current = Math.max(0, knobT.current - dt * 0.2);
        }
        if (_enabled.current) { requestAnimationFrame((t) => { updateUnlock(t); }); }
    }

    function addUnlockEventListeners () {
        const _unlockKnob: any = unlockKnob.current;
        const _handlers: any = {};
        _handlers._mousedownHandler = (evt: any) => { onStartMouseDragEvent(evt); };
        _handlers._touchstartHandler = (evt: any) => { onDoTouchDragEvent(evt); };
        _handlers._mousemoveHandler = (evt: any) => { onDoMouseDragEvent(evt); };
        _handlers._touchmoveHandler = (evt: any) => { onDoTouchDragEvent(evt); };
        _handlers._mouseupHandler = () => { onCancelDragEvent(); };
        _handlers._touchendHandler = () => { onCancelDragEvent(); };
        _handlers._touchcancelHandler = () => { onCancelDragEvent(); };

        _unlockKnob.addEventListener("mousedown", _handlers._mousedownHandler);
        _unlockKnob.addEventListener("touchstart", _handlers._touchstartHandler);
        document.body.addEventListener("mousemove", _handlers._mousemoveHandler);
        document.body.addEventListener("touchmove", _handlers._touchmoveHandler);
        document.body.addEventListener("mouseup", _handlers._mouseupHandler);
        document.body.addEventListener("touchend", _handlers._touchendHandler);
        document.body.addEventListener("touchcancel", _handlers._touchcancelHandler);
        handlers.current = (_handlers);
    }

    function removeUnlockEventListeners () {
        const _handlers: any = handlers.current || {};
        unlockKnob.current?.removeEventListener("mousedown", _handlers._mousedownHandler);
        unlockKnob.current?.removeEventListener("touchstart", _handlers._touchstartHandler);
        document.body.removeEventListener("mousemove", _handlers._mousemoveHandler);
        document.body.removeEventListener("touchmove", _handlers._touchmoveHandler);
        document.body.removeEventListener("mouseup", _handlers._mouseupHandler);
        document.body.removeEventListener("touchend", _handlers._touchendHandler);
        document.body.removeEventListener("touchcancel", _handlers._touchcancelHandler);
    }

    function setDefaults () {
        const pathLength = unlockPath.current.getTotalLength();
        const pathSteps = Array.from({ length: pathResolution + 1 }, (_, i) => {
            const p = unlockPath.current.getPointAtLength(pathLength * i / pathResolution);
            return { x: p.x, y: p.y };
        });
        pathData.current = ({
            pathSteps,
            pathLength
        });
        knobT.current = 0;
    }

    const moveKnobToStep = (stepIdx: number) => {
        try {
            const { pathSteps, pathLength } = pathData.current;
            if (!pathSteps) return;
            const unlockActiveArrowsEle: any = unlockActiveArrows.current;

            const x0 = pathSteps[stepIdx].x;
            const y0 = pathSteps[stepIdx].y;

            unlockKnob.current.setAttribute("cx", x0);
            unlockKnob.current.setAttribute("cy", y0);
            unlockActiveArrowsEle.style.width = 20 + (pathLength * knobT.current / pathResolution) + "px";
            const angle = 0;
            unlockKnobEle.current.style.transform = `translate( ${x0}px, ${y0}px ) rotate(${angle}rad)`;
        } catch (e) {

        }
    };

    return (
        <div id="unlock-bar" className="a100 ut-loaded">
            <div id="umv-inactive-arrows" className="a100"></div>
            <div id="umv-active-arrows" className="a100" ref={unlockActiveArrows}></div>
            <svg className="umv-svg" ref={unlockSvg}>
                <path ref={unlockPath} id="umv-path" stroke="none" opacity="0" strokeWidth="1" fill="none"
                    d="M 20 20 L 500 20"/>
            </svg>
            <svg id="umv-knob-container" className="umv-svg">
                <circle ref={unlockKnob} id="umv-knob" cx="12" cy="12" r="36" stroke="#444" strokeWidth="0"
                    fill="rgba(250,220,15, 0)" opacity="0"></circle>
            </svg>
            <div id="umv-knob-el" ref={unlockKnobEle}>
                <div id="umv-knob-arrow"></div>
            </div>
        </div>

    );
}

export default UnlockSlider;
