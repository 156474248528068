/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { coverMetadataService, COVER_METADATA } from "./coverMetadataService";
import {getEy3k0nComicIds} from "../metamask/api/ey3k0nContract";

enum ActionStatus {
    IDLE = "IDLE",
    PENDING = "PENDING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export interface CoverMetadataState {
    fetchingMetadataStatus: ActionStatus,
    userTokenIds: number[],
    metadata: Record<number, COVER_METADATA>
}

const initialState: CoverMetadataState = {
    fetchingMetadataStatus: ActionStatus.IDLE,
    userTokenIds: [],
    metadata: {}
};

export const fetchCoverMetadata = createAsyncThunk(
    "coverMetadata/fetchCoverMetadata",
    async (tokenId: number, thunkApi): Promise<{ tokenId: number, metadata: COVER_METADATA | null}> => {
        const state: any = thunkApi.getState();
        return {
            tokenId: tokenId,
            metadata: await coverMetadataService.getCoverMetadata(tokenId)
        };
    }
);

export const fetchUserCoverMetadata = createAsyncThunk(
    "coverMetadata/fetchUserCoverMetadata",
    async (walletAdress: string, thunkApi): Promise<Record<number, COVER_METADATA>> => {
        const state: any = thunkApi.getState();
        const tokenIds: number[] = await getEy3k0nComicIds(walletAdress, "0x59f1b32528a9c74fd2d5dca4ba50e38defaf8e95");
        // set userTokenIds
        thunkApi.dispatch(setUserTokenIds(tokenIds));

        const metadata = await Promise.all(tokenIds.map(async (tokenId) => {
            return await coverMetadataService.getCoverMetadata(tokenId);
        }));

        const metadataRecord: Record<number, COVER_METADATA> = {};
        for (let i = 0; i < tokenIds.length; i++) {
            const tokenData = metadata[i];
            if (tokenData) {
                metadataRecord[tokenIds[i]] = tokenData;
            };
        }
        return metadataRecord;
    }
);

export const coverMetadataSlice = createSlice({
    name: "coverMetadata",
    initialState,
    reducers: {
        setUserTokenIds: (state: Draft<CoverMetadataState>, action: PayloadAction<number[]>) => {
            state.userTokenIds = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCoverMetadata.pending, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.PENDING;
        }).addCase(fetchCoverMetadata.rejected, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.ERROR;
        }).addCase(fetchCoverMetadata.fulfilled, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.SUCCESS;
            const tokenId: number = action.payload.tokenId;
            const metadata: COVER_METADATA | null = action.payload.metadata;
            if (metadata) {
                const newMetadata = { ...state.metadata };
                newMetadata[tokenId] = metadata;
                state.metadata = newMetadata;
            }
        });

        builder.addCase(fetchUserCoverMetadata.pending, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.PENDING;
        }).addCase(fetchUserCoverMetadata.rejected, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.ERROR;
        }).addCase(fetchUserCoverMetadata.fulfilled, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.SUCCESS;
            const newMetadata: Record<number, COVER_METADATA> = { ...state.metadata };
            const userMetadata = action.payload;
            for (const id in userMetadata) {
                newMetadata[id] = userMetadata[id];
            };
            state.metadata = newMetadata;
        });
    }
});
export const {
    setUserTokenIds
} = coverMetadataSlice.actions;

export const selectFetchingMetadataStatus = (state: RootState) => state.coverMetadata.fetchingMetadataStatus;
export const selectAllCoverMetadata = (state: RootState) => state.coverMetadata.metadata;
export const selectUserCoverTokenIds = (state: RootState) => state.coverMetadata.userTokenIds;
export const selectUserCoverTokenMetadata = (state: RootState) => {
    const userTokenMetadata: COVER_METADATA[] = [];
    state.coverMetadata.userTokenIds.forEach((tokenId: number) => {
        if (state.coverMetadata.metadata[tokenId]) {
            userTokenMetadata.push(state.coverMetadata.metadata[tokenId]);
        }
    });
    return userTokenMetadata;
};

export default coverMetadataSlice.reducer;
