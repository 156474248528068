import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { hideBackgroundCharacters } from "../features/background/backgroundSlice";
import { hideCloseButton, setActiveButton } from "../features/device-ui/deviceUISlice";
import { DeviceUIButton } from "../features/device-ui/DeviceUIButton";

export default function useFancyNav () {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const transitionTimeout = 500;

    function navigateTo (path: string) {
        if (path === location.pathname) {
            return;
        }
        let fromEle: any;
        switch (location.pathname) {
        case "/code":
            fromEle = document.getElementById("sec-code");
            break;
        case "/ey3k0ns":
            fromEle = document.getElementById("sec-vice");
            break;
        case "/roadmap":
            fromEle = document.getElementById("sec-map");
            break;
        case "/radio":
            fromEle = document.getElementById("sec-radio");
            break;
        case "/mint":
            fromEle = document.getElementById("sec-mint-coming-soon");
            break;
        }

        preCleanUp();

        document.body.className = "transitioning";
        fromEle?.classList.remove("active");
        fromEle?.classList.add("fadeOut");
        playVideoTransition(() => {
            document.body.className = "";
            navigate(path);
        });
    }

    function playVideoTransition (cb: any) {
        dispatch(hideBackgroundCharacters());
        const vidT: any = document.querySelector("#bgvid-trans video");
        const vidTContainer: any = document.querySelector("#bgvid-trans");
        vidTContainer.classList.add("active");
        // vidT.addEventListener("ended", cb);
        vidT.playbackRate = 2;
        vidT.pause();
        vidT.currentTime = 0;
        vidT.play();
        setTimeout(cb, transitionTimeout);
    }

    function preCleanUp () {
        // set the active button to none when a navigation event happens
        dispatch(setActiveButton(DeviceUIButton.NONE));

        // the close button is universal, so hide on nav change
        dispatch(hideCloseButton());
    }

    return {
        navigateTo
    };
}
