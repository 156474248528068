import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getTransformData, TransformState } from "./getTransformData";

const initialState: TransformState = getTransformData();

export const transformsSlice = createSlice({
    name: "transforms",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTransformState: (state, action: PayloadAction<TransformState>) => {
            state.s2c = action.payload?.s2c;
            state.s2h = action.payload?.s2h;
            state.s2HorW = action.payload?.s2HorW;
            state.s2ht5050 = action.payload?.s2ht5050;
        }
    }
});

export const { setTransformState } = transformsSlice.actions;

export const selectTransforms = (state: RootState) => state.transforms;

export default transformsSlice.reducer;
