import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { connectToMetamask, disconnectMetamask, selectWalletAddress, selectWalletConnected, selectWalletInstalled, selectWeb3Token, sliceGetWeb3Token, tryInstallMetamask } from "./metamaskSlice";

interface MetamaskConnectBtnProps {
    requireSign: boolean
}

const MetamaskConnectBtn = (props: MetamaskConnectBtnProps) => {
    const dispatch = useAppDispatch();
    const walletConnected = useAppSelector(selectWalletConnected);
    const walletInstalled = useAppSelector(selectWalletInstalled);
    const walletAddress = useAppSelector(selectWalletAddress);
    const web3Token = useAppSelector(selectWeb3Token);

    // Metamask util
    function tryConnectToMetamask () {
        dispatch(connectToMetamask("dummy"));
    }
    function installMetamask () {
        dispatch(tryInstallMetamask());
    }
    function disconnectWallet () {
        dispatch(disconnectMetamask());
    }
    function tryGetWeb3Token () {
        dispatch(sliceGetWeb3Token("Ey3k0n wants you to sign in with your Ethereum account.\nThis signature is required in order to securely look you up into the comics database."));
    }

    const afterWalletConnectedButton = () => {
        if (props.requireSign) {
            if (!web3Token) {
                return (
                    <div className="metamask-button metamask-sq-btn" onClick={tryGetWeb3Token}>
                        <span className="metamask-connect hidden">SIGN</span>
                    </div>
                );
            }
        }
        return (
            <div className="metamask-button metamask-sq-btn" onClick={disconnectWallet}>
                <div className="metamask-id-label">{`${walletAddress.substr(0, 6)}...${walletAddress.substr(-4)}`}</div>
            </div>
        );
    };

    return (
        <div className="metamask-button-box msc-entry">
            { !walletInstalled &&
                <div className="metamask-button metamask-sq-btn" onClick={installMetamask}>
                    <span className="metamask-connect hidden">INSTALL</span>
                </div>
            }
            { !walletConnected && walletInstalled &&
                <div className="metamask-button metamask-sq-btn" onClick={tryConnectToMetamask}>
                    <span className="metamask-connect hidden">CONNECT</span>
                </div>
            }
            { walletConnected &&
                afterWalletConnectedButton()
            }
        </div>
    );
};
export default MetamaskConnectBtn;
