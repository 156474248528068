import { shopifyClient } from "./init";
import { gql } from "@apollo/client";
import { productsRes, ProductDetails, VariantDetails, ImageDetails, OptionDetails } from "./interfaces/productInterface";

const getProducts = async (numProducts: number): Promise<ProductDetails[]> => {
    const data = await shopifyClient.query({
        query: gql`
            query {
                products(first: ${numProducts}) {
                    edges {
                        node {
                            id
                            handle
                            title
                            descriptionHtml
                            productType
                            totalInventory
                            variants(first: ${numProducts}) {
                                edges {
                                    node {
                                        id
                                        title
                                        quantityAvailable
                                        price {
                                            amount
                                            currencyCode
                                        }
                                    }
                                }
                            }
                            images(first: 20) {
                                edges {
                                    node {
                                        id
                                        url
                                        altText
                                    }
                                }
                            }
                            options(first: 10) {
                                id
                                name
                                values
                            }
                            priceRange {
                                maxVariantPrice {
                                    amount
                                    currencyCode
                                },
                                minVariantPrice {
                                    amount
                                    currencyCode
                                }
                            }
                        }
                    }
                }
            }
        `
    });
    // console.log(data.data.products);
    const parsedProducts = parseProducts(data.data.products);
    const filteredProducts = filterEnvProducts(parsedProducts);

    return filteredProducts;
};

const parseProducts = (data: productsRes): ProductDetails[] => {
    const prodDetails: ProductDetails[] = [];
    data.edges.forEach((node: any) => {
        const prod: Record<string, any> = { ...node.node };

        // Extract and reassign variants
        if (prod.variants) {
            const variants: VariantDetails[] = [];
            prod.variants.edges.forEach((variantNode: {node: VariantDetails}) => {
                variants.push(variantNode.node);
            });
            prod.variants = variants;
        } else {
            prod.variants = null;
        }

        if (prod.images) {
            const images: ImageDetails[] = [];
            prod.images.edges.forEach((imageNode: {node: ImageDetails}) => {
                images.push(imageNode.node);
            });
            prod.images = images;
        }

        prodDetails.push(prod as ProductDetails);
    });

    return prodDetails;
};

const filterEnvProducts = (products: ProductDetails[]) => {
    const env = process.env.REACT_APP_MYENV; // dev / prod / staging
    if (env === "prod") {
        // remove all DRAFT products
        return products.filter(prod => !prod.title.startsWith("[DRAFT]"));
    } else {
        return products;
    };
};

// Order of receiving options matter!
const getTitleFromOptions = (selectedOptions: string[]): string => {
    return selectedOptions.join(" / ");
};

export const productService = {
    getProducts,
    getTitleFromOptions
};
