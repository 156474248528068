/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import MetaMaskOnboarding from "@metamask/onboarding";
import { installMetamask, switchNetwork, tryConnectToMetamask } from "./MetamaskService";
import { getWeb3Token, isWhitelisted, mintNFT, setSignerAPIBaseURI, whitelistMintNFT } from "./api";
import { listenForTokensLeft, tokenBalanceOf } from "./api/ey3k0nContract";

export enum WhitelistStatus {
    WHITELISTED = "WHITELISTED",
    NOT_WHITELISTED = "NOT_WHITELISTED",
    UNKNOWN = "UNKNOWN"
}

export interface MetamaskState {
    signerAPIBaseURI: string;
    accounts: string[];
    walletConnected: boolean;
    walletAddress?: string;
    walletConnectionError: boolean;
    contractAddress: string;
    tokenBalance: number;
    isWhitelisted: WhitelistStatus;
    tokensLeft: number;
    web3Token?: string;
    gasLimit?: number;
    networkId: string;
}

const initialState: MetamaskState = {
    signerAPIBaseURI: "https://api-dev.ey3k0n.io",
    accounts: [],
    walletConnected: false,
    walletConnectionError: false,
    contractAddress: "0x00",
    tokenBalance: 0,
    tokensLeft: 0,
    isWhitelisted: WhitelistStatus.UNKNOWN,
    networkId: "0x1"
};

export const listenToBalance = createAsyncThunk(
    "metamask/listenToBalance",
    async (_: any = "", thunkApi) => {
        const state: any = thunkApi.getState();
        const contractAddress = selectContractAddress(state);
        await listenForTokensLeft(contractAddress, (balance: number) => {
            thunkApi.dispatch(setTokensLeft(balance));
        });
    });

export const checkNetwork = createAsyncThunk(
    "metamask/connectNetwork",
    async (_: any = "", thunkApi) => {
        const state: any = thunkApi.getState();
        const networkId = selectNetworkId(state);
        await switchNetwork(networkId);
    });

export const connectToMetamask = createAsyncThunk(
    "metamask/metamaskConnect",
    async (_: any = "", thunkApi) => {
        tryConnectToMetamask((accounts: any) => {
            thunkApi.dispatch(setAccounts(accounts));
        });
    });

export const getTokenBalance = createAsyncThunk(
    "metamask/getTokenBalance",
    async (walletAddress: string, thunkApi: any) => {
        const state = thunkApi.getState();
        const address = selectWalletAddress(state);
        const contractAddress = selectContractAddress(state);
        return await tokenBalanceOf(address, contractAddress);
    });

export const sliceGetWeb3Token = createAsyncThunk(
    "metamask/getWeb3Token",
    async (inputText: string) => {
        return await getWeb3Token(inputText);
    });

export const checkIfOnWhitelist = createAsyncThunk(
    "metamask/checkIfOnWhitelist",
    async (walletAddress: string, thunkApi) => {
        const state: any = thunkApi.getState();
        let web3Token = state.metamask.web3Token;
        if (!web3Token) {
            web3Token = await getWeb3Token("Ey3k0n wants you to sign in with your Ethereum account.\nThis signature is required in order to securely look you up into TH3 L1ST database.");
            thunkApi.dispatch(setWeb3Token(web3Token));
        }

        return await isWhitelisted(web3Token);
    });

export const metamaskSlice = createSlice({
    name: "metamask",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAccounts: (state: Draft<MetamaskState>, action: PayloadAction<string[]>) => {
            state.accounts = action.payload;
            state.walletConnectionError = false;
            state.isWhitelisted = WhitelistStatus.UNKNOWN;
            state.web3Token = undefined;
        },
        disconnectMetamask: (state: Draft<MetamaskState>) => {
            state.accounts = [];
            state.walletConnectionError = false;
        },
        tryInstallMetamask: (state: Draft<MetamaskState>) => {
            installMetamask();
        },
        setWalletConnectionError: (state: Draft<MetamaskState>, action: PayloadAction<boolean>) => {
            state.walletConnectionError = action.payload;
        },
        setWeb3Token: (state: Draft<MetamaskState>, action: PayloadAction<string>) => {
            state.web3Token = action.payload;
        },
        setContract: (state: Draft<MetamaskState>, action: PayloadAction<any>) => { // Part of metamaskSlice.setSaleState
            state.contractAddress = action.payload.contractAddress;
            state.networkId = action.payload.networkId || state.networkId;
        },
        setGasLimit: (state: Draft<MetamaskState>, action: PayloadAction<any>) => { // Part of metamaskSlice.setSaleState
            state.gasLimit = action.payload.gasLimit;
        },
        setSigner: (state: Draft<MetamaskState>, action: PayloadAction<any>) => { // Part of metamaskSlice.setSaleState
            state.signerAPIBaseURI = action.payload.signerAPIBaseURI;
            setSignerAPIBaseURI(state.signerAPIBaseURI);
        },
        setTokensLeft: (state: Draft<MetamaskState>, action: PayloadAction<number>) => {
            state.tokensLeft = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTokenBalance.fulfilled, (state, action) => {
                state.tokenBalance = action.payload;
            }).addCase(getTokenBalance.rejected, (state, action: any) => {
                console.log(action);
            });

        builder
            .addCase(sliceGetWeb3Token.fulfilled, (state, action) => {
                state.web3Token = action.payload;
            });
        builder
            .addCase(sliceGetWeb3Token.rejected, (state, action) => {
                state.web3Token = undefined;
            });

        builder
            .addCase(checkIfOnWhitelist.fulfilled, (state, action) => {
                state.isWhitelisted = action.payload ? WhitelistStatus.WHITELISTED : WhitelistStatus.NOT_WHITELISTED;
            })
            .addCase(checkIfOnWhitelist.pending, (state, action) => {
                state.isWhitelisted = WhitelistStatus.UNKNOWN;
            })
            .addCase(checkIfOnWhitelist.rejected, (state, action) => {
                console.log("The Status was", action);
                state.isWhitelisted = WhitelistStatus.UNKNOWN;
            });
    }
});
export const {
    setAccounts,
    disconnectMetamask,
    tryInstallMetamask,
    setTokensLeft,
    setContract,
    setGasLimit,
    setSigner,
    setWeb3Token
} = metamaskSlice.actions;

export const selectNetworkId = (state: RootState) => state.metamask.networkId;
export const selectGasLimit = (state: RootState) => state.metamask.gasLimit;
export const selectIsWhitelisted = (state: RootState) => state.metamask.isWhitelisted;
export const selectTokensLeft = (state: RootState) => state.metamask.tokensLeft;
export const selectTokenBalance = (state: RootState) => state.metamask.tokenBalance;
export const selectWalletConnected = (state: RootState) => state.metamask.accounts && state.metamask.accounts.length > 0;
export const selectWalletInstalled = (state: RootState) => MetaMaskOnboarding.isMetaMaskInstalled();
export const selectWalletConnectionError = (state: RootState) => state.metamask.walletConnectionError;
export const selectContractAddress = (state: RootState) => state.metamask.contractAddress;
export const selectWalletAddress = (state: RootState) => {
    if (state.metamask.accounts && state.metamask.accounts.length > 0) {
        return state.metamask.accounts[0];
    }
    return "";
};
export const selectWeb3Token = (state: RootState) => state.metamask.web3Token;

export default metamaskSlice.reducer;
