import React, { useRef } from "react";
import useFadeIn from "../../hooks/useFadeIn";
import ContentViewport from "../../components/ContentViewport";

function ComingSoonSection (props: any) {
    const sectionRef: any = useRef();
    useFadeIn(sectionRef);

    return (
        <ContentViewport>
            <div ref={sectionRef} id="sec-mint" className="section">
                <div id="mint-frame-coming-soon" className="a100"></div>
                <div id="mint-coming-soon-message" className="act">
                    <h2>COMING <strong>#</strong>SOON</h2>
                </div>
            </div>
        </ContentViewport>
    );
}

export default ComingSoonSection;
