const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const goneInComic = [
    {
        img: "/img/comic/gone_in/Gone_In_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 23,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 43,
                offsetTopPerc: 20,
                offsetLeftPerc: 30
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 0,
                offsetLeftPerc: 30
            }),
            calcVal({
                heightPerc: 24,
                offsetTopPerc: 36,
                offsetLeftPerc: 49
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 44,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 36,
                offsetTopPerc: 30,
                offsetLeftPerc: 35
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 23,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 12,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 48,
                offsetTopPerc: 52,
                offsetLeftPerc: 27
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 32,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 28,
                offsetTopPerc: 70,
                offsetLeftPerc: 46
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 46,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 46,
                offsetTopPerc: 22,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 35,
                offsetTopPerc: 65,
                offsetLeftPerc: 39
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 4,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 35,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 20,
                offsetTopPerc: 80,
                offsetLeftPerc: 55
            })
        ]
    },
    {
        img: "/img/comic/gone_in/Gone_In_08.webp",
        children: [
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 25,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            }),
            DEFAULT_VIEW
        ]
    }
];
