
export default function useBodyClasses () {
    function setClasses (classes: string[]) {
        document.body.className = "";
        document.body.classList.add(...classes);
    }
    function classListAdd (className: string) {
        document.body.classList.add(className);
    }
    function classListRemove (className: string) {
        document.body.classList.remove(className);
    }
    function classListToggle (className: string, force: boolean) {
        document.body.classList.toggle(className, force);
    }
    return {
        setClasses,
        classListAdd,
        classListRemove,
        classListToggle
    };
}
