import React, { useEffect, useRef } from "react";
import "swiper/css";
import { useAppDispatch } from "../../app/hooks";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import { ComicSlider } from "./ComicSlider";

export default function ComicReader () {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.COMIC));
        setTimeout(() => {
            body.setClasses(["sec-comic-reader"]);
        }, 100);
    }, []);

    return (
        <div ref={sectionRef} className={"section"}>
            <div className="read-comic-container">
                <div className="read-comic-inner">
                    <div className="read-comic-body">
                        <ComicSlider />
                    </div>
                </div>
            </div>
        </div>
    );
}
