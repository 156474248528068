import { BigNumber, ethers } from "ethers";
import abi from "./ey3k0n.abi";
import detectEthereumProvider from "@metamask/detect-provider";

let nftContract: any;
let comicContract: any;

export async function getProvider () {
    const windowProvider: any = await detectEthereumProvider();
    if (!windowProvider) {
        throw new Error("NO_PROVIDER_FOUND");
    }
    return new ethers.providers.Web3Provider(windowProvider);
}

export async function getContract (contractAddress: string) {
    if (nftContract && nftContract.address === contractAddress) {
        return nftContract;
    }

    const provider = await getProvider();
    nftContract = new ethers.Contract(contractAddress, abi, provider.getSigner());
    return nftContract;
}

export async function getComicContract (contractAddress: string) {
    if (comicContract && comicContract.address === contractAddress) {
        return comicContract;
    }

    const provider = await getProvider();
    comicContract = new ethers.Contract(contractAddress, abi, provider.getSigner());
    return comicContract;
}

export async function tokenBalanceOf (address: string, contractAddress: string): Promise<number> {
    if (!address) {
        throw new Error("address is required");
    }
    const contract = await getContract(contractAddress);
    const response = await contract.balanceOf(address);
    return response.toNumber();
}

export async function getEy3k0nTokenIds (address: string, contractAddress: string): Promise<Array<number>> {
    const contract = await getContract(contractAddress);

    console.info(await contract.name(), "tokens owned by", address);

    const sentLogs = await contract.queryFilter(
        contract.filters.Transfer(address, null)
    );
    const receivedLogs = await contract.queryFilter(
        contract.filters.Transfer(null, address)
    );

    const logs = sentLogs.concat(receivedLogs)
        .sort(
            (a: any, b: any) =>
                a.blockNumber - b.blockNumber ||
                a.transactionIndex - b.TransactionIndex
        );

    const owned = new Set<String>();

    for (const log of logs) {
        const { from, to, tokenId } = log.args;

        if (to.toLowerCase() === address.toLowerCase()) {
            owned.add(tokenId.toString());
        } else if (from.toLowerCase() === address.toLowerCase()) {
            owned.delete(tokenId.toString());
        }
    }

    return Array.from(owned.values()).map((x) => Number(x));
}

export async function getEy3k0nComicIds (address: string, contractAddress: string): Promise<Array<number>> {
    const contract = await getComicContract(contractAddress);

    const sentLogs = await contract.queryFilter(
        contract.filters.Transfer(address, null)
    );
    const receivedLogs = await contract.queryFilter(
        contract.filters.Transfer(null, address)
    );

    const logs = sentLogs.concat(receivedLogs)
        .sort(
            (a: any, b: any) =>
                a.blockNumber - b.blockNumber ||
                a.transactionIndex - b.TransactionIndex
        );

    const owned = new Set<String>();

    for (const log of logs) {
        const { from, to, tokenId } = log.args;

        if (to.toLowerCase() === address.toLowerCase()) {
            owned.add(tokenId.toString());
        } else if (from.toLowerCase() === address.toLowerCase()) {
            owned.delete(tokenId.toString());
        }
    }

    return Array.from(owned.values()).map((x) => Number(x));
}

export interface MintArgs {
    quantity: number;
    address: string;
    issued: any;
    signature: string;
    expiration: any;
    NFTPrice: string;
    gasLimit?: number;
    contractAddress: string;
}

export async function _mint (args: MintArgs): Promise<any> {
    if (!args.quantity) {
        throw new Error("args.quantity is required");
    }
    if (!args.address) {
        throw new Error("args.address is required");
    }
    if (!args.issued) {
        throw new Error("args.issued is required");
    }
    if (!args.signature) {
        throw new Error("args.signature is required");
    }
    if (!args.expiration) {
        throw new Error("args.expiration is required");
    }
    if (!args.NFTPrice) {
        throw new Error("args.NFTPrice is required");
    }
    if (!args.contractAddress) {
        throw new Error("args.contractAddress is required");
    }
    console.log(JSON.stringify(args, null, 4));
    const contract = await getContract(args.contractAddress);
    const price = ethers.utils.parseEther(args.NFTPrice);
    const quantity = BigNumber.from(args.quantity);
    const totalEth = price.mul(quantity);

    const receipt = await contract.mint(
        args.issued,
        args.expiration,
        BigNumber.from(args.quantity),
        args.signature
        , {
            value: totalEth,
            gasLimit: args.gasLimit
        });
    const tx = await receipt.wait();
    console.log("ALERT", tx);
    return tx;
}

export async function listenForTokensLeft (contractAddress: string, callback: (balance: number) => void) {
    if (!callback) {
        return;
    }

    const contract = await getContract(contractAddress);

    contract.on("Transfer", async (from: string, to: string, tokenId: any) => {
        await getTokensLeft(contract, callback);
    });
    await getTokensLeft(contract, callback);
}

async function getTokensLeft (contract: any, callback: (balance: number) => void) {
    const lastTokenMinted = await contract.lastTokenId();
    const maxSupply = await contract.maxSupply();
    const remain = maxSupply.sub(lastTokenMinted);
    console.log("tokensLeft", remain.toNumber());
    callback(remain.toNumber());
}
