import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ContentViewport from "../../components/ContentViewport";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import ShopItemCard from "./ShopItemCard";
import {
    fetchProducts, initCart,
    selectProducts, selectProductTypes
} from "../shopify/shopifySlice";
import { ProductDetails } from "../shopify/interfaces/productInterface";
import Dropdown from "../utils/Dropdown";
import CartDisplay from "./CartDisplay";

function ShopScreen (props: any) {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.SHOP));
        body.setClasses(["sec-shop"]);
        dispatch(fetchProducts(""));
        dispatch(initCart(""));
    }, []);

    /* FOR SELECTION */
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const openExpanded = () => setExpanded(true);
    const closeExpanded = () => setExpanded(false);
    const selectPrev = () => {
        setSelectedIdx(Math.max(0, selectedIdx - 1));
    };
    const selectNext = () => {
        setSelectedIdx(Math.min(filteredProducts.length - 1, selectedIdx + 1));
    };

    const classExpanded = expanded ? "active expanded" : "active";

    /* Product utils */
    const products = useAppSelector(selectProducts);
    const productTypes = useAppSelector(selectProductTypes);
    /* TO FILTER CATEGORIES */
    const [selectedProductType, setSelectedProductType] = useState("ALL");
    const [filteredProducts, setFilteredProducts] = useState(() => products);
    const filterProductsFunction = (items: ProductDetails[], filterString: string) => {
        if (filterString === "ALL") {
            return items;
        }
        return items.filter(product => product.productType === filterString);
    };
    useEffect(() => {
        setFilteredProducts(filterProductsFunction(products, selectedProductType));
    }, [selectedProductType, products]);

    return (
        <ContentViewport>
            <div ref={sectionRef} className={"section"}>
                <div id="sec-shop" className={`${classExpanded}`}>
                    <div id="browse-shop-container">
                        <div className="browse-shop-dropdown-container">
                            <Dropdown
                                selected={selectedProductType}
                                options={["ALL", ...productTypes]}
                                optionCallback={(productType) => setSelectedProductType(productType)}
                            />
                        </div>
                        <div id="cart-container">
                            <CartDisplay />
                        </div>
                        <div id="shop-close-btn" onClick={() => closeExpanded()}></div>
                        <div id="browse-shop-wrapper">
                            <div id="shop-items-display">
                                {
                                    filteredProducts.map((item: ProductDetails, index: number) => {
                                        return (
                                            <ShopItemCard openExpanded={openExpanded}
                                                key={item.id}
                                                item={item}
                                                idx={index}
                                                selectedIdx={selectedIdx}
                                                setSelected={() => setSelectedIdx(index)}
                                                expanded={expanded}/>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div id="shop-btn-wrapper">
                            <div id="shop-prev-item-btn" className={`sq-btn ${selectedIdx === 0 ? "btn-no-display" : ""}`} onClick={selectPrev}>
                                PREVIOUS
                            </div>
                            <div id="shop-item-idx-disp" className="sm-label">{`${selectedIdx + 1} / ${filteredProducts.length}`}</div>
                            <div id="shop-next-item-btn" className={`sq-btn ${selectedIdx === filteredProducts.length - 1 ? "btn-no-display" : ""}`} onClick={selectNext}>
                                NEXT
                            </div>
                        </div>
                        <div className="shop-scroll-prompt">SCROLL DOWN FOR MORE</div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
}

export default ShopScreen;
