import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ImageDetails, OptionDetails, ProductDetails, VariantDetails } from "../shopify/interfaces/productInterface";
import { productService } from "../shopify/productService";
import { addCartItem, selectCartLoading, selectItemQtyInCart } from "../shopify/shopifySlice";
import CartItemQtyUpdate from "./CartItemQtyUpdate";
import ShopItemOptions from "./ShopItemOptions";

interface ShopItemCardProps {
    openExpanded: () => void;
    item: ProductDetails; // To specify shop item type in shopSlice
    idx: number;
    selectedIdx: number;
    setSelected: () => void;
    expanded: boolean;
}

function ShopItemCard (props: ShopItemCardProps) {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        props.setSelected();
        props.openExpanded();
    };
    const item = props.item;

    /* Product Option Utils */
    const getFirstWithInventory = () => {
        return item.variants.find((variant) => variant.quantityAvailable > 0);
    };
    const getInitSelectedOptions = (): string[] => {
        return item.options.map(x => x.values[0]);
    };
    const foundVariants = item.variants.filter((variant: VariantDetails) => variant.title !== "Default Title");
    const [selectedVariant, setSelectedVariant] = useState(item.variants ? () => getFirstWithInventory() : null);
    const [selectedOptions, setSelectedOptions] = useState(() => getInitSelectedOptions());
    const updateSelectedOptions = (label: string, name: string, idx: number) => {
        // update selectedOption
        setSelectedOptions(prevOptions =>
            [
                ...prevOptions.slice(0, idx),
                name,
                ...prevOptions.slice(idx + 1, undefined)
            ]);

        // update image if have
        if (!["size"].includes(label.toLowerCase())) { // don't do for size
            setImageFromOption(name);
        }
    };

    const getSelectedVariantWithOptions = (optionStrings: string[]) => {
        // if variant exists, return variant else return first variant with options
        const found = item.variants.filter(x => x.title === productService.getTitleFromOptions(optionStrings));
        return found.length > 0 ? found[0] : selectedVariant;
    };
    useEffect(() => {
        // update selected variant
        const foundVariant = getSelectedVariantWithOptions(selectedOptions);
        setSelectedVariant(foundVariant);
    }, [selectedOptions]);

    const getPriceText = () => {
        // If no variants
        if (!selectedVariant || !selectedVariant.price) {
            return `${item.priceRange.maxVariantPrice.amount} ${item.priceRange.maxVariantPrice.currencyCode}`;
        };
        // If no specific unit price
        return `${selectedVariant.price.amount} ${selectedVariant.price.currencyCode}`;
    };
    const getInventory = () => {
        if (!selectedVariant) {
            return item.totalInventory;
        };
        return selectedVariant.quantityAvailable;
    };

    /* UI Utils */
    const selected = () => {
        if (props.idx === props.selectedIdx) return "shop-item-selected";
        if (props.idx === props.selectedIdx - 1) return "shop-item-selected-prev";
        if (props.idx === props.selectedIdx + 1) return "shop-item-selected-next";
        return "";
    };
    const transform = () => {
        if (!props.expanded) return "";
        // If expanded
        if (props.idx === props.selectedIdx - 1 || props.idx === props.selectedIdx + 1) {
            return `translateX(${(props.idx - props.selectedIdx) * 55}%)`;
        };
        return `translateX(${(props.idx - props.selectedIdx) * 100}%)`;
    };
    const [selectedImage, setSelectedImage] = useState(0);
    const setNextImage = () => {
        setSelectedImage(selectedImage === props.item.images.length - 1 ? selectedImage : selectedImage + 1);
    };
    const setPrevImage = () => {
        setSelectedImage(selectedImage === 0 ? selectedImage : selectedImage - 1);
    };
    const setImageFromOption = (optionName: string) => {
        for (let i = 0; i < props.item.images.length; i++) {
            if (props.item.images[i].altText?.toLowerCase()
                .includes(optionName.toLowerCase())) {
                return setSelectedImage(i);
            }
        }
        // no change if don't find
    };

    /* Cart Utils */
    const getItemId = (): string => {
        let itemId: string = item.id;
        if (selectedVariant !== null && selectedVariant !== undefined) {
            itemId = selectedVariant.id;
        }
        return itemId;
    };
    const handleAddItemToCart = () => {
        dispatch(addCartItem({ merchandiseId: getItemId() }));
    };
    const itemQtyInCart = useAppSelector(selectItemQtyInCart(selectedVariant ? selectedVariant.id : item.id));
    const cartLoading = useAppSelector(selectCartLoading);
    const isAddToCartDisabled = () => {
        if (getInventory() === 0) return true;
        if (itemQtyInCart >= getInventory()) return true;
        return false;
    };

    const ImageRotationButtons = () => {
        return (
            <div className="shop-image-rotation-buttons">
                { (selectedImage !== 0) &&
                    <div className="left-button"
                        onClick={setPrevImage}></div>
                }
                { (selectedImage !== props.item.images.length - 1) &&
                    <div className="right-button"
                        onClick={setNextImage}></div>
                }
            </div>
        );
    };

    return (
        <div className={`shop-item ${selected()}`} onClick={handleClick} key={item.id}
            style={{
                transform: transform()
            }}>
            <div className="shop-item-info-wrapper">
                <div className="shop-item-image-wrapper bt-padding">
                    <div className="shop-item-image-box">
                        { item.images.map((img: ImageDetails, index: number) => {
                            return (
                                <div key={img.id}
                                    className={`shop-item-image 
                                        ${selectedImage === index ? "shop-item-image-main" : "shop-item-image-secondary"}`}
                                    style={{background: `url('${img.url}') center/contain no-repeat`}}
                                    onClick={() => setSelectedImage(index === 1 ? 0 : 1)}
                                />
                            );
                        })}
                        <ImageRotationButtons />
                    </div>
                    <div className="shop-item-image-title">
                        {`${item.title}`}
                    </div>
                </div>
                <div className="shop-item-desc bt-padding shop-text-box">
                    <div className="desc-title">{item.title}</div>
                    <div className="desc-img-disp-wrapper">
                        { item.images.map((img: ImageDetails, index: number) => {
                            return (
                                <div key={img.id}
                                    className={`desc-img-disp
                                        ${selectedImage === index ? "desc-img-disp-main" : "desc-img-disp-secondary"}`}
                                    style={{background: `url('${img.url}') center/contain no-repeat`}}
                                    onClick={() => setSelectedImage(index === 1 ? 0 : 1)}
                                />
                            );
                        })}
                        <ImageRotationButtons />
                    </div>
                    <div className="desc-description-corner-topleft"/>
                    <div className="desc-description-corner-bottomright"/>
                    <div className="desc-description" dangerouslySetInnerHTML={{ __html: item.descriptionHtml }} />
                    {
                        foundVariants.length > 0 &&
                        <div className="desc-variant-select">
                            {/* <div className="desc-variant-label">Options:</div> */}
                            <div className="desc-variant-options">
                                {
                                    item.options.map((input: OptionDetails, index: number) => {
                                        return (
                                            <ShopItemOptions key={input.id}
                                                maxLabelLength={Math.max(...item.options.map(option => option.name.length))}
                                                label={input.name}
                                                selected={selectedOptions[index]}
                                                options={input.values}
                                                optionCallback={(option) => updateSelectedOptions(input.name, option, index)}
                                            />
                                        );
                                    })
                                }
                                {/* {
                                    foundVariants.map((variant: VariantDetails, index: number) => {
                                        return (
                                            <div key={variant.id} className={`desc-variant-btn ${variant.quantityAvailable === 0 ? "variant-disabled" : ""} ${variant.id === selectedVariant?.id ? "variant-selected" : ""}`}
                                                onClick={() => setSelectedVariant(variant)}>
                                                {variant.title}
                                            </div>
                                        );
                                    })
                                } */}
                            </div>
                        </div>
                    }
                    <div className="desc-price">{getPriceText()}</div>
                    { getInventory() <= 10 &&
                        <div className="desc-inventory">Inventory: {getInventory()}</div>
                    }
                    <div className="desc-cart-wrapper">
                        { itemQtyInCart > 0 &&
                            <div className="desc-add-cart-qty-update">
                                <CartItemQtyUpdate
                                    addDisabled={isAddToCartDisabled()}
                                    merchandiseId={getItemId()}/>
                            </div>
                        }
                        <div className={`desc-add-cart-btn ${isAddToCartDisabled() ? "btn-disabled" : ""}`}
                            onClick={handleAddItemToCart}>
                            {cartLoading ? "Loading..." : "ADD TO CART"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShopItemCard;
