import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { DeviceUIButton } from "./DeviceUIButton";
import { DeviceUIStatus } from "./DeviceUIStatus";

export interface DeviceUIState {
    deviceStatus: DeviceUIStatus;
    closeButtonVisible: boolean;
    closeButtonAction?: () => void;
    activeButton: DeviceUIButton,
    navDisabled: boolean;
}

const initialState: DeviceUIState = {
    deviceStatus: DeviceUIStatus.OFF,
    closeButtonVisible: false,
    activeButton: DeviceUIButton.NONE,
    navDisabled: false
};

export const deviceUISlice = createSlice({
    name: "deviceUI",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        turnDeviceOn: (state: Draft<DeviceUIState>) => {
            state.deviceStatus = DeviceUIStatus.ON;
        },
        turnDeviceOff: (state: Draft<DeviceUIState>) => {
            state.deviceStatus = DeviceUIStatus.OFF;
        },
        turnDeviceSwitchingOn: (state: Draft<DeviceUIState>) => {
            state.deviceStatus = DeviceUIStatus.SWITCHING_ON;
        },
        showCloseButton: (state: Draft<DeviceUIState>, action: PayloadAction<() => void>) => {
            state.closeButtonVisible = true;
            state.closeButtonAction = action.payload;
        },
        hideCloseButton: (state: Draft<DeviceUIState>) => {
            state.closeButtonVisible = false;
            state.closeButtonAction = undefined;
        },
        setActiveButton: (state: Draft<DeviceUIState>, action: PayloadAction<DeviceUIButton>) => {
            state.activeButton = action.payload;
        },
        setNavDisabled: (state: Draft<DeviceUIState>, action: PayloadAction<boolean>) => {
            state.navDisabled = action.payload;
        }
    }
});

export const selectNavDisabled = (state: RootState) => state.deviceUI.navDisabled;
export const selectDeviceStatus = (state: RootState) => state.deviceUI.deviceStatus;
export const selectCloseButtonVisible = (state: RootState) => state.deviceUI.closeButtonVisible;
export const selectCloseButtonAction = (state: RootState) => state.deviceUI.closeButtonAction;
export const selectActiveButton = (state: RootState) => state.deviceUI.activeButton;

export const { turnDeviceOn, turnDeviceOff, showCloseButton, hideCloseButton, setActiveButton, turnDeviceSwitchingOn, setNavDisabled } = deviceUISlice.actions;

export default deviceUISlice.reducer;
