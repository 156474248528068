/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { comicSize, comics, ComicName, ComicInfo } from "./comics";
import { COVER_METADATA } from "../coverMetadata/coverMetadataService";

export interface ComicState {
    size: number[];
    selectedComic: ComicInfo;
    comics: ComicInfo[];
    selectedCover: COVER_METADATA | null;
}

const initialState: ComicState = {
    size: comicSize,
    selectedComic: comics[ComicName.GONE_IN],
    comics: Object.values(comics),
    selectedCover: null
};

export const comicSlice = createSlice({
    name: "comic",
    initialState,
    // The `reducer` field lets us define reducers and generate associated actions
    reducers: {
        setSelectedComic: (state: Draft<ComicState>, action: PayloadAction<ComicName>) => {
            state.selectedComic = comics[action.payload];
        },
        setSelectedCover: (state: Draft<ComicState>, action: PayloadAction<COVER_METADATA>) => {
            state.selectedCover = action.payload;
        }
    }
});
export const {
    setSelectedComic,
    setSelectedCover
} = comicSlice.actions;

export const selectComicSize = (state: RootState) => state.comic.size;
export const selectComics = (state: RootState) => state.comic.comics;
export const selectComic = (state: RootState) => state.comic.selectedComic;
export const selectSelectedCover = (state: RootState) => state.comic.selectedCover;
export default comicSlice.reducer;
