import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useFancyNav from "../../hooks/useFancyNav";
import { selectUserCoverTokenMetadata } from "../coverMetadata/coverMetadataSlice";
import { selectWalletAddress, selectWalletConnected } from "../metamask/metamaskSlice";
import { ComicInfo, ComicName } from "./comics";
import { selectSelectedCover, setSelectedComic } from "./comicSlice";

interface ComicCardProps {
    comic: ComicInfo;
}

function ComicCoverCard (props: ComicCardProps) {
    const dispatch = useAppDispatch();

    // States
    const walletConnected = useAppSelector(selectWalletConnected);
    const selectedCover = useAppSelector(selectSelectedCover);

    const { navigateTo } = useFancyNav();
    const selectComic = (name: ComicName) => {
        dispatch(setSelectedComic(name));
        navigateTo("/c0m1cs/reader");
    };

    // Check if cover enabled
    const hasCover = () => { return selectedCover !== null; };
    const hasAtLeastStage = (stage: number) => {
        return selectedCover?.attributes.ST4G3 ? parseInt(selectedCover.attributes.ST4G3) >= stage : false;
    };
    const isDisabled = () => {
        // if (!walletConnected) {
        //     return "disabled";
        // }

        let disabled = false;
        if (props.comic.pages.length === 0) {
            disabled = true;
        }
        // switch (props.comic.stage) {
        // case 1: {
        //     if (!hasCover()) {
        //         disabled = true;
        //     }
        //     break;
        // }
        // case 2: {
        //     if (!hasAtLeastStage(1)) {
        //         disabled = true;
        //     }
        //     break;
        // }
        // default: {
        //     disabled = true;
        //     break;
        // }
        // }
        return disabled ? "disabled" : "";
    };

    return (
        <div className={`comic-card-container ${isDisabled() ? "disabled" : "enabled"}`}>
            <div className="comic-card-wrapper">
                <div className="comic-card" onClick={() => {
                    if (!isDisabled()) selectComic(props.comic.name);
                }}>
                    <div className="comic-card-image-wrapper">
                        <div className="comic-card-image"
                            style={{
                                background: `url('${props.comic.cover}') center/contain no-repeat`
                            }} />
                    </div>
                    <div className="comic-card-title-wrapper">
                        <span className="comic-card-title">
                            {`${props.comic.displayName.toUpperCase()}`}
                        </span>
                        <span className="comic-card-num-pages">
                            {props.comic.pages.length > 0 ? `${props.comic.pages.length} PAGES` : ""}
                        </span>
                    </div>
                </div>
            </div>
            { isDisabled() &&
                <div className="comic-card-lock-wrapper">
                    <div className="comic-card-lock-label">
                        LOCKED
                    </div>
                </div>
            }
        </div>
    );
}

export default ComicCoverCard;
