const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const titansComic = [
    {
        img: "/img/comic/titans/Titans_Layered_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 43,
                offsetTopPerc: 4.5,
                offsetLeftPerc: 28.3
            }),
            calcVal({
                heightPerc: 43,
                offsetTopPerc: 52.5,
                offsetLeftPerc: 28.3
            })
        ]
    },
    {
        img: "/img/comic/titans/Titans_Layered_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 29,
                offsetTopPerc: 6.1,
                offsetLeftPerc: 26.5
            }),
            calcVal({
                heightPerc: 29,
                offsetTopPerc: 6.1,
                offsetLeftPerc: 46.8
            }),
            calcVal({
                heightPerc: 20.8,
                offsetTopPerc: 36.1,
                offsetLeftPerc: 28.7
            }),
            calcVal({
                heightPerc: 20.8,
                offsetTopPerc: 36.1,
                offsetLeftPerc: 50.6
            }),
            calcVal({
                heightPerc: 42.7,
                offsetTopPerc: 50.1,
                offsetLeftPerc: 28.7
            })
        ]
    },
    {
        img: "/img/comic/titans/Titans_Layered_03_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 53,
                offsetTopPerc: 0,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 56,
                offsetTopPerc: 4,
                offsetLeftPerc: 44
            })
        ]
    },
    {
        img: "/img/comic/titans/Titans_Layered_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 48,
                offsetTopPerc: 0,
                offsetLeftPerc: 26
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/titans/Titans_Layered_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 51,
                offsetTopPerc: 0,
                offsetLeftPerc: 24
            }),
            calcVal({
                heightPerc: 61,
                offsetTopPerc: 33.6,
                offsetLeftPerc: 16.8
            })
        ]
    },
    {
        img: "/img/comic/titans/Titans_Layered_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 49,
                offsetTopPerc: 51,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/titans/Titans_Layered_08.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 24.9,
                offsetTopPerc: 5.6,
                offsetLeftPerc: 24.4
            }),
            calcVal({
                heightPerc: 24.9,
                offsetTopPerc: 5.6,
                offsetLeftPerc: 50.2
            }),
            calcVal({
                heightPerc: 32.3,
                offsetTopPerc: 31.5,
                offsetLeftPerc: 17
            }),
            calcVal({
                heightPerc: 32.3,
                offsetTopPerc: 31.5,
                offsetLeftPerc: 50.2
            }),
            calcVal({
                heightPerc: 43,
                offsetTopPerc: 51,
                offsetLeftPerc: 28.3
            })
        ]
    }
];
