import React from "react";

interface EyekonData {
    name: string;
    id: string;
    description: string;
    image: string;
    properties: any;
}

interface EyekonCardProps {
    eyekon: EyekonData
}

function Ey3konCard (props: EyekonCardProps) {
    const attributes: any = props.eyekon.properties?.attributes || {};
    const attribElements: any = [];
    for (const key in attributes) {
        const value = attributes[key];
        attribElements.push(<div key={key} className="json-t2"><span className="json-id">"{key}"</span>: <span className="json-val">"{value}"</span>,</div>);
    }
    const data = props.eyekon;

    return (
        <div className="vc-card-wrapper">
            <div className="vc-card-info">
                <div className="vci-header">
                    <div className="vcih-id"><strong>{data.name} // </strong> {data.id}</div>
                    <div className="vcih-pcode"></div>
                </div>
                <div className="vci-body">
                    <div className="json-t0"><span className="json-id">"name"</span>: <span
                        className="json-val">"{data.name} - {data.id}"</span>,
                    </div>
                    <div className="json-t0"><span className="json-id">"description"</span>: <span
                        className="json-val">"{data.description}"</span>,
                    </div>
                    <div className="json-t0"><span className="json-id">"image"</span>: <span
                        className="json-val">"{data.image}"</span>,
                    </div>
                    <div className="json-t0"><span className="json-obj">"properties"</span>: &#123;</div>
                    <div className="json-t1"><span className="json-id">"rebelId"</span>: <span className="json-val">    "{data.properties.rebelId}" </span>,</div>
                    <div className="json-t1"><span className="json-id">"organization"</span>: <span className="json-val"> "{data.properties.organization}" </span>,</div>
                    <div className="json-t1"><span className="json-id">"collection"</span>: <span className="json-val"> "{data.properties.collection}" </span>,</div>
                    <div className="json-t1"><span className="json-id">"character"</span>: <span className="json-val"> "{data.properties.character}" </span>,</div>
                    <div className="json-t1"><span className="json-id">"artist"</span>: <span className="json-val"> "{data.properties.artist}" </span>,</div>
                    <div className="json-t1"><span className="json-obj">"attributes"</span>: <span className="json-val"> &#123; </span></div>
                    { attribElements }
                    {/*
                                                {% for key, value in data.properties.attributes %}
                                                    <div className="json-t2"><span className="json-id">"{key}"</span>: <span className="json-val">"{value}"</span>,</div>
                                                {% endfor %}
                        */}
                    <div className="json-t1">        &#125; </div>
                    <div className="json-t0"> &#125; </div>
                </div>
            </div>
            <div className="vc-card">
                <div className="vc-header">
                    <div className="vcih-id"><strong>{data.name} // </strong> {data.id}</div>
                    <div className="vcih-pcode"></div>
                </div>
                <div className="vc-bg"></div>
                <div className="vc-front"></div>
                <div className="vc-back"></div>
                <div className="vc-reflex"></div>
            </div>
        </div>
    );
}

export default Ey3konCard;
