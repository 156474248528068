import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    checkNetwork,
    connectToMetamask, disconnectMetamask,
    selectWalletAddress,
    selectWalletConnected,
    selectWalletConnectionError,
    selectWalletInstalled,
    tryInstallMetamask
} from "../metamask/metamaskSlice";
import MintFrame from "./MintFrame";
import { useNavigate } from "react-router-dom";

function MetamaskScreen (props: any) {
    const walletConnected = useAppSelector(selectWalletConnected);
    const walletInstalled = useAppSelector(selectWalletInstalled);
    const walletConnectionError = useAppSelector(selectWalletConnectionError);
    const walletAddress = useAppSelector(selectWalletAddress);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (walletConnected) {
            dispatch(checkNetwork("dummy"));
        }
    }, [walletConnected]);

    function disconnectWallet () {
        dispatch(disconnectMetamask());
    }

    function tryConnectToMetamask () {
        dispatch(connectToMetamask("dummy"));
    }

    function gotoSale () {
        navigate("/mint/sale");
    }

    function installMetamask () {
        dispatch(tryInstallMetamask());
    }

    return (
        <MintFrame>
            <div id="mint-metamask-scrn" className="mint-screen a100 connect active">
                <div id="mnt-mm-container" className="mint-center-content">
                    <div id="mnt-mm-title" className="msc-entry btm-separator">
                        <span><strong>META</strong>Mask</span>
                    </div>
                    <div id="mnt-mm-logo-box" className="msc-entry">
                        <div id="mnt-mm-logo"></div>
                    </div>
                    <div id="mnt-mm-message" className="msc-entry btm-separator top-separator">
                        { !walletConnected && walletInstalled && <span className="mnt-mm-connect">Connect to <strong>META</strong>Mask</span>}
                        { !walletInstalled && <span className="mnt-mm-connect">Click to install <strong>META</strong>Mask</span>}
                        { walletConnectionError && <span className="mnt-mm-connecting">Check your wallet</span>}

                        {walletConnectionError && <span className="mnt-mm-retry"><strong
                            id="mnt-mm-error-msg">Unsuccesful Connection</strong></span>}

                        { walletConnected &&
                            <div className="mnt-mm-connected active">
                                <span>Connected as:</span>
                                <div id="mnt-mm-account-box">
                                    <div id="mnt-mm-accID">{`${walletAddress.substr(0, 6)}...${walletAddress.substr(-4)}`}</div>
                                    <div id="mnt-mm-disconnect-button" className="sq-btn" onClick={disconnectWallet}>DISCONNECT</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div id="mnt-mm-button-box" className="msc-entry">
                        { !walletInstalled &&
                            <div id="mnt-mm-button" className="sq-btn" onClick={installMetamask}>
                                <span className="mnt-mm-connect hidden">INSTALL</span>
                            </div>
                        }
                        { !walletConnected && walletInstalled &&
                            <div id="mnt-mm-button" className="sq-btn" onClick={tryConnectToMetamask}>
                                <span className="mnt-mm-connect hidden">CONNECT</span>
                            </div>
                        }
                        { walletConnected &&
                            <div id="mnt-mm-button" className="sq-btn" onClick={gotoSale}>
                                <span className="mnt-mm-connected">GO TO SALE</span>
                            </div>
                        }
                        {/*
                        <div id="mnt-mm-button" className="sq-btn">
                            <span className="mnt-mm-connecting">PROCESSING</span>
                            <span className="mnt-mm-retry">RETRY</span>
                        </div>
                    */}
                    </div>
                </div>
            </div>
        </MintFrame>
    );
}

export default MetamaskScreen;
