import React, { useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../utils/ProtectedRoute";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { selectWalletConnected } from "../metamask/metamaskSlice";
import ComicReader from "./ComicReader";
import BrowseComics from "./BrowseComics";
import { selectUserCoverTokenIds } from "../coverMetadata/coverMetadataSlice";
import Stage2IncubateResult from "./Stage2IncubateResult";
import { selectAuthorized } from "../authentication/authSlice";
import MetamaskScreen from "../metamask/MetamaskScreen";

function ComicLandingScreen (props: any) {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();
    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.COMIC));
        body.setClasses(["sec-comic"]);
    }, []);

    const walletConnected = useAppSelector(selectWalletConnected);
    const coverTokenBalance = useAppSelector(selectUserCoverTokenIds);
    const authorized = useAppSelector(selectAuthorized);

    const hasEy3k0n = (): boolean => {
        return walletConnected && (coverTokenBalance.length > 0);
    };

    // Permission conditions
    const permissionConditions = [walletConnected, coverTokenBalance, authorized];
    const hasPermission = (): boolean => {
        return hasEy3k0n() || authorized;
    };

    return (
        <div ref={sectionRef} className="section">
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute
                        hasPermission={hasPermission}
                        permissionConditions={permissionConditions}
                        noPermsUrl="/c0m1cs/connect">
                        <BrowseComics />
                    </ProtectedRoute>
                } />
                <Route path="/reader" element={
                    <ProtectedRoute
                        hasPermission={hasPermission}
                        permissionConditions={permissionConditions}
                        noPermsUrl="/c0m1cs/connect">
                        <ComicReader />
                    </ProtectedRoute>
                } />
                <Route path="/result" element={
                    <ProtectedRoute
                        hasPermission={hasEy3k0n}
                        permissionConditions={permissionConditions}
                        noPermsUrl="/c0m1cs/connect">
                        <Stage2IncubateResult />
                    </ProtectedRoute>
                } />
                <Route path="/connect"
                    element={<MetamaskScreen
                        hasPermission={hasPermission}
                        targetBtnText="READ COMIC"
                        targetUrl="/c0m1cs/"
                        autoNavigate={false}
                        requireSign={false}
                        enableEmailAndPasswordOption={true}/>} />
            </Routes>
        </div>
    );
}

export default ComicLandingScreen;
