const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({
    heightPerc,
    offsetTopPerc,
    offsetLeftPerc
}: {
  heightPerc: number;
  offsetTopPerc: number;
  offsetLeftPerc: number;
}) => {
    return {
        width: ((heightPerc * comicHeight) / 100) * aspectRatio,
        height: (heightPerc * comicHeight) / 100,
        offsetTop: (offsetTopPerc * comicHeight) / 100,
        offsetLeft: (offsetLeftPerc * comicWidth) / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const detenteComic = [
    {
        img: "/img/comic/detente/Detente_Layered_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/detente/Detente_Layered_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/detente/Detente_Layered_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 43.8,
                offsetTopPerc: 51,
                offsetLeftPerc: 28
            })
        ]
    },
    {
        img: "/img/comic/detente/Detente_Layered_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/detente/Detente_Layered_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 48,
                offsetTopPerc: 52,
                offsetLeftPerc: 27
            })
        ]
    },
    {
        img: "/img/comic/detente/Detente_Layered_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/detente/Detente_Layered_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 2,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 46,
                offsetTopPerc: 54,
                offsetLeftPerc: 29
            })
        ]
    }
];
