/* eslint-disable no-unused-vars */
export enum DeviceUIButton {
    NONE,
    CODE,
    EY3K0NS,
    MAP,
    MINT,
    COMIC,
    SHOP,
    INCUBATE
}
