import React, { useEffect, useRef, useState } from "react";
import DeviceUI from "./features/device-ui/DeviceUI";
import CodeSection from "./features/code/CodeSection";
import { Route, Routes } from "react-router-dom";
import RoadmapScreen from "./features/roadmap/RoadmapScreen";
import Ey3k0nScreen from "./features/ey3k0ns/Ey3k0nScreen";
import LandingPage from "./features/landing-page/LandingPage";
import BackgroundContainer from "./features/background/BackgroundContainer";
import MintLandingScreen from "./features/minting/MintLandingScreen";
import ComicLandingScreen from "./features/comic/ComicLandingScreen";
import RadioSection from "./features/radio/RadioSection";
import ComicForm from "./features/marketing/ComicForm";
import { useAppSelector } from "./app/hooks";
import { selectRadioStatus } from "./features/radio/radioSlice";
import { RadioStatus } from "./features/radio/RadioStatus";
import ClaimForm from "./features/marketing/ClaimForm";
import ShopScreen from "./features/shop/ShopScreen";
import IncubateLandingScreen from "./features/incubate/IncubateLandingScreen";
import Overlays from "./features/overlays/Overlays";
import { KeypadContextProvider } from "./features/utils/KeypadContext";

function App () {
    const introVideo = useRef<HTMLVideoElement>() as any;
    const introVideoContainer = useRef<HTMLElement>() as any;
    const transitionVid = useRef<HTMLVideoElement>() as any;
    const backgroundContainer = useRef<HTMLElement>() as any;
    const skullVideoRef = useRef<HTMLElement>() as any;
    const radioStatus = useAppSelector(selectRadioStatus);

    const [radioClass, setRadioClass] = useState("radio-paused");
    useEffect(() => {
        setRadioClass(radioStatus === RadioStatus.PLAYING ? "radio-playing" : "radio-paused");
    }, [radioStatus]);

    // TODO: Compress videos
    return (
        <div className={radioClass}>
            <KeypadContextProvider>
                <BackgroundContainer
                    introVideoRef={introVideo}
                    introVideoContainerRef={introVideoContainer}
                    transitionVideoRef={transitionVid}
                    backgroundContainer={backgroundContainer}
                    skullVideoRef={skullVideoRef}
                />
                <Routes>
                    <Route path="/code" element={<CodeSection />} />
                    <Route path="/roadmap" element={<RoadmapScreen />} />
                    <Route path="/radio" element={<RadioSection />} />
                    <Route path="/ey3k0ns" element={<Ey3k0nScreen />} />
                    <Route path="/mint/*" element={<MintLandingScreen />}/>
                    <Route path="/ENL1ST" element={<ComicForm />}/>
                    <Route path="/CL41M" element={<ClaimForm />}/>
                    <Route path="/c0m1cs/*" element={<ComicLandingScreen />} />
                    <Route path="/incubate/*" element={<IncubateLandingScreen />} />
                    <Route path="/sh0p" element={<ShopScreen />} />
                    <Route path="/" element={<LandingPage
                        introVideoRef={introVideo}
                        introVideoContainerRef={introVideoContainer}
                        transitionVideoRef={transitionVid}
                    />} />
                </Routes>
                <DeviceUI />
                <Overlays />
            </KeypadContextProvider>
        </div>
    );
}

export default App;
