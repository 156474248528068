import axios from "axios";

const EY3K0N_METDATA_URL = "https://nfts.ey3k0n.io/metadata/";

const NONE_STRING = "None";

export interface EY3K0N_METADATA {
    name: string,
    description: string,
    image: string,
    properties: {
        rebelId: string,
        organization: string,
        collection: string,
        character: string,
        artist: string,
        attributes: Record<string, string | null>
    },
    attributes: Record<string, string | null>
}

const _parseAttributesArray = (traits: Array<{trait_type: string, value: string}>): Record<string, string | null> => {
    const parsedTraits: Record<string, string | null> = {};
    for (let i = 0; i < traits.length; i++) {
        const trait = traits[i];
        parsedTraits[trait.trait_type] = trait.value === NONE_STRING ? null : trait.value;
    };
    return parsedTraits;
};

const _parseAttributesMap = (attributes: Record<string, string>): Record<string, string | null> => {
    const parsedAttributes: Record<string, string | null> = {};
    for (const id in attributes) {
        parsedAttributes[id] = attributes[id] === NONE_STRING ? null : attributes[id];
    }
    return parsedAttributes;
};

const _parseMetadata = (metadata: any): EY3K0N_METADATA => {
    return {
        ...metadata,
        properties: {
            ...metadata.properties,
            attributes: _parseAttributesMap(metadata.properties.attributes)
        },
        attributes: _parseAttributesArray(metadata.attributes)
    };
};

const getEy3k0nMetadata = async (tokenId: number): Promise<EY3K0N_METADATA | null> => {
    const metadataUrl = EY3K0N_METDATA_URL + tokenId.toString();
    try {
        const response = await axios.get(metadataUrl);
        const metadata = response.data;
        return _parseMetadata(metadata);
    } catch (e) {
        const error = e as any;
        console.log(error);
        return null;
    }
};

export const ey3k0nMetadataService = {
    getEy3k0nMetadata
};
