import React, { useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ContentViewport from "../../components/ContentViewport";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import { selectSelectedCover, setSelectedCover } from "./comicSlice";
import { checkNetwork, getTokenBalance, selectWalletAddress, selectWalletConnected, selectWeb3Token } from "../metamask/metamaskSlice";
import { fetchUserEy3k0nMetadata } from "../ey3k0nMetadata/ey3k0nMetadataSlice";
import { fetchUserCoverMetadata, selectUserCoverTokenMetadata } from "../coverMetadata/coverMetadataSlice";
import Dropdown from "../utils/Dropdown";
import MetamaskConnectBtn from "../metamask/MetamaskConnectBtn";
import { incubateVerifyStage2, incubateVerifyStage3, incubateVerifyStage4, incubateVerifyStage5, incubateVerifyStage6, incubateVerifyStage7, incubateVerifyStage8, incubateVerifyStage9 } from "../firebase/functions";
import { setShowKeypad } from "../utils/utilSlice";
import BrowsePageCards from "./BrowsePageCards";
import BrowsePillCards from "./BrowsePillCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import { incubatePillStage2, incubatePillStage3, incubatePillStage4, incubatePillStage5, incubatePillStage6, incubatePillStage7, incubatePillStage8, incubatePillStage9 } from "../incubate/incubateService";
import { setIncubateResult } from "../incubate/incubateSlice";
import { KeypadCallbackResponse, useKeypadContext } from "../utils/KeypadContext";

function BrowseComics (props: any) {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();
    const keypadContext = useKeypadContext();

    // States
    const walletConnected = useAppSelector(selectWalletConnected);
    const walletAddress = useAppSelector(selectWalletAddress);
    const userCoverTokenMetadata = useAppSelector(selectUserCoverTokenMetadata);
    const web3Token = useAppSelector(selectWeb3Token);
    const selectedCover = useAppSelector(selectSelectedCover);
    const selectCover = (tokenId: string) => {
        const coverMetadata = userCoverTokenMetadata.filter(x => x.tokenId === parseInt(tokenId))[0];
        dispatch(setSelectedCover(coverMetadata));
    };
    const [showPills, setShowPills] = useState(false);
    const toggleShowPills = () => { setShowPills(!showPills); };

    useEffect(() => {
        if (walletConnected) {
            dispatch(checkNetwork("dummy"));
            dispatch(getTokenBalance(walletAddress));
            dispatch(fetchUserEy3k0nMetadata(walletAddress));
            dispatch(fetchUserCoverMetadata(walletAddress));
        }
    }, [walletConnected, walletAddress]);

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.COMIC));
        body.setClasses(["sec-comic"]);
    }, []);

    /* Show keypad */
    const toastParams = {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        className: "incubate-toast",
        bodyClassName: "incubate-toast-body"
    };
    const toastSuccess = (message: string) => toast.success(message, toastParams as any);
    const toastError = (message: string) => toast.error(message, toastParams as any);
    const genIncubateCallback = (
        answerVerifyFn: (ans: string) => Promise<any>,
        incubateFn: (web3Token: string, comicId: number) => Promise<any>
    ) => async (answer: string): Promise<KeypadCallbackResponse> => {
        // check code
        const checkCodeRes = await answerVerifyFn(answer);
        if (!checkCodeRes.data) {
            return {
                success: false,
                msg: "1NV4L1D C0D3",
                navigateUrl: ""
            };
        }
        // no cover selected
        if (!selectedCover) {
            return {
                success: false,
                msg: "NO COVER SELECTED",
                navigateUrl: ""
            };
        }
        // invalid web3 token
        if (!web3Token) {
            return {
                success: false,
                msg: "WALLET NOT SIGNED",
                navigateUrl: ""
            };
        }

        // incubate
        try {
            const incubateRes = await incubateFn(web3Token, selectedCover.tokenId);
            dispatch(setIncubateResult(incubateRes.image + `?cb=${Date.now()}`));
            return {
                success: true,
                msg: "",
                navigateUrl: "/c0m1cs/result"
            };
        } catch (err) {
            console.log(err);
            return {
                success: false,
                msg: "System temporarily busy, please try again later",
                navigateUrl: ""
            };
        }
    };
    const incubateStage2Callback = genIncubateCallback(incubateVerifyStage2, incubatePillStage2);
    const incubateStage3Callback = genIncubateCallback(incubateVerifyStage3, incubatePillStage3);
    const incubateStage4Callback = genIncubateCallback(incubateVerifyStage4, incubatePillStage4);
    const incubateStage5Callback = genIncubateCallback(incubateVerifyStage5, incubatePillStage5);
    const incubateStage6Callback = genIncubateCallback(incubateVerifyStage6, incubatePillStage6);
    const incubateStage7Callback = genIncubateCallback(incubateVerifyStage7, incubatePillStage7);
    const incubateStage8Callback = genIncubateCallback(incubateVerifyStage8, incubatePillStage8);
    const incubateStage9Callback = genIncubateCallback(incubateVerifyStage9, incubatePillStage9);

    const keypadDisabled = () => {
        let disabled = false;
        if (selectedCover != null && selectedCover.attributes && selectedCover.attributes.ST4G3 !== null) {
            switch (parseInt(selectedCover.attributes.ST4G3)) {
            case 0:
                toastError("Cover has not been incubated");
                disabled = true;
                break;
            case 9:
                toastError("Cover already ST4G3 9");
                disabled = true;
                break;
            default:
                disabled = false;
            }
        }
        return disabled;
    };
    const handleShowKeypad = () => {
        if (keypadDisabled()) {
            return;
        }
        if (keypadContext !== undefined &&
            selectedCover != null && selectedCover.attributes && selectedCover.attributes.ST4G3 !== null) {
            switch (parseInt(selectedCover.attributes.ST4G3)) {
            case 1:
                keypadContext.setKeypadCallback(incubateStage2Callback);
                break;
            case 2:
                keypadContext.setKeypadCallback(incubateStage3Callback);
                break;
            case 3:
                keypadContext.setKeypadCallback(incubateStage4Callback);
                break;
            case 4:
                keypadContext.setKeypadCallback(incubateStage5Callback);
                break;
            case 5:
                keypadContext.setKeypadCallback(incubateStage6Callback);
                break;
            case 6:
                keypadContext.setKeypadCallback(incubateStage7Callback);
                break;
            case 7:
                keypadContext.setKeypadCallback(incubateStage8Callback);
                break;
            case 8:
                keypadContext.setKeypadCallback(incubateStage9Callback);
                break;
            default:
                return;
            }
        }
        dispatch(setShowKeypad(true));
    };

    return (
        <ContentViewport>
            <div ref={sectionRef} className="section">
                <div className="browse-comic-container">
                    <ToastContainer />
                    <div className="browse-page-wrapper">
                        { showPills
                            ? <BrowsePillCards />
                            : <BrowsePageCards />
                        }
                    </div>
                    { ((selectedCover !== null) && web3Token) &&
                        <div id="show-keypad-btn" className="sq-btn" onClick={handleShowKeypad}>
                            <FontAwesomeIcon icon={faKeyboard} />
                        </div>
                    }
                    { walletConnected && userCoverTokenMetadata.length > 0 &&
                        <div id="browse-page-select-cover-dropdown">
                            <Dropdown
                                selected={selectedCover ? `SELECTED: ${selectedCover.tokenId}` : "SELECT COVER"}
                                options={userCoverTokenMetadata.map(token => token.tokenId.toString())}
                                optionCallback={(val: string) => selectCover(val)}/>
                        </div>
                    }
                    <div id="browse-page-metamask-connect-wrapper">
                        <MetamaskConnectBtn requireSign={userCoverTokenMetadata.length > 0} />
                    </div>
                    { userCoverTokenMetadata.length > 0 &&
                        <div id="browse-page-toggle-show-pill-btn" onClick={toggleShowPills}>
                            {showPills ? "COMICS" : "MY PILLS"}
                        </div>
                    }
                    { !showPills &&
                        <div id="browse-page-scroll-label-wrapper">
                            <p id="browse-page-scroll-label">SCROLL DOWN FOR MORE</p>
                        </div>
                    }
                    {/* <div id="browse-page-comic-count"> ??
                        <p id="browse-page-comic-count">SCROLL DOWN FOR MORE</p>
                    </div> */}
                </div>
            </div>
        </ContentViewport>
    );
}

export default BrowseComics;
