const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const lightsComic = [
    {
        img: "/img/comic/lights/Lights_Layered_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/lights/Lights_Layered_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 52,
                offsetTopPerc: 0,
                offsetLeftPerc: 24
            }),
            calcVal({
                heightPerc: 52,
                offsetTopPerc: 41,
                offsetLeftPerc: 24
            })
        ]
    },
    {
        img: "/img/comic/lights/Lights_Layered_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 48,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/lights/Lights_Layered_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/lights/Lights_Layered_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 1,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 48,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/lights/Lights_Layered_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 1,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 49,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/lights/Lights_Layered_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 15,
                offsetLeftPerc: 25
            })
        ]
    }
];
