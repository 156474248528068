import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectTransforms } from "../transforms/transformsSlice";
import { clearSkullToggleClasses, selectSkullVideoActive, selectSkullVideoToggleClasses } from "./backgroundSlice";

function SkullVideo (props: any) {
    const transforms = useAppSelector(selectTransforms);
    const dispatch = useAppDispatch();
    const skullRef: any = useRef();

    const skullVideoActive = useAppSelector(selectSkullVideoActive);
    const skullVideoToggleClasses = useAppSelector(selectSkullVideoToggleClasses);

    function showSkull () {
        const skullVideo = skullRef.current;
        skullVideo.classList = "aHD s2ht5050";
        skullVideo.classList.toggle("hidden", false);
        skullVideo.classList.toggle("fadeOut", false);
        skullVideo.classList.toggle("blurred", false);
        for (const className of skullVideoToggleClasses) {
            skullVideo.classList.toggle(className, true);
        }
        setTimeout(() => {
            skullVideo.classList.toggle("active", true);
        }, 800);
    }

    function hideSkull () {
        const skullVideo = skullRef.current;
        skullVideo.classList.toggle("fadeOut", true);
        skullVideo.classList.toggle("active", false);
        setTimeout(() => {
            skullVideo.classList.toggle("fadeOut", false);
            skullVideo.classList.toggle("hidden", true);
            for (const className of skullVideoToggleClasses) {
                skullVideo.classList.toggle(className, false);
            }
            skullVideo.classList = "aHD s2ht5050";
            dispatch(clearSkullToggleClasses());
        }, 500);
    }

    useEffect(() => {
        showSkull();
    }, []);

    useEffect(() => {
        if (!skullVideoActive) {
            hideSkull();
        }
        showSkull();
    }, [skullVideoActive, skullVideoToggleClasses]);

    return (
        <div ref={skullRef} id="mint-video-container" className="aHD s2ht5050" style={{
            transform: transforms.s2ht5050
        }}>
            <video playsInline muted autoPlay loop preload="auto">
                <source src="/videos/Skull_Mint-med-bit.mp4" type="video/mp4" />
            </video>
            <div id="mint-video-lock"></div>
        </div>

    );
}

export default SkullVideo;
