import React, { useEffect, useRef, useState } from "react";
import UnlockScreen from "../unlock/UnlockScreen";
import { useAppDispatch } from "../../app/hooks";
import { setNavDisabled, turnDeviceOff, turnDeviceOn, turnDeviceSwitchingOn } from "../device-ui/deviceUISlice";
import useBodyClasses from "../../hooks/useBodyCasses";
import {
    hideBackgroundCharacters,
    introVideoActive,
    showBackgroundCharacters,
    transitionVideoActive
} from "../background/backgroundSlice";

interface LandingPageProps {
    introVideoRef: any;
    transitionVideoRef: any;
    introVideoContainerRef: any;
}

function LandingPage (props: LandingPageProps) {
    const { introVideoRef, transitionVideoRef, introVideoContainerRef } = props;
    const dispatch = useAppDispatch();
    const body = useBodyClasses();
    const onIntroPlayableTimeoutId = useRef() as any;
    const unlockAutomaticallyTimeoutId = useRef() as any;

    const [loading, setLoading] = useState(true);
    const [showUnlock, setShowUnlock] = useState(false);

    useEffect(() => {
        dispatch(turnDeviceOff());
        body.setClasses(["landing"]);
        dispatch(setNavDisabled(true));
        return () => {
            dispatch(hideBackgroundCharacters());
            dispatch(setNavDisabled(false));
        };
    }, []);

    function onIntroVideoPlayable () {
        clearTimeout(onIntroPlayableTimeoutId.current);
        setLoading(false);
        setShowUnlock(true);
        doUnlockSequence();
    }

    function onIntroVideoEnded () {
        setTimeout(() => {
            dispatch(turnDeviceOn());
            body.setClasses(["landing"]);
        }, 10675);

        dispatch(introVideoActive(false));
        dispatch(transitionVideoActive(true));
        // introVideoRef.current.classList.remove("active");
        // transitionVideoRef.current.classList.add("active");
        dispatch(showBackgroundCharacters());
        dispatch(setNavDisabled(false));
    }

    useEffect(() => {
        const introVideo = introVideoRef.current;
        const transitionVideo = transitionVideoRef.current;
        transitionVideo.playbackRate = 2;

        /*
                onIntroPlayableTimeoutId.current = setTimeout(() => {
                    onIntroVideoPlayable();
                }, 5000);
        */

        unlockAutomaticallyTimeoutId.current = setTimeout(() => {
            doUnlockSequence();
        }, 15000);

        introVideo.addEventListener("canplaythrough", onIntroVideoPlayable);
        introVideo.addEventListener("ended", onIntroVideoEnded);
        introVideo.load();

        return () => {
            clearTimeout(unlockAutomaticallyTimeoutId.current);
            clearTimeout(onIntroPlayableTimeoutId.current);
        };
    }, [props.introVideoRef]);

    function doUnlockSequence () {
        // pageConfig.turnDeviceSwitchingOn();
        dispatch(turnDeviceSwitchingOn());
        clearTimeout(unlockAutomaticallyTimeoutId.current);
        setShowUnlock(false);
        introVideoContainerRef.current.classList.add("active");
        introVideoRef.current.play();
    }

    if (showUnlock) {
        return <UnlockScreen loading={loading} onUnlockCallback={doUnlockSequence}/>;
    }

    return (
        <div>
        </div>
    );
}

export default LandingPage;
