import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectSaleState } from "./mintingSlice";
import { selectIsWhitelisted, selectWalletConnected } from "../metamask/metamaskSlice";
import PresaleOverScreen from "./PresaleOverScreen";
import useFadeIn from "../../hooks/useFadeIn";
import ComingSoonSection from "./ComingSoonSection";
import { useNavigate } from "react-router-dom";
import NotWhitelistedScreen from "./NotWhitelistedScreen";
import { SaleState } from "./SaleState";
import SoldOutScreen from "./SoldOutScreen";
import MintNFTWhitelistScreen from "./MintNFTWhitelistScreen";
import MintNFTPublicScreen from "./MintNFTPublicScreen";

function SaleScreen (props: any) {
    const walletConnected = useAppSelector(selectWalletConnected);
    const saleState = useAppSelector(selectSaleState);
    const inWhitelist = useAppSelector(selectIsWhitelisted);
    const navigate = useNavigate();
    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        if (!walletConnected) {
            navigate("/mint");
        }
    }, [walletConnected]);

    let currentScreen;
    switch (saleState) {
    case SaleState.COMING_SOON:
        currentScreen = <ComingSoonSection />;
        break;
    case SaleState.PRESALE_OVER:
        currentScreen = <PresaleOverScreen />;
        break;
    case SaleState.WHITELIST_SALE_STARTED:
        if (inWhitelist) {
            currentScreen = <MintNFTWhitelistScreen />;
        } else {
            currentScreen = <NotWhitelistedScreen />;
        }
        break;
    case SaleState.SOLD_OUT:
        currentScreen = <SoldOutScreen />;
        break;
    case SaleState.PUBLIC_SALE_STARTED:
        currentScreen = <MintNFTPublicScreen />;
        break;
    default:
        currentScreen = <PresaleOverScreen />;
        break;
    }

    return currentScreen;
}

export default SaleScreen;
