import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { hideCloseButton, showCloseButton } from "../device-ui/deviceUISlice";

function SquadScreen (props: any) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(showCloseButton(() => {
            if (props.onCloseButtonClicked) {
                props.onCloseButtonClicked();
            }
        }));
        return () => {
            dispatch(hideCloseButton());
        };
    }, [dispatch, hideCloseButton, showCloseButton]);

    return (
        <div className="squad-screen">
            <div id="subsec-squad" className="a100 subsection active">
                <div id="squad-title">
                    <h2><strong>EY3K0N </strong> T3AM</h2>
                </div>
                <div id="squad-viewport">
                    <div id="squad-container">
                        <div className="squad-entry sqe-t sqe-tl">
                            <div id="sq-02" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Jesse McMillin</h4>
                                    <p>Former Creative Director or Lyft, Virgin America and experience at NIKE. Responsible for creative direction of Rebel Studios ,assets, and onboarding artistic talent.</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-t">
                            <div id="sq-01" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Adam Pollina</h4>
                                    <p>Former Hollywood & Catwalk superstar,Adam Pollina has extensive experience working at Yves Saint Laurent, Louis Vuitton, Marvel, DC and Valiant</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-t">
                            <div id="sq-09" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Gavin Cunningham</h4>
                                    <p>Lawyer by profession, Fintech & AI background and extensive experience running family offices and funds. Responsible for for IP structuring, financing & governance.</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-t">
                            <div id="sq-12" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Zak Lewis</h4>
                                    <p>BI, Cybersecurity & Blockchain expert. Led Cybersecurity initiatives for multiple high-profile companies. Managing tech stack, Blockchain, Smart Contracts & Application Builds.</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-t">
                            <div id="sq-08" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Darren Baggett</h4>
                                    <p>Previous experience as European head of Derivatives for Morgan Stanley. Darren is responsible for financial structure, compliance and financial reporting.</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-t sqe-tr">
                            <div id="sq-15" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Komal Sethi</h4>
                                    <p>A serial entrepreneur with a background in computer science, Komal sits on the board of Coinlist and advises Rebel with its token structure and fundraising strategy</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-b sqe-bl">
                            <div id="sq-05" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Sharon Henley</h4>
                                    <p>Extensive experience in marketing & product innovation. Former Nikkon & The Royal Mint head of marketing. Women in FinTech Powerlist 2021 | Top 101 Women in Blockchain</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                        <div className="squad-entry sqe-b sqe-br">
                            <div id="sq-13" className="sq-card-wrapper">
                                <div className="sq-card-info-bg"></div>
                                <div className="sq-card-info">
                                    <h4>Mark Powers</h4>
                                    <p>ADVISOR. Marvel editor for 10 years: X-Men, Wolverine, X-Force, Cable, Spectacular Spider-Man and Fantastic Four. At Devil’s Due, he wrote G.I. JOE.</p>
                                </div>
                                <div className="sq-card"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SquadScreen;
