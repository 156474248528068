import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { COVER_METADATA } from "../coverMetadata/coverMetadataService";
import { selectSelectedCover, setSelectedCover } from "./comicSlice";

interface PillCardProps {
    pill: COVER_METADATA;
}

function PillCard (props: PillCardProps) {
    const dispatch = useAppDispatch();

    const selectedCover = useAppSelector(selectSelectedCover);
    const handleSelectCover = () => {
        dispatch(setSelectedCover(props.pill));
    };

    return (
        <div className="comic-card-container">
            <div className={`comic-card-wrapper ${selectedCover?.tokenId === props.pill.tokenId ? "comic-card-selected-cover" : ""}`}
                onClick={handleSelectCover}>
                <div className="comic-card">
                    <div className="comic-card-image-wrapper">
                        <div className="comic-card-image"
                            style={{
                                background: `url('${props.pill.image}?cb=${props.pill.attributes.ST4G3}') center/contain no-repeat`
                            }} />
                    </div>
                    <div className="comic-card-title-wrapper">
                        <span className="comic-card-title">
                            {`#${props.pill.tokenId}`}
                        </span>
                        <span className="comic-card-num-pages">
                            {`ST4G3: ${props.pill.attributes.ST4G3}`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PillCard;
