import React, { useEffect } from "react";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { useAppDispatch } from "../../app/hooks";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import useBodyClasses from "../../hooks/useBodyCasses";

interface CodeAboutScreenProps {
    playIntroClicked?: () => void;
    squadButtonClicked?: () => void;
}

function CodeAboutScreen (props: CodeAboutScreenProps) {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.CODE));
        body.setClasses(["sec-code"]);
    }, []);

    return (
        <div className="code-about-screen">
            <div id="subsec-about" className="a100 subsection active">
                <div id="hero_bg"></div>
                <div id="about-info">
                    <h2><strong>SE3</strong> AND BE <strong>SE3N</strong></h2>
                    <p>Welcome to <strong>EY3K0N</strong>, a world brimming with endless possibilities and unknown potential, where dark entities and unsung heroes fight for the future of humanity.</p>
                    <p>10 minutes into the future... faith in institutions and politicians has dwindled, leaving the masses searching for security and purpose. Enter <strong>The AG3NCY</strong>, a malevolent entity seizing the opportunity to offer an escape through massive VRealms, where billions can immerse themselves in dopamine-laden illusions. But behind their public face as a "management company" for 10 enigmatic <strong>EY3K0Ns</strong> lies a hidden truth, that one day, we hope you will discover too.</p>
                    <p>The 10 "chosen" <strong>EY3K0N's</strong> are named: <strong>OMN1</strong> "Omm-nee", <strong>S1M14N</strong> "Sih-mee-un", <strong>IND1R4</strong> "In-dee-rah", <strong>C0C0</strong> "Coe-coe", <strong>K0B4</strong> "coe-baah", <strong>K1NG CHR0M3</strong> "king-crome", <strong>ST3RL1NG</strong> "ster-ling", <strong>B0T1K4</strong> "bot-ee-kah", <strong>K4L</strong> "cal", and <strong>B4MB4MS</strong> "bam-bams". These TEN are the elite of the elite. From fashionistas, to politicials, tech titans to climate activists, they control the VRealms <strong>the AG3NCY</strong> has built to contain us. With that power comes responsibility. Some embrace it, some seclude themselves from it, and some despise it, and <strong>The AG3NCY</strong> is keeping a close eye on each of them...</p>
                    <p><strong>The AG3NCY</strong> craves dominance over politics, religion, war, borders, culture, finance, and even fashion. Their belief? That humanity is incapable of avoiding conflict and exploitation throughout history, and they are the solution. With the ten <strong>EY3K0Ns</strong> commanding a staggering six billion followers, <strong>the AG3NCY</strong> manipulates minds, shaping what people wear, who they root for, whom to love, and whom to hate. They offer a tempting proposition — why think for ourselves when <strong>the AG3NCY</strong> can do it all?</p>
                    <p>Yet, amidst this web of control, factions of resistance emerge, determined to reclaim a world where independent thought prevails, even in the face of chaos. Anti-AG3NCY revolutionaries, rogue sentient machines, self-aware AI, and the very <strong>EY3K0Ns</strong> themselves stand united, ready to challenge the status quo and unplug the machines.</p>
                    <p>The <strong>EY3K0Ns</strong> need all the help they can get to fight off <strong>The AG3NCY</strong>, and you can join in on the action! The world of <strong>EY3K0N</strong> exists to fit your preferences. Maybe you prefer to read comics (physical or digital), turn heads in a one-of-a-kind <strong>EY3K0N</strong> sweater...Or, maybe you'd like to swipe dollar bills in your favorite Tokyo nightclub, over a few sips of <strong>EY3K0N</strong> tequila. From the silver screen to the silver platter, there's nowhere the story won't find you.</p>
                    <p>If you want to kick things up a notch, you can join thousands of other tastemakers and entrepreneurs to help shape the <strong>EY3K0N</strong> world through ownership of one of our <strong>EY3K0N</strong> Digital Collectibles.</p>
                    <p>In this epic universe where alliances shift, factions splinter, friendships shatter, and new alliances form, one thing is certain—humanity will never be the same again. Prepare for an enthralling journey through a reality that mirrors our own uncertain path. Join the resistance against <strong>the AG3NCY</strong>, where anti-heroes and sentient machines collide with the enigmatic <strong>EY3K0Ns</strong>. This thrilling saga will keep you on the edge of your seat as you navigate a world where nothing is as it seems. Brace yourself—this is the revolution you've been waiting for.</p>
                </div>
                <div id="about-menu">
                    <div id="am-btn-intro" className="am-btn sq-btn" onClick={() => { if (props.playIntroClicked) props.playIntroClicked(); }}><span>Play Intro</span></div>
                    <div id="am-decor1" className="pink-csq-decor act"></div>
                    <div id="am-btn-squad" className="am-btn sq-btn" onClick={() => { if (props.squadButtonClicked) props.squadButtonClicked(); }}><span>EY3K0N Squad</span></div>
                    <div id="am-decor2" className="pink-csq-decor act"></div>
                    <a href="https://rebelstudios.io" target="_blank"><div id="am-btn-rebel" className="am-btn sq-btn" onClick={() => { /* if (props.rebelButtonClicked) props.rebelButtonClicked(); */ }}><span>Rebel Studios</span></div>
                    </a>
                </div>
            </div>
        </div>);
}

export default CodeAboutScreen;
