import React, { useEffect, useState } from "react";

function TimeWidget (props: any) {
    const [time, setTime] = useState({
        hours: 0,
        minutes: 0
    });

    function update () {
        const now = new Date(Date.now());
        const values: any = {};
        values.hours = now.getHours().toString().padStart(2, "0");
        values.minutes = now.getMinutes().toString().padStart(2, "0");
        setTime(values);
    }

    useEffect(() => {
        update();
        const timeoutId = setInterval(update, 3000);

        return () => clearInterval(timeoutId);
    }, []);

    return (
        <div id="dw-comms" className="dw-widget">
            <div id="dw-time"><span className="dw-fnt">{time.hours.toString().padStart(2, "0")}:{time.minutes.toString().padStart(2, "0")}</span></div>
            <div id="dw-signal"></div>
            <div id="dw-energy"></div>
        </div>
    );
}

export default TimeWidget;
