import React, { useEffect, useRef, useState } from "react";
import RoadmapScreen1 from "./RoadmapScreen1";
import RoadmapScreen2 from "./RoadmapScreen2";
import RoadmapScreen3 from "./RoadmapScreen3";
import ContentViewport from "../../components/ContentViewport";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { useAppDispatch } from "../../app/hooks";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";

function RoadmapScreen (props: any) {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();
    const sectionRef: any = useRef();
    useFadeIn(sectionRef, {
        faceInTimeout: 1200
    });

    const pageCount = 3;
    const leftButton: any = useRef();
    const rightButton: any = useRef();
    const bodyClasses = useBodyClasses();
    const [currentScreen, setCurrentScreen] = useState(0);
    const [classNames, setClassNames]: any = useState([[], [], []]);

    useEffect(() => {
        dispatch(turnDeviceOn());
        bodyClasses.classListAdd("sec-map");
        disableRoadmapShiftButtons();
        dispatch(setActiveButton(DeviceUIButton.MAP));
        body.setClasses(["sec-map"]);
        transition(-1, 0);
        setClassNames([[], [], []]);
        return () => bodyClasses.classListRemove("sec-map");
    }, []);

    const nextClicked = () => {
        let nextScreen = currentScreen + 1;
        if (nextScreen > pageCount) {
            nextScreen = pageCount;
        }
        playVideoTransition();
        transition(currentScreen, nextScreen);
    };

    const transition = (currentIndex: number, nextIndex: number) => {
        setClassNamesValues(currentIndex, ["fadeOut"]);
        // setCurrentScreen(nextIndex);
        setTimeout(function () {
            const cState = [...classNames];
            cState[currentIndex] = [];
            cState[nextIndex] = ["active"];
            setClassNames(cState);
            setCurrentScreen(nextIndex);
            updateRoadmapShiftButtons(nextIndex);
        }, 1000);
    };

    const setClassNamesValues = (index: number, _classNames: string[]) => {
        const cState = [...classNames];
        cState[index] = _classNames;
        setClassNames(cState);
    };

    const previousClicked = () => {
        let previousScreen = currentScreen - 1;
        if (previousScreen < 0) {
            previousScreen = 0;
        }
        // mapScreens[previousScreen].props.classNames = ["active"];
        playVideoTransition();
        transition(currentScreen, previousScreen);
        // setCurrentScreen(previousScreen);
    };

    function playVideoTransition () {
        const vidT: any = document.querySelector("#bgvid-trans video");
        vidT.pause();
        vidT.currentTime = 0;
        vidT.play();
    }

    function updateRoadmapShiftButtons (current: number) {
        leftButton.current.classList.toggle("fade", (current === 0));
        rightButton.current.classList.toggle("fade", (current >= (pageCount - 1)));
    }

    function disableRoadmapShiftButtons () {
        leftButton.current.classList.toggle("fade", true);
        rightButton.current.classList.toggle("fade", true);
    }

    return (
        <ContentViewport>
            <div ref={sectionRef} id="sec-map" className="section">
                <div id="map-pages-container">
                    <RoadmapScreen1 key={1} classNames={classNames[0]}/>
                    <RoadmapScreen2 key={2} classNames={classNames[1]}/>
                    <RoadmapScreen3 key={3} classNames={classNames[2]}/>
                </div>
                <div id="map-menu" className="shift-menu">
                    <div id="rmm-label" className="sm-label">0{currentScreen + 1} - 03</div>
                    <div className="greenblue-csq-decor act"></div>
                    <div id="rmm-lb-pvt" className="sm-btn-pvt smbp-l">
                        <div ref={leftButton} id="rmm-left-btn" className="sm-btn smb-l" onClick={() => previousClicked()}>
                            <div className="btn-frm"></div>
                            <div className="btn-icn"></div>
                        </div>
                    </div>
                    <div id="rmm-rb-pvt" className="sm-btn-pvt smbp-r">
                        <div ref={rightButton} id="rmm-right-btn" className="sm-btn smb-r" onClick={() => nextClicked()}>
                            <div className="btn-frm"></div>
                            <div className="btn-icn"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
}

export default RoadmapScreen;
