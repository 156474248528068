export const siteData = {
    roadmap: {
        pages: [
            {
                title: "0N3",
                entries: [
                    {
                        title: "GALLERY - LIVE",
                        description: "Discover a universe of iconic characters in the interactive gallery. From future-fashion to cyber- enhancement trends. Find your EY3KON.",
                        checked: true
                    },
                    {
                        title: "RADIO - In Progress",
                        description: "Tune in to NODE FM RADIO for an in-world audio experience. Keep an ear out for news, clues, updates, AMA's, exclusive DJ sets and a mashup of future sounds.",
                        checked: false
                    },
                    {
                        title: "GAME - In Design",
                        description: "We're bringing together some of the world's top creative minds to reimagine the future of immersive storytelling. Surprises in store. Keep an EY3 out for the breadcrumbs.",
                        checked: false
                    }
                ]
            },
            {
                title: "TW0",
                entries: [
                    {
                        title: "DROPS - Ongoing",
                        description: "NFT holders can expect exclusive access to original comics, one-of-a-kind artist originals and other limited edition content.",
                        checked: false
                    },
                    {
                        title: "FASHION/Collabs - In Design",
                        description: "The EY3KON Community can look forward to sporting iconic pieces via limited edition swag and streetwear drops.",
                        checked: false
                    },
                    {
                        title: "EVENTS - Cooking",
                        description: "Become an EY3KON, literally. With a background in creating groundbreaking festivals and immersive experiences, you know it's going to kick off. VEY3P's only.",
                        checked: false
                    }
                ]
            },
            {
                title: "THR33",
                entries: [
                    {
                        title: "ON SCREEN - Dreaming big",
                        description: "Our big vision is to explore a whole new sub-genre of entertainment, an interactive experience that pours onto the big screen. Get in at the start.",
                        checked: false
                    }
                ]
            }
        ]
    },
    viceCards: {
        Indira: {
            name: "IND1R4",
            id: "EY3K0N#??????",
            description: "IND1R4 First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "IND1R4",
                artist: "Adam Pollina",
                attributes: {
                    Character: "IND1R4",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        Botika: {
            name: "B0T1K4",
            id: "EY3K0N#??????",
            description: "B0T1K4 First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "B0T1K4",
                artist: "Adam Pollina",
                attributes: {
                    Character: "B0T1K4",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"

                }
            }
        },
        BadBunny: {
            name: "B4MB4MS",
            id: "EY3K0N#??????",
            description: "B4MB4MS First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "B4MB4MS",
                artist: "Adam Pollina",
                attributes: {
                    Character: "B4MB4MS",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        Coco: {
            name: "C0C0",
            id: "EY3K0N#??????",
            description: "C0C0 First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "C0C0",
                artist: "Adam Pollina",
                attributes: {
                    Character: "C0C0",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        Hiro: {
            name: "???",
            id: "EY3K0N#??????",
            description: "??? First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "???",
                artist: "Adam Pollina",
                attributes: {
                    BACKGROUND: "???",
                    "EXO-1": "???",
                    NERO: "???",
                    LEO: "???",
                    HAL: "???",
                    "FUSION ARMOR": "???",
                    SPINNERS: "???",
                    "ION DOME": "???",
                    "MORNING GLORY": "???",
                    HOODIE: "???"
                }
            }
        },
        Ji3: {
            name: "???",
            id: "EY3K0N#??????",
            description: "??? First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "???",
                artist: "Adam Pollina",
                attributes: {
                    BACKGROUND: "???",
                    "EXO-1": "???",
                    NERO: "???",
                    LEO: "???",
                    HAL: "???",
                    "FUSION ARMOR": "???",
                    SPINNERS: "???",
                    "ION DOME": "???",
                    "MORNING GLORY": "???",
                    HOODIE: "???"
                }
            }
        },
        KingChrome: {
            name: "K1NG CHR0M3",
            id: "EY3K0N#??????",
            description: "K1NG CHR0M3 First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "K1NG CHR0M3",
                artist: "Adam Pollina",
                attributes: {
                    Character: "K1NG CHR0M3",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        Omni: {
            name: "OMN1",
            id: "EY3K0N#??????",
            description: "OMN1 First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "OMN1",
                artist: "Adam Pollina",
                attributes: {
                    Character: "OMN1",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        Koba: {
            name: "K0B4",
            id: "EY3K0N#??????",
            description: "K0B4 First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "K0B4",
                artist: "Adam Pollina",
                attributes: {
                    Character: "K0B4",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        Sterling: {
            name: "ST3RL1NG",
            id: "EY3K0N#??????",
            description: "ST3RL1NG First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "ST3RL1NG",
                artist: "Adam Pollina",
                attributes: {
                    Character: "ST3RL1NG",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        SuperSimian: {
            name: "S1MI4N",
            id: "EY3K0N#??????",
            description: "S1MI4N First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "S1MI4N",
                artist: "Adam Pollina",
                attributes: {
                    Character: "S1MI4N",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        },
        TeamMars: {
            name: "K4L",
            id: "EY3K0N#??????",
            description: "K4L First Edition - EY3K0N#??????",
            image: "EY3K0N#??????.jpg",
            properties: {
                rebelId: "EY3K0N#A??????",
                organization: "Rebel Studios",
                collection: "Ey3k0n First Edition",
                character: "K4L",
                artist: "Adam Pollina",
                attributes: {
                    Character: "K4L",
                    Skin: "????",
                    Tag: "????",
                    Techgear: "????",
                    Mouth: "????",
                    Jewelry: "????",
                    "Outer Garment": "????",
                    Headgear: "????",
                    Eyewear: "????",
                    Background: "????",
                    Tattoo: "????",
                    Type: "????",
                    Hair: "????",
                    Armor: "????",
                    "Base Garment": "????",
                    Face: "????",
                    Badges: "????",
                    Accessories: "????",
                    "Trait Number": "????"
                }
            }
        }
    }
};
