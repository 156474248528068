import { mailchimpAddMailingList } from "../firebase/functions";

interface MailchimpServiceResponse {
    success: boolean,
    error: string | null,
    res: any
}
const addMailingList = (audience: string) => async (email: string, walletAddress: string): Promise<MailchimpServiceResponse> => {
    const res = await mailchimpAddMailingList({
        audience: audience,
        email: email,
        walletAddress: walletAddress
    });

    return res.data as MailchimpServiceResponse;
};

const addComicDropMailingList = addMailingList("COMIC_DROP");
const addClaimMailingList = addMailingList("CLAIM");

export const mailchimpService = {
    addComicDropMailingList,
    addClaimMailingList
};
