import React from "react";
import MintFrame from "./MintFrame";
import useFancyNav from "../../hooks/useFancyNav";
import { useCloseButton } from "../../hooks/useCloseButton";

function MintingErrorScreen (props: any) {
    const { navigateTo } = useFancyNav();
    const onCloseClicked = () => {
        navigateTo("/mint");
    };
    useCloseButton(onCloseClicked);
    return (
        <MintFrame>
            <div id="mint-error-scrn" className="mint-screen a100 active">
                <div id="mint-error-container" className="mint-center-content">
                    <div id="mnt-error-title" className="msc-entry">
                        <span><strong>ERROR!</strong></span>
                    </div>
                    <div id="mnt-error-message" className="mnt-msg msc-entry btm-separator top-separator">
                        <span>Something went wrong. Please check the possible issues below and try again.</span>
                    </div>
                    <div id="mnt-error-causes" className="msc-entry">
                        <div className="mec-entry">There are not enough funds (ETH) in your wallet to cover mint cost +
                            gas fees
                        </div>
                        <div className="mec-entry">There are not enough NFTs left. Try a lower quantity</div>
                        <div className="mec-entry">You may have your full allocation</div>
                        <div className="mec-entry mnt-public">We might have just sold out</div>
                        <div className="mec-entry mnt-whitelist">TH3 L1ST minting might be over</div>
                    </div>
                    <div id="mnt-error-btn-box" className="msc-entry">
                        <div id="me-retry-button" className="sq-btn" onClick={() => navigateTo("/mint") }>
                            RETRY
                        </div>
                    </div>
                </div>
            </div>

        </MintFrame>
    );
}

export default MintingErrorScreen;
