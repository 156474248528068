/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { EY3K0N_METADATA } from "../ey3k0nMetadata/ey3k0nMetadataService";
import { COVER_METADATA } from "../coverMetadata/coverMetadataService";
import { getFilteredPills, getFilteredTokens } from "../metamask/api";
import axios from "axios";

enum ActionStatus {
    IDLE = "PENDING",
    PENDING = "PENDING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export interface IncubateState {
    fetchingUseableTokenStatus: ActionStatus,
    hasIncubatePerms: boolean,
    canIncubateEy3k0ns: number[],
    canIncubateCovers: number[],
    incubateResult: string,
}

const initialState: IncubateState = {
    fetchingUseableTokenStatus: ActionStatus.IDLE,
    hasIncubatePerms: false,
    canIncubateEy3k0ns: [],
    canIncubateCovers: [],
    incubateResult: "../img/incubate/incubate_result_placeholder.png"
};

export const getCanIncubateEy3k0ns = createAsyncThunk(
    "incubate/getCanIncubateEy3k0ns",
    async (tokenIds: number[], thunkApi): Promise<number[]> => {
        const state: any = thunkApi.getState();
        const idStrings = tokenIds.map(x => x.toString());
        const res = (await getFilteredTokens(idStrings)).map((x: string) => Number(x));
        return res;
    }
);

export const getCanIncubateCovers = createAsyncThunk(
    "incubate/getCanIncubateCovers",
    async (tokenIds: number[], thunkApi): Promise<number[]> => {
        const state: any = thunkApi.getState();
        const comicIdStrings = tokenIds.map(x => x.toString());
        return (await getFilteredPills(comicIdStrings)).map((x: string) => Number(x));
    }
);

export const getIncubateResultImage = createAsyncThunk(
    "incubate/getIncubateResultImage",
    async (coverId: number, thunkApi): Promise<string> => {
        const state: any = thunkApi.getState();
        const res: any = await axios.get(`https://anthology.ey3k0n.io/metadata/${coverId}`);
        return res.data.image.replace(".png", ".jpg");
    }
);
export const incubateSlice = createSlice({
    name: "incubate",
    initialState,
    reducers: {
        setHasIncubatePerms: (state: Draft<IncubateState>, action: PayloadAction<boolean>) => {
            state.hasIncubatePerms = action.payload;
        },
        setIncubateResult: (state: Draft<IncubateState>, action: PayloadAction<string>) => {
            state.incubateResult = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCanIncubateEy3k0ns.pending, (state, action) => {
            state.fetchingUseableTokenStatus = ActionStatus.PENDING;
        }).addCase(getCanIncubateEy3k0ns.rejected, (state, action) => {
            state.fetchingUseableTokenStatus = ActionStatus.ERROR;
        }).addCase(getCanIncubateEy3k0ns.fulfilled, (state, action) => {
            state.fetchingUseableTokenStatus = ActionStatus.SUCCESS;
            state.canIncubateEy3k0ns = action.payload;
        });

        builder.addCase(getCanIncubateCovers.pending, (state, action) => {
            state.fetchingUseableTokenStatus = ActionStatus.PENDING;
        }).addCase(getCanIncubateCovers.rejected, (state, action) => {
            state.fetchingUseableTokenStatus = ActionStatus.ERROR;
        }).addCase(getCanIncubateCovers.fulfilled, (state, action) => {
            state.fetchingUseableTokenStatus = ActionStatus.SUCCESS;
            state.canIncubateCovers = action.payload;
        });

        builder.addCase(getIncubateResultImage.fulfilled, (state, action) => {
            state.incubateResult = action.payload;
        });
    }
});
export const {
    setHasIncubatePerms,
    setIncubateResult
} = incubateSlice.actions;

export const selectIncubateResult = (state: RootState) => state.incubate.incubateResult;
export const selectFetchingUseableTokenStatus = (state: RootState) => state.incubate.fetchingUseableTokenStatus;
export const selectHasIncubatePerms = (state: RootState) => state.incubate.hasIncubatePerms;
export const selectCanIncubateEy3k0nIds = (state: RootState) => state.incubate.canIncubateEy3k0ns;
export const selectCanIncubateCoverIds = (state: RootState) => state.incubate.canIncubateCovers;
export const selectUserCanIncubateEy3k0nMetadata = (state: RootState) => {
    const userTokenMetadata: {tokenId: number, metadata: EY3K0N_METADATA}[] = [];
    state.incubate.canIncubateEy3k0ns.forEach((tokenId: number) => {
        if (state.ey3k0nMetadata.metadata[tokenId]) {
            userTokenMetadata.push({
                tokenId: tokenId,
                metadata: state.ey3k0nMetadata.metadata[tokenId]
            });
        }
    });
    return userTokenMetadata;
};
export const selectUserCanIncubateCoverMetadata = (state: RootState) => {
    const userTokenMetadata: {tokenId: number, metadata: COVER_METADATA}[] = [];
    state.incubate.canIncubateCovers.forEach((tokenId: number) => {
        if (state.coverMetadata.metadata[tokenId]) {
            userTokenMetadata.push({
                tokenId: tokenId,
                metadata: state.coverMetadata.metadata[tokenId]
            });
        }
    });
    return userTokenMetadata;
};
export default incubateSlice.reducer;
