import React from "react";
import { isIndexSignatureDeclaration, isTemplateSpan } from "typescript";

interface CardSelectorProps {
    items: any[],
    selectedIdx: number,
    setSelectedIdx: (idx: number) => void,
    renderItem: (item: any) => any
};

const CardSelector = (props: CardSelectorProps) => {
    /* CSS UTILS */
    const selected = (idx: number) => {
        if (idx === props.selectedIdx) return "card-selector-card-selected";
        if (idx === props.selectedIdx - 1) return "card-selector-card-prev";
        if (idx === props.selectedIdx + 1) return "card-selector-card-next";
        return "";
    };
    const transform = (idx: number) => {
        const translate = `translateX(${(idx - props.selectedIdx) * 20}%)`;
        // const scale = idx === props.selectedIdx ? "" : "scale(0.8)";
        // if (scale.length > 0) {
        //     return `${translate} ${scale}`;
        // }
        return translate;
    };

    /* BTN UTILS */
    const selectPrev = () => {
        props.setSelectedIdx(Math.max(0, props.selectedIdx - 1));
    };
    const selectNext = () => {
        props.setSelectedIdx(Math.min(props.items.length - 1, props.selectedIdx + 1));
    };
    return (
        <div className="card-selector-container">
            <div className="card-selector-wrapper">
                {
                    props.items.map((item: any, index: number) => {
                        return (
                            <div key={index} className={`card-selector-card ${selected(index)}`}
                                style={{
                                    transform: transform(index)
                                }}>
                                <props.renderItem item={item}/>
                            </div>
                        );
                    })
                }
            </div>
            <div className="card-selector-btn-container">
                { (props.selectedIdx > 0)
                    ? <div className="sq-btn" onClick={selectPrev}>{"<"}</div>
                    : <div />
                }
                { (props.selectedIdx < (props.items.length - 1))
                    ? <div className="sq-btn" onClick={selectNext}>{">"}</div>
                    : <div />
                }
            </div>
        </div>
    );
};

export default CardSelector;
