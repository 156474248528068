import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { CartLine } from "../shopify/interfaces/cartInterface";
import { selectCart, selectNumItemsInCart } from "../shopify/shopifySlice";
import CartItemQtyUpdate from "./CartItemQtyUpdate";

const CartDisplay = (props: any) => {
    const cart = useAppSelector(selectCart);

    /* Trigger wiggle when add item */
    const numItemsInCart = useAppSelector(selectNumItemsInCart);
    const [btnWiggle, setBtnWiggle] = useState(false);
    const [prevCartLen, setPrevCartLen] = useState(0);
    const triggerBtnWiggle = () => {
        // Button begins to shake
        setBtnWiggle(true);
        // Buttons stops to shake after 0.3 seconds
        setTimeout(() => setBtnWiggle(false), 500);
    };
    useEffect(() => {
        if (numItemsInCart > prevCartLen) {
            triggerBtnWiggle();
        }
        setPrevCartLen(numItemsInCart);
    }, [numItemsInCart]);

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    const getAllChildren = (htmlElement: any): any => {
        if (htmlElement.children.length === 0) return [htmlElement];
        const allChildElements = [];
        for (let i = 0; i < htmlElement.children.length; i++) {
            const children = getAllChildren(htmlElement.children[i]);
            if (children) allChildElements.push(...children);
        }
        allChildElements.push(htmlElement);
        return allChildElements;
    };
    const checkClickInCart = (e: any) => {
        const cardWrapperElement = document.getElementById("cart-wrapper");
        const cartElements = getAllChildren(cardWrapperElement);
        let isInCart = false;
        for (let i = 0; i < cartElements.length; i++) {
            if (e.target === cartElements[i]) {
                isInCart = true;
            }
        }
        if (!isInCart) {
            setExpanded(false);
        };
    };
    useEffect(() => {
        if (expanded) {
            window.addEventListener("click", checkClickInCart);
            return () => {
                window.removeEventListener("click", checkClickInCart);
            };
        };
    }, [expanded]);

    const cartExpanded = expanded ? "cart-expanded" : "";

    return (
        <div id="cart-wrapper" className={`${cartExpanded}`}>
            <div id="cart-icon" className={`${btnWiggle ? "cart-btn-wiggle" : ""}`} onClick={toggleExpanded}>
                { (cart && cart.lines.length > 0) &&
                    <div id="cart-num-items">{numItemsInCart}</div>
                }
            </div>
            <div id="cart-items-wrapper">
                <div id="cart-title">CART</div>
                <div id="cart-item-list">
                    {
                        cart?.lines.map((productLine: CartLine, index: number) => (
                            <div key={`${productLine.id}-${index}`} className="cart-item-wrapper">
                                <div className="cart-item-text">
                                    {`${productLine.productTitle}${productLine.title === "Default Title" ? "" : " - " + productLine.title}`}
                                </div>
                                <div className="cart-item-qty-select">
                                    <CartItemQtyUpdate merchandiseId={productLine.merchandiseId} addDisabled={productLine.quantity >= productLine.quantityAvailable}/>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <a id="cart-checkout-btn" className={cart && cart.lines.length > 0 ? "" : "link-disabled"} href={cart?.checkoutUrl} target="_blank" rel="noopener noreferrer">
                    CHECK OUT
                </a>
            </div>
            <div id="cart-items-close-btn" onClick={toggleExpanded} />
        </div>
    );
};

export default CartDisplay;
