/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { productService } from "./productService";
import { cartService } from "./cartService";
import { ProductDetails } from "./interfaces/productInterface";
import { Cart } from "./interfaces/cartInterface";

export interface ShopifyState {
    products: ProductDetails[],
    productsFetched: boolean,
    productTypes: string[],
    cartLoading: boolean,
    cart: Cart | null
};

const initialState: ShopifyState = {
    products: [],
    productsFetched: false,
    productTypes: [],
    cartLoading: false,
    cart: null
};

/* PRODUCT UTILS */
const MAX_PRODUCTS = 50; // GraphQL api requires us to set first:n param
export const fetchProducts = createAsyncThunk(
    "shopify/fetchProducts",
    async (_: any = "", thunkApi): Promise<ProductDetails[]> => {
        const state: any = thunkApi.getState();
        const products = state.shopify.products;
        if (products.length > 0) {
            return products;
        }
        return await productService.getProducts(MAX_PRODUCTS);
    }
);
const getProductTypes = (prods: ProductDetails[]): string[] => {
    const types = new Set<string>();
    prods.forEach((product: ProductDetails) => {
        types.add(product.productType);
    });
    return Array.from(types.values());
};

/* CART UTILS */
export const initCart = createAsyncThunk(
    "shopify/initCart",
    async (_: any = "", thunkApi): Promise<Cart> => {
        const state: any = thunkApi.getState();
        const cart = state.shopify.cart;
        // If already has cart
        if (cart) return cart;

        return await cartService.createCart();
    }
);
export const addCartItem = createAsyncThunk(
    "shopify/addCartItem",
    async (args: { merchandiseId: string }, thunkApi): Promise<Cart | null> => {
        const state: any = thunkApi.getState();
        const cart = state.shopify.cart;
        // If no cart
        if (!cart) return cart;

        const cartLine = cartService.getCartLine(cart, args.merchandiseId);
        if (!cartLine) {
            return cartService.addCartLine(cart.id, args.merchandiseId);
        }
        return cartService.updateCartLine(cart.id, cartLine.id, args.merchandiseId, cartLine.quantity + 1);
    }
);
export const removeCartItem = createAsyncThunk(
    "shopify/removeCartItem",
    async (args: { merchandiseId: string}, thunkApi): Promise<Cart | null> => {
        const state: any = thunkApi.getState();
        const cart = state.shopify.cart;
        // If no cart
        if (!cart) return cart;

        const cartLine = cartService.getCartLine(cart, args.merchandiseId);
        if (!cartLine) { // Unlikely to happen. Should only be able to remove if item is already in cart
            return cart;
        }
        return cartService.updateCartLine(cart.id, cartLine.id, args.merchandiseId, cartLine.quantity - 1);
    }
);

export const shopifySlice = createSlice({
    name: "shopify",
    initialState,
    reducers: {
        setProducts: (state: Draft<ShopifyState>, action: PayloadAction<ProductDetails[]>) => {
            state.products = action.payload;
        }
    },
    extraReducers: builder => {
        /* Fetch products */
        builder
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.products = action.payload;
                state.productsFetched = true;
                state.productTypes = getProductTypes(action.payload);
            }).addCase(fetchProducts.rejected, (state, action) => {
                console.log(action);
            });

        /* Create Cart */
        builder
            .addCase(initCart.pending, (state, action) => {
                state.cartLoading = true;
            })
            .addCase(initCart.fulfilled, (state, action) => {
                state.cart = action.payload;
                state.cartLoading = false;
            })
            .addCase(initCart.rejected, (state, action) => {
                console.log(action);
            });

        /* Add item to cart */
        builder
            .addCase(addCartItem.pending, (state, action) => {
                state.cartLoading = true;
            })
            .addCase(addCartItem.fulfilled, (state, action) => {
                state.cart = action.payload;
                state.cartLoading = false;
            })
            .addCase(addCartItem.rejected, (state, action) => {
                console.log(action);
            });

        /* Remove item from cart */
        builder
            .addCase(removeCartItem.pending, (state, action) => {
                state.cartLoading = true;
            })
            .addCase(removeCartItem.fulfilled, (state, action) => {
                state.cart = action.payload;
                state.cartLoading = false;
            })
            .addCase(removeCartItem.rejected, (state, action) => {
                console.log(action);
            });
    }
});

export const selectProducts = (state: RootState) => state.shopify.products;
export const selectProductsFetched = (state: RootState) => state.shopify.productsFetched;
export const selectProductTypes = (state: RootState) => state.shopify.productTypes;
export const selectCartLoading = (state: RootState) => state.shopify.cartLoading;
export const selectCart = (state: RootState) => state.shopify.cart;
export const selectNumItemsInCart = (state: RootState) => state.shopify.cart
    ? state.shopify.cart.lines.reduce<number>((accumulator, obj) => {
        return accumulator + obj.quantity;
    }, 0)
    : 0;
export const selectItemQtyInCart = (itemId: string) => (state: RootState) => cartService.getItemQtyInCart(state.shopify.cart, itemId);
export default shopifySlice.reducer;
