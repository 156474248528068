import React from "react";

function RoadmapScreen2 (props: any) {
    const _classNames = props.classNames || [];
    const classNames = ["rm-page", ..._classNames].join(" ");
    return (
        <div id="rm-page-2" className={classNames}>
            <h2 className="mp-title">TW0</h2>
            <div className="mp-entries">
                <div className="mp-entry">
                    <div className="mpe-title">DROPS - Ongoing</div>
                    <div className="mpe-body">
                        NFT holders can expect exclusive access to original comics, one-of-a-kind artist originals and other limited edition content.
                    </div>
                </div>
                <div className="mp-entry">
                    <div className="mpe-title">FASHION/Collabs - In Design</div>
                    <div className="mpe-body">
                        description: "The EY3KON Community can look forward to sporting iconic pieces via limited edition swag and streetwear drops.
                    </div>
                </div>
                <div className="mp-entry">
                    <div className="mpe-title">EVENTS - Cooking</div>
                    <div className="mpe-body">
                        Become an EY3KON, literally. With a background in creating groundbreaking festivals and immersive experiences, you know it's going to kick off. VEY3P's only.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoadmapScreen2;
