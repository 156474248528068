import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// eslint-disable-next-line camelcase
import { radio_playToggle, selectTrackTitle } from "./radioSlice";
import useFancyNav from "../../hooks/useFancyNav";

function RadioWidget (props: any) {
    const fancyNav = useFancyNav();
    const dispatch = useAppDispatch();

    const trackTitle = useAppSelector(selectTrackTitle);

    const playToggle = () => {
        dispatch(radio_playToggle());
    };

    const openPlayer = () => {
        fancyNav.navigateTo("/radio");
    };

    return (
        <div className="radio-widget">
            <div id="rad-widget">
                <div id="rad-wid-play-container" className="rw-sec">
                    <div id="rad-wid-play-btn" className="radio-play" onClick={playToggle}></div>
                </div>
                <div id="rad-wid-info-container" className="rw-sec">
                    <div id="rad-wid-info" className="radio-info">{trackTitle}</div>
                </div>
                <div id="rad-wid-eq-container" className="rw-sec">
                    <div id="rad-wid-eq"></div>
                </div>
                <div id="rad-wid-expand-container" className="rw-sec">
                    <div id="rad-wid-expand-btn" onClick={openPlayer}></div>
                </div>
            </div>
            <div className="radioplayer"
                data-src-test="https://streaming.radio.co/sced7c0e79/listen"
                data-src="https://s4.radio.co/s54de34e1d/listen"
                data-autoplay="false"
                data-playbutton="false"
                data-volumeslider="false"
                data-elapsedtime="false"
                data-nowplaying="false"
                data-showplayer="false"
                data-showartwork="false">
            </div>
        </div>);
}

export default RadioWidget;
