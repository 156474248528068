import React from "react";

interface ShopItemOptionsProps {
    maxLabelLength: number;
    label: string;
    selected: string;
    options: string[];
    optionCallback: (option: string) => void;
}

const ShopItemOptions = (props: ShopItemOptionsProps) => {
    const SELECTED_CLASS = "option-selected";

    const isColorType = () => {
        return props.label.toLowerCase().includes("color");
    };
    const isSelected = (item: string) => {
        return props.selected === item;
    };

    return (
        <div className="shop-item-options-wrapper"
            style={{
                gridTemplateColumns: `${props.maxLabelLength * 3}% 1fr`
            }}>
            <div className="shop-item-options-label-wrapper">
                <div className="shop-item-options-label">{props.label}</div>
            </div>
            <div className="shop-item-options-options-wrapper">
                {
                    props.options.map((itemName: string, index: number) => {
                        if (isColorType()) {
                            return (
                                <div key={`${index}-${itemName}`}
                                    className={`shop-item-option-option-color ${isSelected(itemName) ? SELECTED_CLASS : ""}`}
                                    onClick={() => props.optionCallback(itemName)}
                                    style={{backgroundColor: itemName}}
                                />
                            );
                        }
                        return (
                            <div key={`${index}-${itemName}`}
                                className={`shop-item-option-option-text ${isSelected(itemName) ? SELECTED_CLASS : ""}`}
                                onClick={() => props.optionCallback(itemName)}>
                                {itemName.toUpperCase()}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ShopItemOptions;
