import React, { useEffect, useRef } from "react";
import ContentViewport from "../../components/ContentViewport";
import useFadeIn from "../../hooks/useFadeIn";
import { turnDeviceOn } from "../device-ui/deviceUISlice";
import { useAppDispatch } from "../../app/hooks";

interface MetamaskFrameProps {
    children: any;
}

function MetamaskFrame (props: MetamaskFrameProps) {
    const sectionRef: any = useRef();
    useFadeIn(sectionRef);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(turnDeviceOn());
    }, []);

    return (
        <ContentViewport>
            <div ref={sectionRef} className="sec-metamask section">
                <div className={"metamask-frame a100"}></div>
                {props.children}
            </div>
        </ContentViewport>
    );
}

export default MetamaskFrame;
