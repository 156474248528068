import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./features/transforms/windowsResizeListener";
import {
    BrowserRouter as Router
} from "react-router-dom";
import "./features/radio/RadioService";
import "./features/firebase/database";
import "./features/firebase/ga";
import { ApolloProvider } from "@apollo/client";
import { shopifyClient } from "./features/shopify/init";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <ApolloProvider client={shopifyClient}>
                    <App />
                </ApolloProvider>
            </Router>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
