const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const sterlingComic = [
    {
        img: "/img/comic/sterling/Sterling_Layered_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 29,
                offsetTopPerc: 4.5,
                offsetLeftPerc: 28.2
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 19,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 41,
                offsetTopPerc: 59,
                offsetLeftPerc: 31
            })
        ]
    },
    {
        img: "/img/comic/sterling/Sterling_Layered_2_3.jpg",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 45.7,
                offsetTopPerc: 2.7,
                offsetLeftPerc: 2.3
            }),
            calcVal({
                heightPerc: 51,
                offsetTopPerc: 47,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 45,
                offsetTopPerc: 2.7,
                offsetLeftPerc: 51.9
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 47,
                offsetLeftPerc: 48
            })
        ]
    },
    {
        img: "/img/comic/sterling/Sterling_Layered_4_5.jpg",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 45,
                offsetTopPerc: 0,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 48.5,
                offsetTopPerc: 45,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 51,
                offsetTopPerc: 0,
                offsetLeftPerc: 49
            }),
            calcVal({
                heightPerc: 51.3,
                offsetTopPerc: 48.3,
                offsetLeftPerc: 48.7
            })
        ]
    },
    {
        img: "/img/comic/sterling/Sterling_Layered_6_7.jpg",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 22,
                offsetTopPerc: 2.2,
                offsetLeftPerc: 2.2
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 46,
                offsetTopPerc: 54,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 50
            }),
            calcVal({
                heightPerc: 65,
                offsetTopPerc: 35,
                offsetLeftPerc: 35
            }),
            DEFAULT_VIEW
        ]
    }
];
