const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const maraudingComic = [
    {
        img: "/img/comic/marauding/Marauding_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 44,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/marauding/Marauding_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 44,
                offsetTopPerc: 0,
                offsetLeftPerc: 26
            }),
            calcVal({
                heightPerc: 24,
                offsetTopPerc: 45,
                offsetLeftPerc: 28.5
            }),
            calcVal({
                heightPerc: 22,
                offsetTopPerc: 47.5,
                offsetLeftPerc: 54
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/marauding/Marauding_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 42,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 28,
                offsetTopPerc: 29,
                offsetLeftPerc: 33
            }),
            calcVal({
                heightPerc: 43.5,
                offsetTopPerc: 57,
                offsetLeftPerc: 28
            })
        ]
    },
    {
        img: "/img/comic/marauding/Marauding_04_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 0
            }),
            calcVal({
                heightPerc: 30,
                offsetTopPerc: 16,
                offsetLeftPerc: 63
            }),
            calcVal({
                heightPerc: 28,
                offsetTopPerc: 40,
                offsetLeftPerc: 55
            }),
            calcVal({
                heightPerc: 44.5,
                offsetTopPerc: 55,
                offsetLeftPerc: 52.5
            })
        ]
    },
    {
        img: "/img/comic/marauding/Marauding_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 43,
                offsetTopPerc: 17,
                offsetLeftPerc: 29
            }),
            calcVal({
                heightPerc: 22,
                offsetTopPerc: 61,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 62,
                offsetLeftPerc: 40
            })
        ]
    },
    {
        img: "/img/comic/marauding/Marauding_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 28,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 35,
                offsetTopPerc: 2,
                offsetLeftPerc: 37
            }),
            calcVal({
                heightPerc: 43.5,
                offsetTopPerc: 30,
                offsetLeftPerc: 28
            }),
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 64,
                offsetLeftPerc: 30
            })
        ]
    },
    {
        img: "/img/comic/marauding/Marauding_08.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 23,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 27.7,
                offsetTopPerc: 6.5,
                offsetLeftPerc: 43.8
            }),
            calcVal({
                heightPerc: 60,
                offsetTopPerc: 38,
                offsetLeftPerc: 20
            })
        ]
    }
];
