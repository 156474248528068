const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({
    heightPerc,
    offsetTopPerc,
    offsetLeftPerc
}: {
  heightPerc: number;
  offsetTopPerc: number;
  offsetLeftPerc: number;
}) => {
    return {
        width: ((heightPerc * comicHeight) / 100) * aspectRatio,
        height: (heightPerc * comicHeight) / 100,
        offsetTop: (offsetTopPerc * comicHeight) / 100,
        offsetLeft: (offsetLeftPerc * comicWidth) / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const dontShootComic = [
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 2,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 0,
                offsetLeftPerc: 30
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 58,
                offsetLeftPerc: 30
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 35,
                offsetTopPerc: 0,
                offsetLeftPerc: 30
            }),
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 40,
                offsetLeftPerc: 30
            }),
            calcVal({
                heightPerc: 55,
                offsetTopPerc: 65,
                offsetLeftPerc: 35
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 33,
                offsetTopPerc: 0,
                offsetLeftPerc: 35
            }),
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 33,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 60,
                offsetLeftPerc: 35
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_08.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/dont_shoot/Dont_Shoot_09.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 45,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 46.5,
                offsetLeftPerc: 25
            })
        ]
    }
];
