import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ContentViewport from "../../components/ContentViewport";
import useFadeIn from "../../hooks/useFadeIn";
import { COVER_METADATA } from "../coverMetadata/coverMetadataService";
import { selectUserCoverTokenIds } from "../coverMetadata/coverMetadataSlice";
import { EY3K0N_METADATA } from "../ey3k0nMetadata/ey3k0nMetadataService";
import { selectUserEy3k0nTokenIds } from "../ey3k0nMetadata/ey3k0nMetadataSlice";
import { selectWeb3Token } from "../metamask/metamaskSlice";
import CardSelector from "./CardSelector";
import { getCanIncubateCovers, getCanIncubateEy3k0ns, getIncubateResultImage, selectCanIncubateCoverIds, selectCanIncubateEy3k0nIds, selectIncubateResult, selectUserCanIncubateCoverMetadata, selectUserCanIncubateEy3k0nMetadata } from "./incubateSlice";
import {incubatePill} from "../metamask/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IncubateScreen = (props: any) => {
    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);
    const toastParams = {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        className: "incubate-toast",
        bodyClassName: "incubate-toast-body"
    };
    const toastSuccess = (message: string) => toast.success(message, toastParams as any);
    const toastError = (message: string) => toast.error(message, toastParams as any);

    const [showIncubateDesc, setShowIncubateDesc] = useState(false);
    const toggleShowIncubateDesc = () => {
        setShowIncubateDesc(!showIncubateDesc);
    };

    // Redux section
    const dispatch = useAppDispatch();
    const web3Token = useAppSelector(selectWeb3Token);
    const userEy3k0nTokenIds = useAppSelector(selectUserEy3k0nTokenIds);
    const userCoverTokenIds = useAppSelector(selectUserCoverTokenIds);

    useEffect(() => {
        if (web3Token && userEy3k0nTokenIds.length > 0) {
            dispatch(getCanIncubateEy3k0ns(userEy3k0nTokenIds));
        }
        if (web3Token && userCoverTokenIds.length > 0) {
            dispatch(getCanIncubateCovers(userCoverTokenIds));
        }
    }, [web3Token, userEy3k0nTokenIds]);

    const [selectedCharacter, setSelectedCharacter] = useState(0);
    const [selectedCover, setSelectedCover] = useState(0);

    const userCanIncubateEy3k0nTokenIds = useAppSelector(selectCanIncubateEy3k0nIds);
    const userCanIncubateCoverTokenIds = useAppSelector(selectCanIncubateCoverIds);
    const userEy3k0nMetadata = useAppSelector(selectUserCanIncubateEy3k0nMetadata);
    const userCoverMetadata = useAppSelector(selectUserCanIncubateCoverMetadata);

    const RenderEy3k0n = (props: {
        item: {
            tokenId: number, metadata: EY3K0N_METADATA
        }
    }) => {
        const tokenId = props.item.tokenId;
        const metadata = props.item.metadata;
        return (
            <div className="incubate-ey3k0n-card-wrapper">
                <div className="incubate-ey3k0n-card-title">
                    {metadata.name}
                    {/* <div className="incubate-ey3k0n-card-tokenid">
                        {`#${tokenId}`}
                    </div> */}
                </div>
                <div className="incubate-ey3k0n-card-body">
                    <div className="incubate-ey3k0n-card-img"
                        style={{ background: `url('${metadata.image}') center/contain no-repeat` }}/>
                </div>
            </div>
        );
    };

    const RenderCover = (props: {
        item: {
            tokenId: number, metadata: COVER_METADATA
        }
    }) => {
        const tokenId = props.item.tokenId;
        const metadata = props.item.metadata;
        return (
            <div className="incubate-cover-card-wrapper">
                <div className="incubate-cover-card-title">
                    {metadata.name}
                    {/* <div className="incubate-cover-card-tokenid">
                        {`#${tokenId}`}
                    </div> */}
                </div>
                <div className="incubate-cover-card-body">
                    <div className="incubate-cover-card-img"
                        style={{ background: `url('${metadata.image}') center/contain no-repeat` }}/>
                </div>
            </div>
        );
    };

    const incubateResult = useAppSelector(selectIncubateResult);

    /* States to validate can incubate */
    const [incubatedEy3k0ns, setIncubatedEy3k0ns] = useState<number[]>([]);
    const addIncubatedEy3k0n = (tokenId: number) => {
        setIncubatedEy3k0ns([...incubatedEy3k0ns, tokenId]);
    };
    const [incubatedCovers, setIncubatedCovers] = useState<number[]>([]);
    const addIncubatedCover = (tokenId: number) => {
        setIncubatedCovers([...incubatedCovers, tokenId]);
    };
    const [isIncubating, setIsIncubating] = useState<boolean>(false);

    const canIncubate = () => {
        if (userEy3k0nMetadata.length === 0) {
            return false;
        }
        if (userCoverMetadata.length === 0) {
            return false;
        }
        if (incubatedEy3k0ns.includes(userCanIncubateEy3k0nTokenIds[selectedCharacter])) {
            return false;
        }
        if (incubatedCovers.includes(userCanIncubateCoverTokenIds[selectedCover])) {
            return false;
        }
        if (isIncubating) {
            return false;
        }
        return true;
    };

    return (
        <ContentViewport>
            <div ref={sectionRef} className={"section"}>
                <div id="sec-incubate">
                    <div className="incubate-container">
                        <ToastContainer />
                        <div className="incubate-wrapper">
                            <div className="incubate-desc-wrapper">
                                <div className="incubate-desc-btn sq-btn" onClick={() => toggleShowIncubateDesc()}>
                                    INFO
                                </div>
                                <div className={`incubate-desc-text ${showIncubateDesc ? "show-incubate-desc" : ""}`}>
                                    {`To incubate your access pass, ensure you are connected to Metamask, and that your favourite EY3K0N First Edition NFT and access pass are in the same wallet. Select the EY3K0N you wish to incubate (for example, Indira) then press ‘Incubate’ and let the games begin.

                                    Incubation cannot be undone, so choose your character wisely! 
                                    Good luck AG3NT, you’re going to need it!`}
                                </div>
                            </div>
                            <div className="incubate-selections-container">
                                <div className="incubate-selections-wrapper">
                                    <div className="incubate-select-character">
                                        <CardSelector
                                            items={userEy3k0nMetadata}
                                            selectedIdx={selectedCharacter}
                                            setSelectedIdx={setSelectedCharacter}
                                            renderItem={RenderEy3k0n}/>
                                    </div>
                                    <div className="incubate-select-cover">
                                        <CardSelector
                                            items={userCoverMetadata}
                                            selectedIdx={selectedCover}
                                            setSelectedIdx={setSelectedCover}
                                            renderItem={RenderCover}/>
                                    </div>
                                    <div className="incubate-result-wrapper">
                                        <div className="incubate-result-outer">
                                            <div className="incubate-result-inner">
                                                <div />
                                                <div className="incubate-result-img-wrapper">
                                                    <div className="incubate-result"
                                                        style={{
                                                            background: `url('${incubateResult}') center/contain no-repeat`
                                                        }} />
                                                </div>
                                            </div>
                                            <div />
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="incubate-btn-wrapper">
                                    <div className={`incubate-btn sq-btn ${canIncubate() ? "" : "disabled"}`}
                                        onClick={() => {
                                            setIsIncubating(true);
                                            incubatePill(web3Token as string, userCanIncubateEy3k0nTokenIds[selectedCharacter], userCanIncubateCoverTokenIds[selectedCover])
                                                .then(() => {
                                                    // success incubation
                                                    toastSuccess(`incubated ${userCanIncubateEy3k0nTokenIds[selectedCharacter]} ey3k0n with cover: ${userCanIncubateCoverTokenIds[selectedCover]}`);

                                                    // Update incubated items
                                                    addIncubatedCover(userCanIncubateCoverTokenIds[selectedCover]);
                                                    addIncubatedEy3k0n(userCanIncubateEy3k0nTokenIds[selectedCharacter]);

                                                    // RESULT should be populated with the URL found at the "image" key, form this API call (that should be done AFTER success incubation):
                                                    // https://anthology.ey3k0n.io/metadata/${coverId}
                                                    dispatch(getIncubateResultImage(userCanIncubateCoverTokenIds[selectedCover]));
                                                })
                                                .catch((err) => {
                                                    // fail incubation
                                                    // toastError(err.toString());
                                                    console.log(err.toString());
                                                    toastError("System temporarily busy, please try again later");
                                                })
                                                .finally(() => {
                                                    setIsIncubating(false);
                                                })
                                            ;
                                        }}
                                    >INCUBATE</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
};

export default IncubateScreen;
