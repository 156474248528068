/* eslint-disable no-unused-vars */
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UtilState {
    showKeypad: boolean;
}

const initialState: UtilState = {
    showKeypad: false
};

export const utilSlice = createSlice({
    name: "util",
    initialState,
    reducers: {
        setShowKeypad: (state: Draft<UtilState>, action: PayloadAction<boolean>) => {
            state.showKeypad = action.payload;
        }
    }
});

export const {
    setShowKeypad
} = utilSlice.actions;

export const selectShowKeypad = (state: RootState) => state.util.showKeypad;
export default utilSlice.reducer;
