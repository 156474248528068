import { app } from "./init";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions(app, "us-central1");

// export const addToMailingList = httpsCallable(functions, "app/add-to-mailing-list");
export const mailchimpAddMailingList = httpsCallable(functions, "mailchimp-mailchimpUpdateMailingList");

// Incubation
export const incubateVerifyPin = httpsCallable(functions, "incubate-verifyPin");
export const incubateVerifyStage2 = httpsCallable(functions, "incubate-verifyStage2");
export const incubateVerifyStage3 = httpsCallable(functions, "incubate-verifyStage3");
export const incubateVerifyStage4 = httpsCallable(functions, "incubate-verifyStage4");
export const incubateVerifyStage5 = httpsCallable(functions, "incubate-verifyStage5");
export const incubateVerifyStage6 = httpsCallable(functions, "incubate-verifyStage6");
export const incubateVerifyStage7 = httpsCallable(functions, "incubate-verifyStage7");
export const incubateVerifyStage8 = httpsCallable(functions, "incubate-verifyStage8");
export const incubateVerifyStage9 = httpsCallable(functions, "incubate-verifyStage9");

// Authentication
export const verifyEmailAndPassword = httpsCallable(functions, "authentication-verifyEmailAndPassword");
