import React, { useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import MetamaskScreen from "../metamask/MetamaskScreen";
import ProtectedRoute from "../utils/ProtectedRoute";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { selectWalletConnected } from "../metamask/metamaskSlice";

import IncubateVideo from "./IncubateVideo";
import IncubateScreen from "./IncubateScreen";
import KeyPadScreen from "./KeyPadScreen";
import { selectHasIncubatePerms } from "./incubateSlice";

const IncubateLandingScreen = (props:any) => {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.INCUBATE));
        body.setClasses(["sec-incubate"]);
    }, []);

    const hasIncubatePerms = useAppSelector(selectHasIncubatePerms);

    const walletConnected = useAppSelector(selectWalletConnected);
    const metamaskPermissionConditions = [walletConnected];
    const hasMetamaskPermission = (): boolean => {
        return walletConnected;
    };

    return (
        <div ref={sectionRef} className="section">
            <Routes>
                <Route path="/"
                    element={
                        <ProtectedRoute
                            hasPermission={hasMetamaskPermission}
                            permissionConditions={metamaskPermissionConditions}
                            noPermsUrl="/incubate/connect">
                            <IncubateScreen />
                        </ProtectedRoute>
                    }/>
                <Route path="/video"
                    element={
                        <ProtectedRoute
                            hasPermission={() => hasIncubatePerms}
                            permissionConditions={[hasIncubatePerms]}
                            noPermsUrl="/incubate/keypad">
                            <IncubateVideo />
                        </ProtectedRoute>
                    }/>
                <Route path="/keypad"
                    element={<KeyPadScreen />}/>
                <Route path="/connect"
                    element={
                        <ProtectedRoute
                            hasPermission={() => hasIncubatePerms}
                            permissionConditions={[hasIncubatePerms]}
                            noPermsUrl="/incubate/keypad">
                            <MetamaskScreen
                                hasPermission={hasMetamaskPermission}
                                targetBtnText="INCUBATE"
                                targetUrl="/incubate/"
                                autoNavigate={false}
                                requireSign={true}
                                permissionConditions={metamaskPermissionConditions} />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </div>
    );
};

export default IncubateLandingScreen;
