import { RootState } from "../../app/store";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RadioStatus } from "./RadioStatus";

export interface RadioState {
    volume: number;
    status: RadioStatus;
    track: any;
}

const initialState: RadioState = {
    volume: 50,
    status: RadioStatus.INITIAL,
    track: {}
};

export const radioSlice = createSlice({
    name: "radio",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        radio_setVolume: (state: Draft<RadioState>, action: PayloadAction<number>) => {
            state.volume = action.payload;
            // radio.setVolume(state.volume);
        },
        radio_pause: (state: Draft<RadioState>, action: PayloadAction<number>) => {
            state.status = (RadioStatus.PAUSED);
        },
        radio_playToggle: (state: Draft<RadioState>) => {
            if (state.status === RadioStatus.PLAYING) {
                state.status = (RadioStatus.PAUSED);
            } else if (state.status === RadioStatus.PAUSED || RadioStatus.INITIAL) {
                state.status = RadioStatus.PLAYING;
            }
        },
        setTrack: (state: Draft<RadioState>, action: PayloadAction<any>) => {
            state.track = action.payload;
        },
        setRadioStatus (state: Draft<RadioState>, action: PayloadAction<RadioStatus>) {
            if (state.status !== action.payload) {
                state.status = action.payload;
            }
        },
        onSongChanged: (state: Draft<RadioState>, action: PayloadAction<any>) => {
            const { payload } = action;
            if (state.track.artwork !== payload.trackArtwork) {
                state.track.artwork = payload.trackArtwork;
            }

            if (state.track.name !== payload.trackTitle) {
                if (payload.trackTitle) {
                    state.track.title = payload.trackTitle;
                } else {
                    state.track.title = "Unnamed Track";
                }
            }
        }
    }
});

// export const selectRadioStatus = (state: RootState) => state.radio.status;
export const selectRadioStatus = (state: RootState): RadioStatus => state.radio.status;
export const selectTrackArtwork = (state: RootState) => state.radio.track?.artwork;
export const selectTrackTitle = (state: RootState) => state.radio.track?.title;
// eslint-disable-next-line camelcase
export const { setRadioStatus, onSongChanged, radio_setVolume, radio_playToggle } = radioSlice.actions;
export default radioSlice.reducer;
