import axios from "axios";
const ANTHOLOGY_BASE_URI = "https://ey3k0n-validator.mbntech.ro/api";

/* FOR TESTING */
// const ANTHOLOGY_BASE_URI = "http://localhost:3001/api";

export const incubatePillStage2 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_2`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage3 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_3`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage4 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_4`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage5 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_5`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage6 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_6`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage7 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_7`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage8 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_8`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};

export const incubatePillStage9 = async (web3Token: string, comicId: number): Promise<any> => {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/stage_9`, {
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
};
