import React, { useEffect, useRef } from "react";
// import ComingSoonSection from "./ComingSoonSection";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { turnDeviceOn } from "../device-ui/deviceUISlice";
import useBodyClasses from "../../hooks/useBodyCasses";
import { mintingSectionEnabledSelector } from "../feature-switches/featureSwitchSlice";
import MintingNested from "./MintingNested";
import useFadeIn from "../../hooks/useFadeIn";

function MintLandingScreen (props: any) {
    const dispatch = useAppDispatch();
    const bodyClasses = useBodyClasses();
    const mintingEnabled = useAppSelector(mintingSectionEnabledSelector);

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
        bodyClasses.setClasses([]);
    }, []);

    return (
        <div>
            {/* { (!mintingEnabled) && <ComingSoonSection /> } */}
            { !mintingEnabled && <MintingNested /> }
        </div>
    );
}

export default MintLandingScreen;
