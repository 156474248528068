import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import deviceUIReducer from "../features/device-ui/deviceUISlice";
import transformsReducer from "../features/transforms/transformsSlice";
import backgroundContainerReducer from "../features/background/backgroundSlice";
import radioReducer from "../features/radio/radioSlice";
import featureSwitchReducer from "../features/feature-switches/featureSwitchSlice";
import mintingReducer from "../features/minting/mintingSlice";
import metamaskReducer from "../features/metamask/metamaskSlice";
import comicReducer from "../features/comic/comicSlice";
import shopifyReducer from "../features/shopify/shopifySlice";
import ey3k0nMetadataSlice from "../features/ey3k0nMetadata/ey3k0nMetadataSlice";
import incubateSlice from "../features/incubate/incubateSlice";
import coverMetadataSlice from "../features/coverMetadata/coverMetadataSlice";
import utilSlice from "../features/utils/utilSlice";
import authSlice from "../features/authentication/authSlice";

export const store = configureStore({
    reducer: {
        deviceUI: deviceUIReducer,
        transforms: transformsReducer,
        background: backgroundContainerReducer,
        radio: radioReducer,
        featureSwitches: featureSwitchReducer,
        minting: mintingReducer,
        metamask: metamaskReducer,
        comic: comicReducer,
        shopify: shopifyReducer,
        ey3k0nMetadata: ey3k0nMetadataSlice,
        coverMetadata: coverMetadataSlice,
        incubate: incubateSlice,
        util: utilSlice,
        auth: authSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ["deviceUI/showCloseButton", "radio/set-events/fulfilled", "radio/onSongChanged"],
            ignoredPaths: ["deviceUI.closeButtonAction", "ey3k0nMetadata.metadata"]
        }
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
