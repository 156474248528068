import React from "react";

function RoadmapScreen1 (props: any) {
    const _classNames = props.classNames || [];
    const classNames = ["rm-page", ..._classNames].join(" ");
    return (
        <div id="rm-page-1" className={classNames}>
            <h2 className="mp-title">0N3</h2>
            <div className="mp-entries">
                <div className="mp-entry mp-checked">
                    <div className="mpe-title">GALLERY - LIVE</div>
                    <div className="mpe-body">Discover a universe of iconic characters in the interactive gallery. From future-fashion to cyber- enhancement trends. Find your EY3KON.</div>
                </div>
                <div className="mp-entry">
                    <div className="mpe-title">RADIO - In Progress</div>
                    <div className="mpe-body">
                        Tune in to NODE FM RADIO for an in-world audio experience. Keep an ear out for news, clues, updates, AMA's, exclusive DJ sets and a mashup of future sounds.
                    </div>
                </div>
                <div className="mp-entry">
                    <div className="mpe-title">GAME - In Design</div>
                    <div className="mpe-body">
                        We're bringing together some of the world's top creative minds to reimagine the future of immersive storytelling. Surprises in store. Keep an EY3 out for the breadcrumbs.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoadmapScreen1;
