import React, { MutableRefObject, useEffect, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import {
    selectIntroVideoActive,
    selectShowBackgroundCharacters, selectSkullVideoActive, selectTransitionVideoActive
} from "./backgroundSlice";
import { getTransformData } from "../transforms/getTransformData";
import SkullVideo from "./SkullVideo";

interface BackgroundCharactersProps {
    introVideoRef: MutableRefObject<any>;
    introVideoContainerRef: MutableRefObject<any>;
    transitionVideoRef: MutableRefObject<any>;
    skullVideoRef: MutableRefObject<any>;
    backgroundContainer: MutableRefObject<any>;
}

function BackgroundContainer (props: BackgroundCharactersProps) {
    const bgCharContainer = props.backgroundContainer;
    const showCharacters = useAppSelector(selectShowBackgroundCharacters);
    const transforms = useAppSelector(getTransformData);
    const _transitionVideoActive = useAppSelector(selectTransitionVideoActive);
    const _introVideoActive = useAppSelector(selectIntroVideoActive);
    const skullVideoActive = useAppSelector(selectSkullVideoActive);

    const cht = 1000;
    const gct = 100;
    const bgcharanim = [{ cls: "g2", t: gct }, { cls: "g3", t: gct }, { cls: "c1", t: cht }, { cls: "c2", t: cht }, { cls: "c3", t: cht }, { cls: "c4", t: cht }, { cls: "c5", t: cht }, { cls: "c6", t: cht }, { cls: "g1", t: gct }];

    const bgTimeoutFadeOutId = useRef() as any;
    const bgTimeoutUpdateId = useRef() as any;

    const bgcharidx = useRef(-1);

    useEffect(() => {
        if (showCharacters) {
            start();
        } else {
            stop();
        }
        return () => stop();
    }, [showCharacters]);

    useEffect(() => {
        props.transitionVideoRef.current.classList.toggle("active", _transitionVideoActive);
        props.introVideoRef.current.classList.toggle("active", _introVideoActive);
        props.introVideoContainerRef.current.classList.toggle("active", _introVideoActive);
    }, [_transitionVideoActive, _introVideoActive]);

    function stop () {
        bgCharContainer.current.classList.add("fade-out-char");
        bgTimeoutFadeOutId.current = setTimeout(() => {
            bgCharContainer.current.classList.remove("fade-out-char");
            resetCharacterBg();
            clearTimeout(bgTimeoutUpdateId.current);
        }, 500);
    }

    function start () {
        bgCharContainer.current.classList.remove("fade-out-char");
        clearTimeout(bgTimeoutUpdateId.current);
        clearTimeout(bgTimeoutFadeOutId.current);
        resetCharacterBg();
        bgcharidx.current = -1;
        updateCharacterBg();
    }

    function resetCharacterBg () {
        bgcharanim.forEach(frame => {
            bgCharContainer.current.classList.remove(frame.cls);
        });
    }

    function updateCharacterBg () {
        if (bgcharidx.current >= 0) { bgCharContainer.current.classList.remove(bgcharanim[bgcharidx.current].cls); }

        bgcharidx.current = (bgcharidx.current + 1) % bgcharanim.length;
        bgCharContainer.current.classList.add(bgcharanim[bgcharidx.current].cls);

        bgTimeoutUpdateId.current = setTimeout(() => {
            updateCharacterBg();
        }, bgcharanim[bgcharidx.current].t);
    }

    return (
        <div ref={props.backgroundContainer} id="background" className="a100">
            <div id="bg-char-1" className="bg-chars"></div>
            <div id="bg-char-2" className="bg-chars"></div>
            {skullVideoActive && <SkullVideo/>}
            <div id="background-videos" className="s2c" style={{
                transform: transforms.s2c
            }}>
                <div ref={props.transitionVideoRef} id="bgvid-trans" className="a100 bgvid-wrap">
                    <video playsInline muted preload="auto">
                        <source src="/videos/Loop-med-bit.mp4" type="video/mp4" />
                    </video>
                </div>
                <div ref={props.introVideoContainerRef} id="bgvid-intro" className="a100 bgvid-wrap">
                    <video ref={props.introVideoRef} playsInline muted preload="auto">
                        <source src="/videos/Intro-med-bit.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div id="bg-vice-viewport" className="a100">
                <div id="bg-vice-container" className="aHD s2ht5050" style={{
                    transform: transforms.s2ht5050
                }}>
                    <div id="bg-vice"></div>
                </div>
            </div>
        </div>
    );
}

export default BackgroundContainer;
