import axios from "axios";
import { _mint, getProvider } from "./ey3k0nContract";
import { Buffer } from "buffer";
import { useAppSelector } from "../../../app/hooks";
import { selectWeb3Token } from "../metamaskSlice";

export interface MintArgs {
    address: string;
    web3Token: string;
    quantity: number;
    NFTPrice: string;
    gasLimit?: number;
    contractAddress: string;
}

let baseURI: string = "";

const ANTHOLOGY_BASE_URI = "https://ey3k0n-validator.mbntech.ro/api";

export async function mintNFT (args: MintArgs) {
    if (!args.address) {
        throw new Error("args.address is required");
    }
    if (!args.quantity) {
        throw new Error("args.quantity is required");
    }
    if (!args.web3Token) {
        throw new Error("args.web3Token is required");
    }
    if (!args.NFTPrice) {
        throw new Error("args.NFTPrice is required");
    }
    if (!args.contractAddress) {
        throw new Error("args.contractAddress is required");
    }

    // check balance already minted
    /*
    const currentBalance = await tokenBalanceOf(args.address, args.contractAddress);
        if (currentBalance >= 2) {
            throw new Error("HAS_FULL_ALLOCATION");
        }
    */

    // attempt to mint
    const response = await getApprovalSignature(args.web3Token);
    await _mint({
        address: args.address,
        quantity: args.quantity,
        issued: response.issued,
        signature: response.signature,
        expiration: response.expiration,
        NFTPrice: args.NFTPrice,
        gasLimit: args.gasLimit,
        contractAddress: args.contractAddress
    });

    return true;
}

export async function whitelistMintNFT (args: MintArgs) {
    if (!args.web3Token) {
        throw new Error("WEB3_TOKEN_REQUIRED");
    }
    if (!args.quantity) {
        throw new Error("args.quantity is required");
    }
    if (!args.address) {
        throw new Error("args.address is required");
    }
    if (!args.NFTPrice) {
        throw new Error("args.NFTPrice is required");
    }
    if (!args.contractAddress) {
        throw new Error("args.contractAddress is required");
    }
    // check if on the whitelist
    const whitelisted = await isWhitelisted(args.web3Token);
    if (!whitelisted) {
        throw new Error("NOT_WHITELISTED");
    }
    return mintNFT(args);
}
export function setSignerAPIBaseURI (_baseURI: string) {
    baseURI = _baseURI;
}
function getBaseURI (): string {
    if (!baseURI) {
        throw new Error("baseURI is required");
    }
    return baseURI;
}

export async function isWhitelisted (web3Token: string) {
    if (!web3Token) {
        throw new Error("NO_WEB3_TOKEN");
    }
    const signerBaseURI = getBaseURI();
    const result = await axios.get(`${signerBaseURI}/whitelisted`, {
        headers: {
            "Web3-Token": web3Token
        }
    });
    console.log("WHITELIST RESPONSE", result.data);
    if (result.data === true) {
        return true;
    }
    if (result.data === "true") {
        return true;
    }

    return result.data.whitelisted_at !== null;
}

export async function getApprovalSignature (web3Token: string): Promise<any> {
    if (!web3Token) {
        throw new Error("WEB3_TOKEN_REQUIRED");
    }
    const signerBaseURI = getBaseURI();
    const result = await axios.get(`${signerBaseURI}/approval`, {
        headers: {
            "Web3-Token": web3Token
        }
    });
    console.log("getApprovalSignature", result.data);
    return result.data;
}

export async function getFilteredTokens (tokenIds: string[]): Promise<any> {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/filter_tokens`, tokenIds);
    return result.data;
}

export async function getFilteredPills (tokenIds: string[]): Promise<any> {
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/filter_comics`, tokenIds);
    return result.data;
}

export async function incubatePill (web3Token: string, ey3k0nId: number, comicId: number): Promise<any> {
    // continue
    const result = await axios.post(`${ANTHOLOGY_BASE_URI}/incubate`, {
        ey3k0nId,
        comicId
    }, {
        headers: {
            "Web3-Token": web3Token
        }
    });

    return result.data;
}

export async function getWeb3Token (text: string): Promise<string> {
    const nonce = Math.floor(Math.random() * 999999999999);
    const provider = await getProvider();
    const body = `${text}\n\nWeb3 Token Version: 2\nNonce: ${nonce}`;
    const signature = await provider.getSigner().signMessage(body);

    return Buffer.from(JSON.stringify({
        signature,
        body,
        nonce
    }), "utf-8").toString("base64");
}
