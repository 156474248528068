/* eslint-disable no-unused-vars */
import { RootState } from "../../app/store";
import { createAsyncThunk, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { EY3K0N_METADATA, ey3k0nMetadataService } from "./ey3k0nMetadataService";
import { getEy3k0nTokenIds } from "../metamask/api/ey3k0nContract";

enum ActionStatus {
    IDLE = "PENDING",
    PENDING = "PENDING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export interface Ey3k0nMetadataState {
    fetchingMetadataStatus: ActionStatus,
    userTokenIds: number[],
    metadata: Record<number, EY3K0N_METADATA>
}

const initialState: Ey3k0nMetadataState = {
    fetchingMetadataStatus: ActionStatus.IDLE,
    userTokenIds: [],
    metadata: {}
};

export const fetchEy3k0nMetadata = createAsyncThunk(
    "ey3k0nMetadata/fetchEy3k0nMetadata",
    async (tokenId: number, thunkApi): Promise<{ tokenId: number, metadata: EY3K0N_METADATA | null}> => {
        const state: any = thunkApi.getState();
        return {
            tokenId: tokenId,
            metadata: await ey3k0nMetadataService.getEy3k0nMetadata(tokenId)
        };
    }
);

export const fetchUserEy3k0nMetadata = createAsyncThunk(
    "ey3k0nMetadata/fetchUserEy3k0nMetadata",
    async (walletAddress: string, thunkApi): Promise<Record<number, EY3K0N_METADATA>> => {
        const state: any = thunkApi.getState();
        const tokenIds = await getEy3k0nTokenIds(walletAddress, state.metamask.contractAddress);
        // set user tokenIds
        thunkApi.dispatch(setUserTokenIds(tokenIds));

        const metadata = await Promise.all(tokenIds.map(async (tokenId) => {
            return await ey3k0nMetadataService.getEy3k0nMetadata(tokenId);
        }));

        const metadataRecord: Record<number, EY3K0N_METADATA> = {};
        for (let i = 0; i < tokenIds.length; i++) {
            const tokenData = metadata[i];
            if (tokenData) {
                metadataRecord[tokenIds[i]] = tokenData;
            };
        }

        return metadataRecord;
    }
);

export const ey3k0nMetadataSlice = createSlice({
    name: "ey3k0nMetadata",
    initialState,
    reducers: {
        setUserTokenIds: (state: Draft<Ey3k0nMetadataState>, action: PayloadAction<number[]>) => {
            state.userTokenIds = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchEy3k0nMetadata.pending, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.PENDING;
        }).addCase(fetchEy3k0nMetadata.rejected, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.ERROR;
        }).addCase(fetchEy3k0nMetadata.fulfilled, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.SUCCESS;
            const tokenId: number = action.payload.tokenId;
            const metadata: EY3K0N_METADATA | null = action.payload.metadata;
            if (metadata) {
                const newMetadata = { ...state.metadata };
                newMetadata[tokenId] = metadata;
                state.metadata = newMetadata;
            }
        });

        builder.addCase(fetchUserEy3k0nMetadata.pending, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.PENDING;
        }).addCase(fetchUserEy3k0nMetadata.rejected, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.ERROR;
        }).addCase(fetchUserEy3k0nMetadata.fulfilled, (state, action) => {
            state.fetchingMetadataStatus = ActionStatus.SUCCESS;
            const newMetadata: Record<number, EY3K0N_METADATA> = { ...state.metadata };
            const userMetadata = action.payload;
            for (const id in userMetadata) {
                newMetadata[id] = userMetadata[id];
            };
            state.metadata = newMetadata;
        });
    }
});
export const {
    setUserTokenIds
} = ey3k0nMetadataSlice.actions;

export const selectFetchingMetadataStatus = (state: RootState) => state.ey3k0nMetadata.fetchingMetadataStatus;
export const selectAllEy3k0nMetadata = (state: RootState) => state.ey3k0nMetadata.metadata;
export const selectUserEy3k0nTokenIds = (state: RootState) => state.ey3k0nMetadata.userTokenIds;
export const selectUserEy3k0nTokenMetadata = (state: RootState) => {
    const userTokenMetadata: {tokenId: number, metadata: EY3K0N_METADATA}[] = [];
    state.ey3k0nMetadata.userTokenIds.forEach((tokenId: number) => {
        if (state.ey3k0nMetadata.metadata[tokenId]) {
            userTokenMetadata.push({
                tokenId: tokenId,
                metadata: state.ey3k0nMetadata.metadata[tokenId]
            });
        }
    });
    return userTokenMetadata;
};

export default ey3k0nMetadataSlice.reducer;
