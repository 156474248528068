import React from "react";

interface DropDownProps {
    selected: string;
    options: string[];
    optionCallback: (option: string) => void;
}

const Dropdown = (props: DropDownProps) => {
    return (
        <div className="utils-type-dropdown-wrapper">
            <div className="utils-type-dropdown-selected">
                <div className="utils-selected-label">{props.selected}</div>
                <div className="utils-type-dropdown-arrow" />
            </div>
            <div className="utils-type-dropdown-options">
                {
                    props.options.map((itemName: string, index: number) => {
                        return (
                            <div key={`${index}-${itemName}`} onClick={() => props.optionCallback(itemName)}>{itemName.toUpperCase()}</div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Dropdown;
