import React, { useEffect, useRef, useState } from "react";
import Ey3konCard from "./Ey3konCard";
import { siteData } from "./data";
import ContentViewport from "../../components/ContentViewport";
import { useAppDispatch } from "../../app/hooks";
import { hideCloseButton, setActiveButton, showCloseButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";

function Ey3k0nScreen (props: any) {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();
    const [shiftPos, setShiftPos] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [expandedAlternative, setExpandedAlternative] = useState("false");
    const [currentIndex, setCurrentIndex] = useState("");
    const totalCards = 10;
    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    const setCurrentIndexValue = (index: number) => {
        const value = ((11 - index) % totalCards);
        if (value === 0) {
            setCurrentIndex("10");
        } else {
            setCurrentIndex(value.toString().padStart(2, "0"));
        }
    };

    const next = () => {
        const nextCount = (shiftPos + 1) % totalCards;
        setShiftPos(nextCount);
        setCurrentIndexValue(nextCount);
    };

    const previous = () => {
        let previousCount = shiftPos - 1;
        if (previousCount < 0) {
            previousCount = totalCards - 1;
        }
        setShiftPos(previousCount);
        setCurrentIndexValue(previousCount);
    };

    function teardown () {
        dispatch(hideCloseButton());
        body.setClasses([]);
    }

    useEffect(() => {
        body.setClasses(["sec-vice"]);
        dispatch(setActiveButton(DeviceUIButton.EY3K0NS));
        dispatch(turnDeviceOn());
        setCurrentIndexValue(0);
        return teardown;
    }, []);

    const elementClicked = () => {
        setExpanded(true);
        dispatch(showCloseButton(onItemClosed));
        body.setClasses(["sec-vice", "vice-expanded"]);
    };

    const onItemClosed = () => {
        setExpandedAlternative("active");
        setExpanded(false);
        body.setClasses(["sec-vice"]);
        dispatch(hideCloseButton());
    };

    const viceCards = siteData.viceCards;

    const eys = [
        { letter: "G", card: viceCards.Koba },
        { letter: "H", card: viceCards.Sterling },
        { letter: "I", card: viceCards.BadBunny },
        { letter: "K", card: viceCards.Omni },
        { letter: "A", card: viceCards.Coco },
        { letter: "B", card: viceCards.SuperSimian },
        { letter: "C", card: viceCards.Botika },
        { letter: "D", card: viceCards.KingChrome },
        { letter: "E", card: viceCards.Indira },
        { letter: "F", card: viceCards.TeamMars }
    ];
    const viceCardElements = eys.map((item: any, index: number) => {
        const vpcNumber = (shiftPos + index + 1) % totalCards;
        const vcTarget = vpcNumber === 5 ? "vc-target" : "";
        return (<div key={index} onClick={elementClicked} id={`vc-${item.letter}`} className={`vc-card-pivot vcp-${vpcNumber} ${vcTarget}`}><Ey3konCard key={index} eyekon={item.card} /></div>);
    });
    const classExpanded = expanded ? "active expanded" : expandedAlternative;
    return (
        <ContentViewport>
            <div ref={sectionRef} id="sec-vice" className={`section ${classExpanded}`}>
                <div id="vice-container" className="a100">
                    {viceCardElements}
                </div>
                <div id="vice-menu" className="shift-menu">
                    <div id="vm-label" className="sm-label">{currentIndex} - 10</div>
                    <div className="greenblue-csq-decor act"></div>
                    <div id="vm-lb-pvt" className="sm-btn-pvt smbp-l" onClick={next}>
                        <div id="vm-left-btn" className="sm-btn smb-l">
                            <div className="btn-frm"></div>
                            <div className="btn-icn"></div>
                        </div>
                    </div>
                    <div id="vm-rb-pvt" className="sm-btn-pvt smbp-r" onClick={previous}>
                        <div id="vm-right-btn" className="sm-btn smb-r">
                            <div className="btn-frm"></div>
                            <div className="btn-icn"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
}

export default Ey3k0nScreen;
