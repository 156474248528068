import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectTransforms } from "../transforms/transformsSlice";
import { useSelector } from "react-redux";
import { selectCloseButtonAction, selectCloseButtonVisible } from "./deviceUISlice";

function CloseButton (props: any) {
    const transforms = useAppSelector(selectTransforms);
    const closeButtonVisible = useSelector(selectCloseButtonVisible);
    const closeButtonAction = useSelector(selectCloseButtonAction);
    const closeBtnRef: any = useRef();
    const timeoutId: any = useRef();

    function show () {
        clearTimeout(timeoutId.current);
        closeBtnRef.current.classList.toggle("hidden", false);
        timeoutId.current = setTimeout(() => {
            closeBtnRef.current.classList.toggle("active", true);
        }, 1000);
        return () => clearTimeout(timeoutId.current);
    }

    function hide () {
        const _el = closeBtnRef.current;
        clearTimeout(timeoutId.current);

        _el.classList.toggle("fadeOut", true);
        _el.classList.toggle("active", false);
        timeoutId.current = setTimeout(() => {
            _el.classList.toggle("fadeOut", false);
            _el.classList.toggle("hidden", true);
        }, 1000);
        return () => clearTimeout(timeoutId.current);
    }

    useEffect(() => {
        if (closeButtonVisible) {
            show();
        } else {
            hide();
        }
    }, [closeButtonVisible]);

    return (
        <div id="close-button-container" className="s2ht5050" style={{ transform: transforms.s2ht5050 }}>
            <div ref={closeBtnRef} id="close-button" className="close-button hidden" onClick={() => {
                if (closeButtonAction) {
                    closeButtonAction();
                }
            }}></div>
        </div>

    );
}

export default CloseButton;
