import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface BackgroundState {
    showBackgroundCharacters: boolean;
    introVideoActive: boolean;
    transitionVideoActive: boolean;
    skullVideoActive: boolean;
    skullToggleClasses: string[];
}

const initialState: BackgroundState = {
    showBackgroundCharacters: false,
    introVideoActive: false,
    transitionVideoActive: false,
    skullVideoActive: false,
    skullToggleClasses: []
};

export const backgroundSlice = createSlice({
    name: "deviceUI",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        showBackgroundCharacters: (state: Draft<BackgroundState>) => {
            state.showBackgroundCharacters = true;
        },
        hideBackgroundCharacters: (state: Draft<BackgroundState>) => {
            state.showBackgroundCharacters = false;
        },
        introVideoActive: (state: Draft<BackgroundState>, action: PayloadAction<boolean>) => {
            state.introVideoActive = action.payload;
        },
        transitionVideoActive: (state: Draft<BackgroundState>, action: PayloadAction<boolean>) => {
            state.transitionVideoActive = action.payload;
        },
        clearSkullToggleClasses: (state: Draft<BackgroundState>) => {
            state.skullToggleClasses = [];
        },
        showSkullVideo: (state: Draft<BackgroundState>, action: PayloadAction<string[]>) => {
            state.skullToggleClasses = action.payload;
            state.skullVideoActive = true;
        },
        hideSkullVideo: (state: Draft<BackgroundState>) => {
            state.skullVideoActive = false;
            state.skullToggleClasses = [];
        }
    }
});

export const selectShowBackgroundCharacters = (state: RootState) => state.background.showBackgroundCharacters;
export const selectTransitionVideoActive = (state: RootState) => state.background.transitionVideoActive;
export const selectIntroVideoActive = (state: RootState) => state.background.introVideoActive;
export const selectSkullVideoActive = (state: RootState) => state.background.skullVideoActive;
export const selectSkullVideoToggleClasses = (state: RootState) => state.background.skullToggleClasses;

export const {
    showBackgroundCharacters,
    hideBackgroundCharacters,
    transitionVideoActive,
    introVideoActive,
    showSkullVideo,
    hideSkullVideo,
    clearSkullToggleClasses
} = backgroundSlice.actions;

export default backgroundSlice.reducer;
