import { shopifyClient } from "./init";
import { gql } from "@apollo/client";
import { Cart, CartLine, CartRes } from "./interfaces/cartInterface";

const cartQuery = `
                    cart {
                        id
                        checkoutUrl
                        lines(first: 10) {
                            edges {
                                node {
                                    id
                                    quantity
                                    merchandise {
                                        ... on ProductVariant {
                                            id
                                            quantityAvailable
                                            title
                                            product {
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        cost {
                            totalAmount {
                                amount
                                currencyCode
                            }
                            subtotalAmount {
                                amount
                                currencyCode
                            }
                            totalTaxAmount {
                                amount
                                currencyCode
                            }
                            totalDutyAmount {
                                amount
                                currencyCode
                            }
                        }
                    }`;

const extractCartLines = (cart: CartRes): CartLine[] => {
    const lines: CartLine[] = [];
    cart.lines.edges.forEach((line: any) => {
        const lineItem: Record<string, any> = {};
        lineItem.merchandiseId = line.node.merchandise.id;
        lineItem.id = line.node.id;
        lineItem.quantity = line.node.quantity;
        lineItem.title = line.node.merchandise.title;
        lineItem.quantityAvailable = line.node.merchandise.quantityAvailable;
        lineItem.productTitle = line.node.merchandise.product.title;
        lines.push(lineItem as CartLine);
    });
    return lines;
};

const getCart = (cart: CartRes): Cart => {
    const cartLines: CartLine[] = extractCartLines(cart);
    return {
        ...cart,
        lines: cartLines
    };
};

const getCartLine = (cart: Cart, merchandiseId: string): CartLine | undefined => {
    return cart.lines.find((line: CartLine) => line.merchandiseId === merchandiseId);
};

const getItemQtyInCart = (cart: Cart | null, merchandiseId: string): number => {
    if (!cart) return 0;
    const cartLine = getCartLine(cart, merchandiseId);
    if (!cartLine) return 0;
    return cartLine.quantity;
};

const createCart = async (): Promise<Cart> => {
    const query = `
            mutation {
                cartCreate(
                    input: {
                        lines: []
                        attributes: { key: "cart_attribute", value: "This is a cart attribute" }
                    }
                ) {
                    ${cartQuery}
                }
            }`;
    const data = await shopifyClient.mutate({
        mutation: gql`${query}`
    });
    return getCart(data.data.cartCreate.cart);
};

const addCartLine = async (cartId: string, merchandiseId: string): Promise<Cart> => {
    const query = `
            mutation {
                cartLinesAdd(
                    cartId: "${cartId}"
                    lines: {
                        merchandiseId: "${merchandiseId}",
                        quantity: 1
                    },
                ) {
                    ${cartQuery}
                }
            }
        `;
    const data = await shopifyClient.mutate({
        mutation: gql`${query}`
    });
    return getCart(data.data.cartLinesAdd.cart);
};

const removeCartLine = async (cartId: string, lineId: string): Promise<Cart> => {
    const query = `
            mutation {
                cartLinesRemove(
                    cartId: "${cartId}"
                    lineIds: ["${lineId}"],
                ) {
                    ${cartQuery}
                }
            }
        `;
    const data = await shopifyClient.mutate({
        mutation: gql`${query}`
    });
    return getCart(data.data.cartLinesRemove.cart);
};

const updateCartLine = async (
    cartId: string,
    lineId: string,
    merchandiseId: string,
    quantity: number): Promise<Cart> => {
    if (quantity === 0) {
        return removeCartLine(cartId, lineId);
    }
    const query = `
            mutation {
                cartLinesUpdate(
                    cartId: "${cartId}"
                    lines: {
                        id: "${lineId}",
                        merchandiseId: "${merchandiseId}",
                        quantity: ${quantity}
                    },
                ) {
                    ${cartQuery}
                }
            }
        `;
    const data = await shopifyClient.mutate({
        mutation: gql`${query}`
    });
    return getCart(data.data.cartLinesUpdate.cart);
};

export const cartService = {
    createCart,
    addCartLine,
    removeCartLine,
    updateCartLine,
    getCartLine,
    getItemQtyInCart
};
