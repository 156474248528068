import { useAppDispatch } from "../app/hooks";
import { useEffect } from "react";
import { hideSkullVideo, showSkullVideo } from "../features/background/backgroundSlice";

export function useSkullVideo (classList: string[] = []) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(showSkullVideo(classList));
        return () => {
            dispatch(hideSkullVideo());
        };
    }, []);
}
