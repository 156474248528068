import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const shopifyConfig = JSON.parse(process.env.REACT_APP_SHOPIFY_CONFIG || "{UNDEFINED: TRUE}");
const storefrontAccessToken = shopifyConfig.STOREFRONT_ACCESS_TOKEN;
const storefrontAPI = "https://ey3k0n.myshopify.com/api/2022-10/graphql.json";

const httpLink = createHttpLink({ uri: storefrontAPI });
const middlewareLink = setContext(() => ({
    headers: {
        "X-Shopify-Storefront-Access-Token": storefrontAccessToken
    }
}));
export const shopifyClient = new ApolloClient({
    link: middlewareLink.concat(httpLink),
    cache: new InMemoryCache()
});
