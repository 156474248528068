import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ComicPage } from "./ComicPage";
import PrismaZoom from "react-prismazoom";
import { selectComic, selectComicSize } from "./comicSlice";
import { useAppSelector } from "../../app/hooks";
import useFancyNav from "../../hooks/useFancyNav";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // optional for styling

export function ComicSlider () {
    const comicSize = useAppSelector(selectComicSize);
    const comic = useAppSelector(selectComic);
    const aspectRatio = comicSize[0] / comicSize[1];

    const [swiper, setSwiper] = useState<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentArea, setCurrentArea] = useState(1);
    const [zoom, setZoom] = useState(1);
    const refPrismaZoom = useRef<any>(null);

    const next = () => {
        if (currentArea >= comic.pages[currentSlide].children.length) {
            swiper.slideNext();
        } else {
            setCurrentArea((state) => state + 1);
        }
    };
    const nextPage = () => {
        swiper.slideNext();
        setCurrentArea(1);
    };
    const back = () => {
        if (currentArea === 1) {
            swiper.slidePrev();
        } else {
            setCurrentArea((state) => state - 1);
        }
    };
    const prevPage = () => {
        swiper.slidePrev();
        setCurrentArea(1);
    };

    useEffect(() => {
        refPrismaZoom.current.reset();
    }, [currentArea, currentSlide]);

    const isAreaOpen = () =>
        currentArea > 0 && currentArea <= comic.pages[currentSlide].children.length;

    const { navigateTo } = useFancyNav();
    const returnToMain = () => {
        navigateTo("/c0m1cs/");
    };

    /* FULLSCREEN */
    const toggleComicFullscreen = () => {
        const comicDisplay = document.querySelector(".read-comic-container");
        if (!document.fullscreenElement) {
            comicDisplay?.requestFullscreen().catch((err) => {
                alert(
                    `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                );
            });
        } else {
            document.exitFullscreen();
        }
    };

    /* TOOLTIPS */
    tippy("#comic-reader-ff-back", {
        content: "Previous page",
        animation: "scale-subtle",
        arrow: true
    });
    tippy("#comic-reader-ff-next", {
        content: "Next page",
        animation: "scale-subtle",
        arrow: true
    });
    tippy("#cm-left-btn", {
        content: "Previous panel",
        animation: "scale-subtle",
        arrow: true
    });
    tippy("#cm-right-btn", {
        content: "Next panel",
        animation: "scale-subtle",
        arrow: true
    });

    return (
        <>
            <PrismaZoom
                allowZoom={isAreaOpen()}
                allowPan={isAreaOpen()}
                maxZoom={3}
                ref={refPrismaZoom}
                className="comic-zoom-container"
                onZoomChange={setZoom}
                allowTouchEvents={isAreaOpen()}
            >
                <Swiper
                    spaceBetween={20}
                    className="comic-swiper"
                    style={{
                        aspectRatio: aspectRatio as any
                    }}
                    speed={800}
                    noSwiping={zoom > 1 || isAreaOpen()}
                    noSwipingClass={"swiper-slide"}
                    onSlideChange={(swiper) => {
                        if (swiper.previousIndex > swiper.activeIndex) {
                            setCurrentArea(
                                comic.pages[currentSlide].children.length + 1
                            );
                        } else {
                            setCurrentArea(1);
                        }
                        setCurrentSlide(swiper.activeIndex);
                    }}
                    onSwiper={setSwiper as any}
                >
                    {comic.pages.map((page, index) => (
                        <SwiperSlide key={index}>
                            <ComicPage
                                swiper={swiper}
                                size={comicSize}
                                page={page}
                                currentArea={currentArea}
                                setCurrentArea={(index: number) => {
                                    setCurrentArea(index);
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </PrismaZoom>
            <div id="comic-menu">
                <div id="cm-label" className="sm-label">
                    {currentSlide + 1} / {comic.pages.length}
                </div>
                <div className="greenblue-csq-decor act"></div>
                <div id="cm-lb-pvt" className="sm-btn-pvt smbp-l">
                    <div
                        id="cm-left-btn"
                        className={`sm-btn smb-l ${
                            currentSlide === 0 && currentArea === 1
                                ? "fade"
                                : ""
                        }`}
                        onClick={back}
                    >
                        <div className="btn-frm"></div>
                        <div className="btn-icn"></div>
                    </div>
                </div>
                <div id="cm-rb-pvt" className="sm-btn-pvt smbp-r">
                    <div
                        id="cm-right-btn"
                        className={`sm-btn smb-r ${
                            currentSlide === comic.pages.length - 1 &&
                            currentArea ===
                                comic.pages[currentSlide].children.length
                                ? "fade"
                                : ""
                        }`}
                        onClick={next}
                    >
                        <div className="btn-frm"></div>
                        <div className="btn-icn"></div>
                    </div>
                </div>
                { currentSlide !== 0 &&
                    <div id="comic-reader-ff-back" onClick={prevPage}>
                        <span className="comic-reader-ff-lbl">{"<<"}</span>
                    </div>
                }
                { currentSlide !== comic.pages.length - 1 &&
                    <div id="comic-reader-ff-next" onClick={nextPage}>
                        <span className="comic-reader-ff-lbl">{">>"}</span>
                    </div>
                }
            </div>
            <div id="comic-reader-back-btn" className="sq-btn" onClick={returnToMain}>
                <span id="comic-reader-back-btn-lbl">{"<< BACK"}</span>
            </div>
            <div
                id="comic-fullscreen-btn"
                className="sq-btn"
                onClick={toggleComicFullscreen}
            >
                <span id="comic-fullscreen-btn-lbl">
                    {"FULLSCREEN"}
                </span>
            </div>
            {/* <div className={`comic-reader-incubate-url incubate-url-pos-${currentArea}`}>https://ey3k0n.io/incubate/</div> */}
        </>
    );
}
