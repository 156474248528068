export interface TransformState {
    s2c: string;
    s2ht5050: string;
    s2h: string;
    s2HorW: string;
}

export function getTransformData (): TransformState {
    const _transforms: TransformState = {
        s2c: "",
        s2ht5050: "",
        s2h: "",
        s2HorW: ""
    };
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    const aspect = vw / vh;

    let sh = vh / 1080;
    let sw = vw / 1920;
    const maxs = Math.max(sh, sw);

    _transforms.s2c = `translate(-50%,-50%) scale(${maxs},${maxs})`;
    _transforms.s2ht5050 = `translate(-50%,-50%) scale(${sh},${sh})`;
    _transforms.s2h = `scale(${sh},${sh})`;

    if (aspect > 1) {
        sh = Math.min(1.15, sh);
        sh = Math.max(0.5, sh);
        _transforms.s2HorW = `scale(${sh},${sh})`;
    } else {
        sw = Math.min(1.25, vw / 608);
        sw = Math.max(0.5, sw);
        _transforms.s2HorW = `translate(-50%, 0) scale(${sw},${sw})`;
    }

    return _transforms;
}
