import React from "react";
import KeypadQwerty from "../utils/KeypadQwerty";

const Overlays = () => {
    return (
        <div>
            <KeypadQwerty />
        </div>
    );
};

export default Overlays;
