import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

import { FeatureSwitchState } from "./FeatureSwitchState";

const initialState: FeatureSwitchState = {
    mintingSectionEnabled: false
};

export const featureSwitchSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        mintingSectionEnabled: (state: Draft<FeatureSwitchState>, action: PayloadAction<boolean>) => {
            state.mintingSectionEnabled = action.payload;
        },
        setFeatureSwitches: (state: Draft<FeatureSwitchState>, action: PayloadAction<FeatureSwitchState>) => {
            state.mintingSectionEnabled = action.payload.mintingSectionEnabled;
        }
    }
});

export default featureSwitchSlice.reducer;

export const { setFeatureSwitches } = featureSwitchSlice.actions;

export const mintingSectionEnabledSelector = (state: RootState) => state.featureSwitches.mintingSectionEnabled;
