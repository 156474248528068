const comicWidth = 3700;
const comicHeight = 2850;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const pilgrimageComic = [
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 0,
                offsetLeftPerc: 35
            }),
            calcVal({
                heightPerc: 25,
                offsetTopPerc: 36,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 25,
                offsetTopPerc: 36,
                offsetLeftPerc: 47
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 8,
                offsetLeftPerc: 26
            }),
            calcVal({
                heightPerc: 37,
                offsetTopPerc: 30,
                offsetLeftPerc: 35
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 39,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 35,
                offsetTopPerc: 29,
                offsetLeftPerc: 32.5
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 8,
                offsetTopPerc: 0,
                offsetLeftPerc: 67
            }),
            calcVal({
                heightPerc: 42,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 40,
                offsetTopPerc: 31,
                offsetLeftPerc: 32
            }),
            calcVal({
                heightPerc: 38,
                offsetTopPerc: 62,
                offsetLeftPerc: 37
            })
        ]
    },
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 31,
                offsetTopPerc: 0,
                offsetLeftPerc: 34
            }),
            calcVal({
                heightPerc: 34,
                offsetTopPerc: 25,
                offsetLeftPerc: 28
            }),
            calcVal({
                heightPerc: 27,
                offsetTopPerc: 30,
                offsetLeftPerc: 48
            }),
            calcVal({
                heightPerc: 43,
                offsetTopPerc: 56,
                offsetLeftPerc: 32
            })
        ]
    },
    {
        img: "/img/comic/pilgrimage/Pilgrimage_Layered_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 20,
                offsetTopPerc: 0,
                offsetLeftPerc: 55
            }),
            calcVal({
                heightPerc: 26,
                offsetTopPerc: 0,
                offsetLeftPerc: 27
            }),
            calcVal({
                heightPerc: 21.5,
                offsetTopPerc: 27,
                offsetLeftPerc: 28.5
            }),
            calcVal({
                heightPerc: 26,
                offsetTopPerc: 27,
                offsetLeftPerc: 49
            }),
            calcVal({
                heightPerc: 48,
                offsetTopPerc: 52,
                offsetLeftPerc: 26
            })
        ]
    }
];
