import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedRouteProps {
    children: any;
    hasPermission: () => boolean;
    permissionConditions: any[];
    noPermsUrl: string;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const navigate = useNavigate();

    // Redirect if user does not have permissiono
    useEffect(() => {
        if (!props.hasPermission()) {
            navigate(props.noPermsUrl);
        }
    }, [props.permissionConditions]);

    return <>{ props.hasPermission() ? props.children : null}</>;
};

export default ProtectedRoute;
