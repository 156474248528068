import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ContentViewport from "../../components/ContentViewport";
import useBodyClasses from "../../hooks/useBodyCasses";
import useFadeIn from "../../hooks/useFadeIn";
import useFancyNav from "../../hooks/useFancyNav";
import { DeviceUIButton } from "../device-ui/DeviceUIButton";
import { setActiveButton, turnDeviceOn } from "../device-ui/deviceUISlice";
import { selectIncubateResult } from "../incubate/incubateSlice";
import { selectSelectedCover } from "./comicSlice";

const Stage2IncubateResult = () => {
    const dispatch = useAppDispatch();
    const body = useBodyClasses();
    const { navigateTo } = useFancyNav();
    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    const selectedCover = useAppSelector(selectSelectedCover);
    const incubateResult = useAppSelector(selectIncubateResult);

    useEffect(() => {
        dispatch(turnDeviceOn());
        dispatch(setActiveButton(DeviceUIButton.COMIC));
        body.setClasses(["sec-comic"]);
    }, []);
    return (
        <ContentViewport>
            <div ref={sectionRef} className="section">
                <div className="browse-comic-container">
                    <div className="incubate-result-page-wrapper">
                        <div className="incubate-result-page-back-btn sq-btn"
                            onClick={() => navigateTo("/c0m1cs/")}>
                            {"<< BACK"}
                        </div>
                        {}
                        <div className="incubate-result-image"
                            style={{
                                background: `url('${incubateResult}') center/contain no-repeat`
                            }}>
                            <div className="incubate-result-title-wrapper">
                                <span>{selectedCover?.name}</span>
                                <div className="incubate-result-decorator"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
};

export default Stage2IncubateResult;
