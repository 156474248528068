import MetaMaskOnboarding from "@metamask/onboarding";
const _window: any = window;

export function tryConnectToMetamask (handleNewAccounts: (accounts: string[]) => void) {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        _window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then(handleNewAccounts);

        _window.ethereum.on("accountsChanged", handleNewAccounts);
    }
}

export function installMetamask () {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
        const metamaskOB = new MetaMaskOnboarding();
        metamaskOB.startOnboarding();
    }
}

export async function switchNetwork (networkId: string) {
    await _window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: networkId }]
    });
}

// export async function getAvailableCharacters () {
//     return ["1", "2", "3"]
// }

// export async function getAvailableComics () {
//     return ["1", "2", "3"]
// }
