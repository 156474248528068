import React, { useEffect, useState } from "react";

interface ScreenCenterWrapperProps {
    children: any,
    widthPercent: number,
    heightPercent: number,
    mobileWidthPercent: number,
    mobileHeightPercent: number
}

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};

const ScreenCenterWrapper = (props: ScreenCenterWrapperProps) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);
    const aspectRatio = () => { return windowDimensions.width / windowDimensions.height; };
    useEffect(() => {
        function handleResize () {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    });
    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: `${aspectRatio() > 1 ? props.widthPercent : props.mobileWidthPercent}vw`,
                height: `${aspectRatio() > 1 ? props.heightPercent : props.mobileHeightPercent}vh`,
                zIndex: "1001"
            }}>
            {props.children}
        </div>
    );
};

export default ScreenCenterWrapper;
