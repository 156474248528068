import React from "react";
import { useSkullVideo } from "../../hooks/useSkullVideo";
import MintFrame from "./MintFrame";
import { useNavigate } from "react-router-dom";
import { useCloseButton } from "../../hooks/useCloseButton";
// import { useAppSelector } from "../../app/hooks";
// import { selectContractAddress } from "../metamask/metamaskSlice";

function NotWhitelistedScreen (props: any) {
    const navigate = useNavigate();

    const onCloseClicked = () => {
        navigate("/mint");
    };
    useCloseButton(onCloseClicked);
    useSkullVideo(["blurred"]);
    // const contractAddress = useAppSelector(selectContractAddress);

    return (
        <MintFrame>
            <div id="mint-notinwhitelist-scrn" className="mint-screen a100 active">
                <div id="mint-notinwhitelist-container" className="mint-side-content">
                    <div id="mnt-notinwhitelist-title" className="msc-entry">
                        <span><strong>NOT</strong> WHITELISTED</span>
                    </div>
                    <div id="mnt-notinwhitelist-message" className="mnt-msg msc-entry btm-separator top-separator">
                        <span>This wallet address doesn't seem to be in the whitelist. Try with a different one or get your hands on an EY3KON in the upcoming Public sale! You can also check the secondary market.</span>
                    </div>
                    <div id="mnt-notinwhitelist-links" className="mnt-links-box msc-entry">
                        <div className="mnt-opensea-button sq-btn">
                            <a href={"https://opensea.io/collection/ey3k0n-first-edition"} target="blank">BUY ON OPENSEA</a>
                        </div>
                    </div>
                </div>
            </div>
        </MintFrame>
    );
}

export default NotWhitelistedScreen;
