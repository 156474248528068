import React, { useEffect, useRef } from "react";
import ContentViewport from "../../components/ContentViewport";
import useFadeIn from "../../hooks/useFadeIn";
import { turnDeviceOn } from "../device-ui/deviceUISlice";
import { useAppDispatch } from "../../app/hooks";

interface MintFrameProps {
    fadeInTime?: number;
    children: any;
    isPublicSale?: boolean;
    titled?: boolean;
}

function MintFrame (props: MintFrameProps) {
    const { isPublicSale } = props;
    const sectionRef: any = useRef();
    useFadeIn(sectionRef);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(turnDeviceOn());
    }, []);

    return (
        <ContentViewport>
            <div ref={sectionRef} id="sec-mint" className={`section ${isPublicSale ? "public" : "whitelist"}`}>
                <div id="mint-frame" className={`a100 ${props.titled ? "titled" : ""}`}></div>
                {props.children}
            </div>
        </ContentViewport>
    );
}

export default MintFrame;
