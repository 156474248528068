import React from "react";

function RoadmapScreen3 (props: any) {
    const _classNames = props.classNames || [];
    const classNames = ["rm-page", ..._classNames].join(" ");
    return (
        <div id="rm-page-3" className={classNames}>
            <h2 className="mp-title">THR33</h2>
            <div className="mp-entries">
                <div className="mp-entry">
                    <div className="mpe-title">ON SCREEN - Dreaming big</div>
                    <div className="mpe-body">
                        Our big vision is to explore a whole new sub-genre of entertainment, an interactive experience that pours onto the big screen. Get in at the start.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoadmapScreen3;
