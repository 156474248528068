import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import ContentViewport from "../../components/ContentViewport";
import useFadeIn from "../../hooks/useFadeIn";
import { turnDeviceOn } from "../device-ui/deviceUISlice";
import { mailchimpService } from "../mailchimp/mailchimpService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ComicForm = (props: any) => {
    const dispatch = useAppDispatch();
    const toastParams = {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
        className: "marketing-toast",
        bodyClassName: "marketing-toast-body"
    };
    const toastSuccess = (message: string) => toast.success(message, toastParams as any);
    const toastError = (message: string) => toast.error(message, toastParams as any);

    const sectionRef = useRef() as any;
    useFadeIn(sectionRef);

    useEffect(() => {
        dispatch(turnDeviceOn());
    }, []);

    const emailRef = useRef("") as any;
    const walletRef = useRef("") as any;
    const [errorMessage, setErrorMessage] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const handleSubmit = async () => {
        const email = emailRef.current.value;
        const wallet = walletRef.current.value;
        if (!(email.length > 0 && wallet.length > 0)) {
            setErrorMessage("EM4IL and W4LL3T must be provided");
            return;
        };
        setSubmitLoading(true);
        // No error
        setErrorMessage("");

        const response = await mailchimpService.addComicDropMailingList(email, wallet);
        setSubmitLoading(false);

        if (response.success) {
            toastSuccess("Succesfully subscribed!");
        } else {
            toastError(response.error ? response.error : "");
        }
    };

    return (
        <ContentViewport>
            <div ref={sectionRef} className="section">
                <div className="marketing-container">
                    <div className="marketing-wrapper">
                        <div className="marketing-divider">
                            <div className="marketing-video-wrapper">
                                <div className="marketing-video">
                                    <video playsInline muted autoPlay loop preload="auto">
                                        <source src="/videos/Landing_Page_Pill0001-0200.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                            <div className="marketing-form">
                                <div className="marketing-title">
                                    ACC3SS P4SS
                                </div>
                                <div className="marketing-desc">
                                    GET NOTIFIED <br/>
                                    Upcoming Drops & Latest News
                                </div>
                                <div className="marketing-entry-wrapper">
                                    <div className="marketing-input-wrapper">
                                        <div className="marketing-label">EM4IL</div>
                                        <input ref={emailRef} className="marketing-entry-text" type="text" placeholder="EM4IL" name="email" autoComplete="off" required/>
                                    </div>
                                    <div className="marketing-input-wrapper">
                                        <div className="marketing-label">W4LL3T ADDR3SS</div>
                                        <input ref={walletRef} className="marketing-entry-text" type="text" placeholder="W4LL3T ADDR3SS" name="wallet" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="marketing-submit-btn sq-btn" onClick={handleSubmit}>
                                    { submitLoading ? "..." : "SUBMIT"}
                                </div>
                                { errorMessage.length > 0 &&
                                    <div className="marketing-error-msg">
                                        {errorMessage}
                                    </div>
                                }
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentViewport>
    );
};

export default ComicForm;
