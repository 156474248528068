const comicWidth = 3700;
const comicHeight = 2859;
const aspectRatio = comicWidth / comicHeight;
const calcVal = ({heightPerc, offsetTopPerc, offsetLeftPerc}:
    {heightPerc: number, offsetTopPerc: number, offsetLeftPerc: number}) => {
    return {
        width: heightPerc * comicHeight / 100 * aspectRatio,
        height: heightPerc * comicHeight / 100,
        offsetTop: offsetTopPerc * comicHeight / 100,
        offsetLeft: offsetLeftPerc * comicWidth / 100
    };
};
const DEFAULT_VIEW = calcVal({
    heightPerc: 100,
    offsetTopPerc: 0,
    offsetLeftPerc: 0
});
export const skinComic = [
    {
        img: "/img/comic/skin/Skin_Layered_01.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_02.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 35.5,
                offsetTopPerc: 0,
                offsetLeftPerc: 29
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 35.5,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_03.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_04.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 2,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 48,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_05.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 47,
                offsetTopPerc: 3,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 36,
                offsetTopPerc: 31.5,
                offsetLeftPerc: 39
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_06.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_07.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_08.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 28,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 19,
                offsetTopPerc: 58,
                offsetLeftPerc: 28
            }),
            calcVal({
                heightPerc: 21,
                offsetTopPerc: 75,
                offsetLeftPerc: 47
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_09.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 0,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 50,
                offsetLeftPerc: 25
            })
        ]
    },
    {
        img: "/img/comic/skin/Skin_Layered_10.webp",
        children: [
            DEFAULT_VIEW,
            calcVal({
                heightPerc: 50,
                offsetTopPerc: 21,
                offsetLeftPerc: 25
            }),
            calcVal({
                heightPerc: 48,
                offsetTopPerc: 52,
                offsetLeftPerc: 25
            })
        ]
    }
];
