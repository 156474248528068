import React from "react";
import useFancyNav from "../../hooks/useFancyNav";

function RadioWidgetMobile (props: any) {
    const fancyNav = useFancyNav();
    const openPlayer = () => {
        fancyNav.navigateTo("/radio");
    };

    return (
        <div className="radio-widget">
            <div id="rad-widget-mobile">
                <div id="rad-wid-eq-container-mb" className="rw-sec">
                    <div id="rad-wid-eq-mb"></div>
                </div>
                <div id="rad-wid-expand-container-mb" className="rw-sec">
                    <div id="rad-wid-expand-btn-mb" onClick={openPlayer}></div>
                </div>
            </div>
        </div>);
}

export default RadioWidgetMobile;
