import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ScreenCenterWrapper from "./ScreenCenterWrapper";
import { selectShowKeypad, setShowKeypad } from "./utilSlice";
import "react-toastify/dist/ReactToastify.css";
import useFancyNav from "../../hooks/useFancyNav";
import { KeypadCallbackResponse, useKeypadContext } from "./KeypadContext";

const MAX_LENGTH = 12; // 8;

const KeypadQwerty = () => {
    const dispatch = useAppDispatch();
    const keypadContext = useKeypadContext();
    const { navigateTo } = useFancyNav();

    /* Keypad State */
    const showKeypad = useAppSelector(selectShowKeypad);
    const closeKeypad = () => {
        dispatch(setShowKeypad(false));
    };

    /* User Input */
    const [answer, setAnswer] = useState("");
    const [showOverlay, setShowOverlay] = useState(false);
    interface OverlayInfoInterface {
        title: string,
        description: string
    }
    const [overlayInfo, setOverlayInfo] = useState<OverlayInfoInterface>({title: "", description: ""});
    const [isLoading, setIsLoading] = useState(false);
    const handleBtn = (val: string) => {
        if (val === "BACK") {
            setAnswer(answer.slice(0, -1));
            return;
        }
        if (answer.length >= MAX_LENGTH) { // Max length
            return;
        }
        setAnswer(answer + val);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setShowOverlay(true);
        setOverlayInfo({
            title: "G3N3R4T1NG....",
            description: "The Agency is generating your upgraded cover. This may take up to 30 seconds, AG3NT."
        });
        if (keypadContext !== undefined) {
            const res: KeypadCallbackResponse = await keypadContext.keypadCallback(answer);
            setIsLoading(false);

            // handle res
            if (res.success) {
                setShowOverlay(false);
                if (res.navigateUrl.length > 0) {
                    dispatch(setShowKeypad(false));
                    navigateTo(res.navigateUrl);
                }
            } else {
                setShowOverlay(true);
                setOverlayInfo({
                    title: "F41L3D",
                    description: res.msg
                });
            }
        }
    };

    const getFontSize = () => {
        if (answer.length <= 6) {
            return "min(11.2em, max(11vw, 3.5em))";
        }
        return `min(11.2em, 
                    min(
                        ${70 / answer.length}vw, 
                        ${80 / answer.length}vh
                    )
                )`;
    };

    const RenderBtn = ({val}: {val: string}) => {
        return (
            <div key={val} className={`keypad-qwerty-btn keypad-qwerty-btn-${val}`} onClick={() => handleBtn(val)}/>
        );
    };
    return (
        <div style={{
            display: `${showKeypad ? "block" : "none"}`
        }}>
            <ScreenCenterWrapper
                widthPercent={100}
                heightPercent={100}
                mobileWidthPercent={100}
                mobileHeightPercent={100}
            >
                { showOverlay &&
                    <div className="keypad-qwerty-overlay">
                        <div className="keypad-qwerty-overlay-background">
                            <div className="keypad-qwerty-overlay-title">{overlayInfo.title}</div>
                            <div className="keypad-qwerty-overlay-description">{overlayInfo.description}</div>
                            <div className="keypad-qwerty-overlay-close-btn" onClick={() => setShowOverlay(false)} />
                        </div>
                    </div>
                }
                <div className="keypad-qwerty-container">
                    <div className="keypad-qwerty-wrapper">
                        <div className="keypad-qwerty-close-btn-wrapper">
                            <div className="keypad-qwerty-close-btn" onClick={closeKeypad}>
                                X
                            </div>
                        </div>
                        <div className="keypad-qwerty-display">
                            <div className="keypad-qwerty-answer"
                                style={{
                                    fontSize: getFontSize()
                                }}>
                                {isLoading ? "..." : answer}
                            </div>
                        </div>
                        <div className="keypad-qwerty-row">
                            {
                                "0123456789".split("").map((val) => {
                                    return (
                                        <RenderBtn key={val.toString()} val={val.toString()} />
                                    );
                                })
                            }
                        </div>
                        <div className="keypad-qwerty-row">
                            {
                                "QWERTYUIOP".split("").map((val) => {
                                    return (
                                        <RenderBtn key={val.toString()} val={val.toString()} />
                                    );
                                })
                            }
                        </div>
                        <div className="keypad-qwerty-row">
                            {
                                "ASDFGHJKL".split("").map((val) => {
                                    return (
                                        <RenderBtn key={val.toString()} val={val.toString()} />
                                    );
                                })
                            }
                        </div>
                        <div className="keypad-qwerty-row">
                            <div className="keypad-qwerty-btn keypad-qwerty-btn-BACK" onClick={() => handleBtn("BACK")}
                                style={{
                                    marginRight: "5%"
                                }}/>
                            {
                                "ZXCVBNM".split("").map((val) => {
                                    return (
                                        <RenderBtn key={val.toString()} val={val.toString()} />
                                    );
                                })
                            }
                            <div className="keypad-qwerty-btn keypad-qwerty-btn-ENTER" onClick={handleSubmit}
                                style={{
                                    marginLeft: "5%"
                                }}/>
                        </div>
                    </div>
                </div>
            </ScreenCenterWrapper>
        </div>
    );
};

export default KeypadQwerty;
