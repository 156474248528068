import React, { useEffect, useState } from "react";
import MintFrame from "./MintFrame";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getTokenBalance, selectTokenBalance, selectWalletAddress } from "../metamask/metamaskSlice";
import { subscribeToMailingList } from "./mintingSlice";
import { useSkullVideo } from "../../hooks/useSkullVideo";
import { useCloseButton } from "../../hooks/useCloseButton";
import useFancyNav from "../../hooks/useFancyNav";

// TODO: get skull to be unlocked
// TODO: mailing List
function MintSuccessScreen (props: any) {
    const mintCount = useAppSelector(selectTokenBalance);
    const dispatch = useAppDispatch();
    const walletAddress = useAppSelector(selectWalletAddress);
    const [email, setEmail] = useState("");
    const { navigateTo } = useFancyNav();
    useSkullVideo([]);
    const onClosedClicked = () => {
        navigateTo("/mint");
    };
    useCloseButton(onClosedClicked);
    useEffect(() => {
        dispatch(getTokenBalance(walletAddress));
    }, [walletAddress]);
    useEffect(() => {
        dispatch(getTokenBalance(walletAddress));
    }, []);

    function shareOnTwitter () {
        const url = "https://www.ey3k0n.io";
        const hashtags = "EY3K0N,NFT";
        const related = "EY3K0N";
        const text = "I just minted an EY3K0N!";
        window.open(`https://twitter.com/intent/tweet?url=${url}&hashtags=${hashtags}&related=${related}&text=${text}`, "_blank");
    }
    const [subclass, setSubclass] = useState("");
    function callSubscribeToMailingList (e: any) {
        e?.preventDefault();
        dispatch(subscribeToMailingList(email))
            .then((result: any) => {
                if (result.payload) {
                    setSubclass("subscribed");
                } else {
                    setSubclass("sub-error");
                }
            })
            .catch((e) => {
                setSubclass("sub-error");
            });
        setSubclass("");
    }

    function onChange (e: any) {
        setEmail(e.target.value);
    }

    return (
        <MintFrame>
            <div id="mint-congrats-scrn" className={`mint-screen a100 active ${subclass}`}>
                <div id="mint-congrats-container" className="mint-side-content">
                    <div id="mnt-congrats-title" className="msc-entry">
                        <span><strong>CONGRATULATIONS!</strong></span>
                    </div>
                    <div id="mnt-congrats-select" className="msc-entry top-separator">
                        <div className="mnt-sel-label">You have obtained: <span className="mnt-sel-amount">{mintCount}</span> of 10 tokens</div>
                        <div className="mnt-sel-btn-box">
                            {mintCount >= 1 && <div className="mnt-sel-btn-01 sq-btn mss-btn active"><span>1</span></div>}
                            {mintCount >= 2 && <div className="mnt-sel-btn-02 sq-btn mss-btn active"><span>2</span></div>}
                            {mintCount >= 3 && <div className="mnt-sel-btn-03 sq-btn mss-btn active"><span>3</span></div>}
                            {mintCount >= 4 && <div className="mnt-sel-btn-04 sq-btn mss-btn active"><span>4</span></div>}
                            {mintCount >= 5 && <div className="mnt-sel-btn-05 sq-btn mss-btn active"><span>5</span></div>}
                            {mintCount >= 6 && <div className="mnt-sel-btn-06 sq-btn mss-btn active"><span>6</span></div>}
                            {mintCount >= 7 && <div className="mnt-sel-btn-07 sq-btn mss-btn active"><span>7</span></div>}
                            {mintCount >= 8 && <div className="mnt-sel-btn-08 sq-btn mss-btn active"><span>8</span></div>}
                            {mintCount >= 9 && <div className="mnt-sel-btn-09 sq-btn mss-btn active"><span>9</span></div>}
                            {mintCount >= 10 && <div className="mnt-sel-btn-10 sq-btn mss-btn active"><span>10</span></div>}
                        </div>
                    </div>
                    <div id="mnt-congrats-message" className="mnt-msg msc-entry btm-separator top-separator">
                        <span>You are in! You now have <strong>EY3KON</strong> status. Enter your email here to be kept in the loop, including
                            <strong>EY3KON</strong> NFT holder exclusives.</span>
                        <div id="mnt-subscribe-form" className="to-subscribe">
                            <input id="mnt-sub-text" type="text" placeholder="your email" name="email" autoComplete="off" required onChange={onChange} />
                            <input id="mnt-sub-btn" className="sq-btn" type="button" value="SUBSCRIBE" onClick={callSubscribeToMailingList} />
                        </div>
                        <div id="mnt-subscribe-msg" className="subscribed">
                            <span><strong>You are now subscribed!</strong></span>
                        </div>
                    </div>
                    <div id="mnt-congrats-links" className="mnt-links-box msc-entry">
                        <div className="mnt-opensea-button sq-btn">
                            <a href="https://opensea.io/collection/ey3k0n-first-edition" target="_blank">VIEW ON OPENSEA</a>
                        </div>
                        <div id="ms-share-box">
                            <div id="ms-share-label">SHARE</div>
                            <div id="ms-share-button" className="mm-btn" onClick={shareOnTwitter}></div>
                        </div>
                    </div>
                </div>
            </div>
        </MintFrame>
    );
}

export default MintSuccessScreen;
